import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.referralCodes
      }

    default:
      return state
  }
}

export function getReferralCodes (accountID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getReferralCodes().then(function (response) {
      const data = normalize(response, [schema.referralCode])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

function cleanupEmails (value) {
  const emails = []

  value.forEach(email => {
    if (email.trim() !== '') {
      emails.push(email.trim())
    }
  })

  return emails
}

export function createReferralCode (values) {
  if (values.requiredEmailVerificationDomains) {
    values.requiredEmailVerificationDomains = cleanupEmails(
      values.requiredEmailVerificationDomains
    )
  };

  values.code = values.code.toUpperCase()

  return function (dispatch, getState, { api, schema }) {
    return api
      .createReferralCode(values)
      .then(function (response) {
        const data = normalize(response, schema.referralCode)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
