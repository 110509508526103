import React from 'react'
import { PROGRAM_PORTAL_URL } from '@/lib/env'
import Icon from './icon'
import Restricted from './restricted'

class ClientDisplayName extends React.Component {
  render () {
    const { client, link } = this.props
    const clientName = [client.firstName, client.middleInitial, client.lastName]
      .filter(s => s)
      .join(' ')

    return (
      <span className='d-inline-flex text-nowrap'>
        {client.vip && (
          <span>
            <Icon
              icon='star'
              className='text-warning mr-1'
              title='VIP Client'
            />
          </span>
        )}

        <span>
          {link
            ? (
              <a
                href={`/clients/${client.id}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {clientName}
              </a>
              )
            : (
                clientName
              )}{' '}
          <Restricted policy='program::everyone'>
            <a
              href={`${PROGRAM_PORTAL_URL}/clients/${client.id}`}
              title='Open in Program Portal'
              data-toggle='tooltip'
              data-boundary='body'
              data-container='body'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon
                icon='external-link-alt'
                size='sm'
                className='text-muted ml-2'
              />
            </a>
          </Restricted>
        </span>
        {this.props.children}
      </span>
    )
  }
}

export default ClientDisplayName
