import React from 'react'
import { getParams } from '@/lib/functions'
import { withRestricted } from '@/components/restricted'
import '@/styles/search-bar.scss'

class SearchBar extends React.Component {
  render () {
    const params = getParams()

    return (
      <form
        action='/clients/search'
        method='get'
        className='search-bar form-inline flex-fill justify-content-end mr-4'
      >
        <input
          className='form-control search-input'
          name='query'
          type='text'
          defaultValue={params.query}
          placeholder='Search for a client and press Enter...'
          minLength='3'
          required
        />
      </form>
    )
  }
}

export default withRestricted(SearchBar, 'device::everyone')
