module.exports = {
  BUGSNAG_KEY: process.env.BUGSNAG_KEY,
  API_SERVICE_URL: process.env.API_SERVICE_URL,
  SHARED_API_GATEWAY_URL: process.env.SHARED_API_GATEWAY_URL,
  SHARED_PROGRAM_API_URL: process.env.SHARED_PROGRAM_API_URL,
  SHARED_GRAPHQL_API_GATEWAY_URL: process.env.SHARED_GRAPHQL_API_GATEWAY_URL,
  APP_STAGE: process.env.APP_STAGE,
  APP_VERSION: process.env.APP_VERSION,
  RESET_PASSWORD_URL: process.env.RESET_PASSWORD_URL,
  PROGRAM_PORTAL_URL: process.env.PROGRAM_PORTAL_URL
}
