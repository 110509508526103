import { normalize } from 'normalizr'
import { ADD_ENTITIES, RESET_ENTITIES, addEntities, resetEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.shipments
      }

    case RESET_ENTITIES:
      return {
        ...action.payload.shipments
      }

    default:
      return state
  }
}

export function getShipments (params, reset = false) {
  return function (dispatch, getState, { api, schema }) {
    return api.getShipments(params)
      .then(function (response) {
        const data = normalize(response.shipments, [schema.shipment])

        if (reset) {
          dispatch(resetEntities(data.entities))
        } else {
          dispatch(addEntities(data.entities))
        }

        return response
      })
  }
}

export function createClientShipment (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.createClientShipment(clientID)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getClientShipments (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientShipments(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.shipment])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function cancelShipment (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.cancelShipment(id)
      .then(function (response) {
        const data = normalize(response, schema.shipment)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function scheduleShipment (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.scheduleShipment(id, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)
        dispatch(addEntities(data.entities))
        return response
      })
  }
}

export function updateShipmentAddress (shipmentID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateShipmentAddress(shipmentID, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function updateShipment (shipmentID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateShipment(shipmentID, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function handoffShipment (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.handoffShipment(id, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
