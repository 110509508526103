import React from 'react'
import { connect } from 'react-redux'
import NavBar from '@/components/nav-bar'
import ReportLink from '../components/inbody-report-link'
import {
  getBodyCompositionReports,
  createBodyCompositionReport
} from '@/lib/redux/actions'
import {
  selectBodyCompositionReports
} from '@/lib/redux/selectors'
import { formatTime } from '@/lib/functions'
import api from '@/lib/api'
import Restricted, { withRestricted } from '@/components/restricted'

class LocalAmbassador extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      validReadings: [],
      invalidReadings: []
    }
  }

  componentDidMount () {
    const { getBodyCompositionReports } = this.props

    getBodyCompositionReports()
  }

  uploadReport (file) {
    const { createBodyCompositionReport } = this.props
    const reader = new FileReader()

    this.setState({
      uploading: true,
      validReadings: [],
      invalidReadings: []
    })

    reader.onload = (ev) => {
      const csv = ev.target.result

      api.uploadBodyCompositionReport(file)
        .then((fileName) => {
          return createBodyCompositionReport(fileName, csv)
            .then((res) => {
              this.setState({
                uploading: false,
                validReadings: res.readings,
                invalidReadings: res.invalid
              })
            })
        })
        .catch(() => {
          alert('Could not upload report, please try again.')

          this.setState({ uploading: false })
        })
    }

    reader.readAsText(file)
  }

  render () {
    const {
      uploading,
      validReadings,
      invalidReadings
    } = this.state
    const { reports } = this.props

    return (
      <div>
        <NavBar showSearch />

        <div className='container'>
          <div className='card mb-4'>
            <div className='card-body'>
              <h3 className='mb-3'>Body composition CSV upload</h3>

              <p>
                Upload the CSV file exported from the body composition machine.
              </p>

              <div className='mb-3 row'>
                {validReadings.length > 0 && (
                  <div className='col text-success'>
                    Readings saved successfuly:

                    <ul>
                      {validReadings.map((report) => (
                        <li key={`report-${report.id}`}>
                          {report.id}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {invalidReadings.length > 0 && (
                  <div className='col text-danger'>
                    Inexisting readings not saved:

                    <ul>
                      {invalidReadings.map((report, i) => (
                        <li key={`report-${i}`}>
                          {report}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className='row'>
                <Restricted policy='device::profile::body_composition::reports::create'>
                  <div className='col mb-3'>
                    {uploading ? (
                      <p className='font-italic'>
                        <img src='/assets/loader.gif' className='mr-2' alt='' />
                        Uploading... please wait
                      </p>
                    ) : (
                      <>
                        <div className='position-absolute'>
                          <span className='btn btn-outline-primary'>Upload new file</span>
                        </div>
                        <input
                          type='file'
                          className='custom-file-input'
                          accept='.csv'
                          onChange={ev => this.uploadReport(ev.target.files[0])}
                        />
                      </>
                    )}
                  </div>
                </Restricted>

                <table className='table table-striped table-borderless'>
                  <tbody>
                    {reports.map((report) => (
                      <tr
                        className='col-3 mb-3'
                        key={`report-${report.id}`}
                      >
                        <td>
                          Uploaded at {formatTime(report.createdAt)}
                        </td>
                        <td>
                          <ReportLink href={report.fileURL} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const reports = selectBodyCompositionReports(state)

  return {
    reports
  }
}

const mapDispatchToProps = {
  getBodyCompositionReports,
  createBodyCompositionReport
}

const connected = connect(mapStateToProps, mapDispatchToProps)(LocalAmbassador)

export default withRestricted(connected, 'device::profile::body_composition::reports')
