import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import ErrorBoundary from '@/components/error-boundary'
import PageLoading from '@/components/page-loading'
import Login from '@/pages/login'
import Verify from '@/pages/verify'
import Shipments from '@/pages/shipments'
import Shipping from '@/pages/shipping'
import NewClients from '@/pages/new_clients'
import AccountDetails from '@/pages/accounts/[id]'
import SiteDetails from '@/pages/sites/[id]'
import Clients from '@/pages/clients'
import ClientDetails from '@/pages/clients/[id]'
import ClientsSearch from '@/pages/clients/search'
import Tasks from '@/pages/tasks'
import DeviceDetails from '@/pages/devices/[id]'
import TriggersReference from '@/pages/triggers_reference'
import Inbody from '@/pages/inbody'
import Dmrs from '@/pages/dmrs'
import Logout from '@/pages/logout'
import store from '@/lib/redux/store'
import * as Actions from '@/lib/redux/actions'
import { allow } from '@/components/restricted'
import graphql from '@/lib/api/graphql'
import '@/styles/app.scss'

function PrivateRoute ({ component: Component, ...rest }) {
  const currentUser = store.getState().currentUser
  const authenticated = currentUser

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    const passRoutes = ['/login', '/verify']
    if (passRoutes.indexOf(location.pathname) > -1) {
      this.setState({ loading: false })
      return
    }

    store
      .dispatch(Actions.verifyCurrentUser())
      .then(() => {
        store.dispatch(Actions.getAccessPolicies()).then(() => {
          store.dispatch(Actions.getProductPresets())
          this.setState({ loading: false })
        })
      })
      .catch(() => {
        store.dispatch(Actions.logoutUser())
        location.href = '/login'
      })
  }

  redirectRoot () {
    const currentUser = store.getState().currentUser
    if (!currentUser) return <Redirect to='/login' />
    if (!allow('device::profile::tasks')) return <Redirect to='/shipments' />

    return <Redirect to='/tasks' />
  }

  render () {
    if (this.state.loading) return <PageLoading />

    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path='/login' render={props => <Login {...props} />} />
            <Route
              exact
              path='/verify'
              render={props => <Verify {...props} />}
            />

            <PrivateRoute exact path='/sites/:id' component={SiteDetails} />
            <PrivateRoute exact path='/new_clients' component={NewClients} />
            <PrivateRoute
              exact
              path='/accounts/:id'
              component={AccountDetails}
            />
            <PrivateRoute
              exact
              path='/clients/search'
              component={ClientsSearch}
            />
            <PrivateRoute exact path='/clients/:id' component={ClientDetails} />
            <PrivateRoute exact path='/clients' component={Clients} />
            <PrivateRoute exact path='/shipments' component={Shipments} />
            <PrivateRoute exact path='/shipping' component={Shipping} />
            <PrivateRoute exact path='/tasks' component={Tasks} />
            <PrivateRoute exact path='/devices/:id' component={DeviceDetails} />
            <PrivateRoute
              exact
              path='/triggers_reference'
              component={TriggersReference}
            />
            <PrivateRoute exact path='/inbody' component={Inbody} />
            <PrivateRoute exact path='/dmrs' component={Dmrs} />

            <Route exact path='/logout' component={Logout} />
            <Route render={() => this.redirectRoot()} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={graphql}>
      <App />
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('app')
)
