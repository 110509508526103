import { denormalize } from 'normalizr'
import { values, sortBy, filter, orderBy } from 'lodash'
import { createSelector } from 'reselect'
import { shipment as shipmentSchema, TASK_STATUSES } from '@/lib/api/schema'
import { PRODUCT_TYPES } from '../constants'

export function selectShipments (state) {
  const shipments = values(state.shipments).map(function (shipment) {
    return denormalize(shipment.id, shipmentSchema, state)
  })

  return shipments
}

export function selectPaymentPlans (state) {
  return values(state.paymentPlans)
}

export const selectIndividualNonLegacyPaymentPlans = createSelector(
  selectPaymentPlans,
  function (plans) {
    return plans.filter(p => !p.enterprise && !p.legacy)
  }
)

export const selectEnterpriseNonLegacyPaymentPlans = createSelector(
  selectPaymentPlans,
  function (plans) {
    return plans.filter(p => p.enterprise && !p.legacy)
  }
)

function selectAccessCodesValues (state) {
  return values(state.accessCodes)
}

function selectReferralCodesValues (state) {
  return values(state.referralCodes)
}

export function selectProductPresets (state) {
  return values(state.productPresets)
}

export const selectProductPresetKits = createSelector(
  selectProductPresets,
  function (productPresets) {
    return productPresets.filter(preset => preset.type === 'kit')
  }
)

export const selectAccessCodes = createSelector(
  selectAccessCodesValues,
  function (accessCodes) {
    return accessCodes.filter(c => c.type === 'personal')
  }
)

export const selectCorporateCodes = createSelector(
  selectAccessCodesValues,
  function (accessCodes) {
    return accessCodes.filter(c => c.corporate)
  }
)

export const selectReferralCodes = createSelector(
  selectReferralCodesValues,
  function (referralCodes) {
    return referralCodes
  }
)

export function selectClientsValues (state) {
  return orderBy(values(state.clients), 'createdAt', 'desc')
}

export function selectSubscriptions (state) {
  return values(state.subscriptions)
}

export function selectMemberships (state) {
  return values(state.memberships)
}

export function selectEnterpriseAccounts (state) {
  return values(state.accounts).filter(a => a.enterprise)
}

export function selectWaitlist (state) {
  return values(state.waitlist)
}

export function selectTasksValues (state) {
  return values(state.tasks)
}

export const selectOpenTasks = createSelector(
  selectTasksValues,
  function (tasks) {
    return filter(tasks, { status: TASK_STATUSES.OPEN })
  }
)

export function selectProductVaues (state) {
  return values(state.products)
}

export function selectProductReadingVaues (state) {
  return orderBy(values(state.productReadings), 'timestamp', 'desc')
}

export const selectBaseUnits = createSelector(
  selectProductVaues,
  function (products) {
    return products.filter(function (product) {
      return product.type === PRODUCT_TYPES.BASE_UNIT
    })
  }
)

export const selectMouthPieces = createSelector(
  selectProductVaues,
  function (products) {
    return products.filter(function (product) {
      return product.type === PRODUCT_TYPES.MOUTH_PIECE
    })
  }
)

export function selectSearchResultValues (state) {
  return values(state.search)
}

export function selectAgentsValues (state) {
  return values(state.agents)
}

export function selectReplacementValues (state) {
  return sortBy(values(state.replacements), 'createdAt')
}

function sortByOldestTask (clients) {
  return sortBy(clients, 'tasks[0].createdAt')
}

function selectClients (state) {
  return state.clients
}

export const selectClientsWithOpenTasks = createSelector(
  selectOpenTasks,
  selectClients,
  function (tasks, clients) {
    const uniqueClients = {}

    tasks.forEach(function (task) {
      const client = {
        ...uniqueClients[task.client],
        ...clients[task.client]
      }

      const clientTasks = client.tasks || []

      clientTasks.push(task)
      uniqueClients[task.client] = {
        ...client,
        tasks: sortBy(clientTasks, 'createdAt')
      }
    })

    return sortByOldestTask(filter(uniqueClients, 'id'))
  }
)

export function selectTriggers (state) {
  return sortBy(values(state.triggers), 'id')
}

export const selectCompletedTasks = createSelector(
  selectTasksValues,
  function (tasks) {
    return filter(tasks, { status: TASK_STATUSES.COMPLETED })
  }
)

export function selectSitesValues (state) {
  return sortBy(values(state.sites), 'name')
}

export function selectBodyCompositionReadingsValues (state) {
  return sortBy(values(state.bodyCompositionReadings), 'createdAt').reverse()
}

export function selectBodyCompositionReports (state) {
  return sortBy(values(state.bodyCompositionReports), 'createdAt').reverse()
}

function selectShippingTracksValues (state) {
  return values(state.shippingTracks)
}

export const selectShippingTracks = createSelector(
  selectShippingTracksValues,
  function (tracks) {
    return sortBy(tracks, 'name')
  }
)
