import React from 'react'
import Restricted from '@/components/restricted'
import CreateShippingExceptionModal from './modals/create-shipping-exception-modal'
import { formatWeekDate } from '@/lib/functions'
import api from '@/lib/api'

class ShippingException extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      exception: null
    }
    this.onShippingExceptionCreate = this.onShippingExceptionCreate.bind(this)
    this.handleDeleteShippingException = this.handleDeleteShippingException.bind(this)
  }

  componentDidMount () {
    const { client } = this.props

    api.getClientShippingExceptions(client.id)
      .then((exceptions) => {
        this.setState({ exception: exceptions[0] })
      })
  }

  get shippingException () {
    const { client } = this.props

    return client.shippingExceptions[0]
  }

  onShippingExceptionCreate (exception) {
    this.setState({ exception })
  }

  handleDeleteShippingException () {
    const { exception } = this.state
    if (!confirm('Are you sure you want to delete this Shipping Exception?')) return

    api.archiveShippingException(exception.id)
      .then(() => this.setState({ exception: null }))
  }

  render () {
    const { client } = this.props
    const { exception } = this.state

    return (
      <div>
        <p className='mb-0'>
          <strong className='mr-2'>Shipping Exception</strong>

          <Restricted policy='device::shipping::automation::update'>
            <button
              className='btn btn-link m-0 p-0 border-0 small'
              data-toggle='modal'
              data-target='#create-shipping-exception-modal'
            >
              Edit
            </button>
          </Restricted>
        </p>

        {exception ? (
          <>
            <span className='mr-3'>{exception.reason}</span>

            <Restricted policy='device::shipping::automation::update'>
              <button
                type='button'
                className='btn btn-sm btn-link text-danger small p-0 m-0'
                onClick={this.handleDeleteShippingException}
              >
                Cancel
              </button>
            </Restricted>

            {exception.matchedWeeks.length > 0 ? (
              <ul>
                {exception.matchedWeeks.map(({ week, year }) => (
                  <li key={`${week}-${year}`}>
                    Skip shipment on the week of {formatWeekDate(week, year)}
                  </li>
                ))}
              </ul>
            ) : (
              <p className='text-muted'>No shipments skipped before the exception expires.</p>
            )}
          </>
        ) : 'None'}

        <CreateShippingExceptionModal
          shippingException={exception}
          client={client}
          onCreate={this.onShippingExceptionCreate}
        />
      </div>
    )
  }
}

export default ShippingException
