import React from 'react'
import Icon from './icon'
import api from '@/lib/api'

class ReportLink extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      signedUrl: null
    }
  }

  componentDidMount () {
    const { href } = this.props

    if (!href) return

    api.fetchFileSignedUrl(href)
      .then((signedUrl) => {
        this.setState({ signedUrl })
      })
  }

  render () {
    const { signedUrl } = this.state

    if (!signedUrl) return null

    return (
      <a href={signedUrl} download>
        Download <Icon icon='download' />
      </a>
    )
  }
}

export default ReportLink
