import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import FormHandler from '@/components/form-handler'
import api from '@/lib/api'
import { formatTime, addTime } from '@/lib/functions'
import '~/react-datepicker/dist/react-datepicker.css'

class CreateShippingExceptionModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      untilDate: null,
      errors: null
    }
    this.changeUntilDate = this.changeUntilDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  changeUntilDate (date) {
    this.setState({ untilDate: date })
  }

  submitByDate (values) {
    const { client } = this.props

    return api.createShippingException({
      ...values,
      clientId: client.id
    })
  }

  get shippingTrackID () {
    return this.props.client.shippingTrackID
  }

  submitByNextTrackShipment (reason) {
    return api.getShippingTrack(this.shippingTrackID)
      .then((track) => {
        return this.submitByDate({
          until: track.nextDateSchedule,
          reason
        })
      })
  }

  onSubmit (values) {
    const { untilDate } = this.state
    const { reset, onCreate } = this.props
    let result

    this.setState({ errors: null })

    if (values.creationMethod === 'select-date') {
      result = this.submitByDate({
        until: untilDate,
        reason: `Shipments stopped until ${formatTime(untilDate, 'LL')}`
      })
    } else {
      result = this.submitByNextTrackShipment(values.reason)
    }

    result.then((exception) => {
      reset()
      window.$('#create-shipping-exception-modal').modal('hide')
      onCreate(exception)
    }).catch((error) => {
      this.setState({ errors: error.graphQLErrors })
    })
  }

  get minDate () {
    return addTime(new Date(), 1, 'day')
  }

  get clientTrack () {
    if (!this.shippingTrackID) return null

    const { tracks } = this.props
    return tracks[this.shippingTrackID]
  }

  render () {
    const {
      handleChange,
      handleSubmit,
      values,
      submitting,
      shippingException
    } = this.props
    const { untilDate, errors } = this.state
    if (!this.clientTrack) return null

    return (
      <div className='modal' id='create-shipping-exception-modal'>
        <div className='modal-dialog modal-sm'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Shipping Exceptions
              </h5>
              <button className='close' type='button' data-dismiss='modal'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              {shippingException && (
                <p className='text-danger'>
                  There is already a <strong>Shipping Exception</strong> for this user.
                  Any changes will override the previouse settings.
                </p>
              )}

              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className='custom-control custom-radio mb-3'>
                  <input
                    type='radio'
                    className='custom-control-input'
                    id='skip-next-only'
                    name='creationMethod'
                    value='skip-next'
                    checked={values.creationMethod === 'skip-next'}
                    onChange={handleChange}
                  />
                  <label className='custom-control-label' htmlFor='skip-next-only'>
                    Exclude user from the next <strong>Track {this.clientTrack.name}</strong> shipment
                    on <strong>{formatTime(this.clientTrack.nextDateSchedule, 'LL')}</strong> because:

                    <textarea
                      className='form-control'
                      name='reason'
                      onChange={handleChange}
                      value={values.reason || ''}
                      placeholder='Reason required'
                      disabled={values.creationMethod === 'select-date'}
                      required
                    />
                  </label>
                </div>

                <div className='custom-control custom-radio mb-4'>
                  <input
                    type='radio'
                    className='custom-control-input'
                    id='skip-until-date'
                    name='creationMethod'
                    value='select-date'
                    checked={values.creationMethod === 'select-date'}
                    onChange={handleChange}
                  />
                  <label className='custom-control-label' htmlFor='skip-until-date'>
                    Stop refill shipments until:

                    <DatePicker
                      className='form-control'
                      placeholderText='MM/DD/YYYY'
                      selected={untilDate}
                      onChange={this.changeUntilDate}
                      minDate={this.minDate}
                    />
                  </label>
                </div>

                {errors && (
                  <ul className='text-danger'>
                    {errors.map((error) => (
                      <li key={error.message}>{error.message}</li>
                    ))}
                  </ul>
                )}

                <button
                  className='btn btn-success btn-block'
                  disabled={submitting}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const form = FormHandler(CreateShippingExceptionModal, {
  initialValues: {
    creationMethod: 'skip-next'
  }
})

function mapStateToProps (state) {
  const tracks = state.shippingTracks

  return {
    tracks
  }
}

export default connect(mapStateToProps)(form)
