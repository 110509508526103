import React from 'react'
import { connect } from 'react-redux'
import {
  createBodyCompositionReading,
  getClientBodyCompositionReadings
} from '@/lib/redux/actions'
import {
  selectBodyCompositionReadingsValues
} from '@/lib/redux/selectors'
import { formatTime } from '@/lib/functions'
import Restricted, { withRestricted } from '@/components/restricted'

class LocalAmbassador extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      lastCreatedReport: null
    }
  }

  componentDidMount () {
    const { getClientBodyCompositionReadings, clientID } = this.props

    getClientBodyCompositionReadings(clientID)
  }

  formatReportDate (date) {
    return formatTime(date, 'M/D/YY HH:mm') || '-'
  }

  createReading () {
    const { createBodyCompositionReading, clientID } = this.props

    createBodyCompositionReading(clientID)
      .then((report) => {
        this.setState({ lastCreatedReport: report.id })
      })
  }

  createWeightOnlyReading () {
    const input = prompt('Enter a valid weight in lbs')
    if (!input) return

    const weight = parseFloat(input, 10)
    if (isNaN(weight)) return this.createWeightOnlyReading()

    const { createBodyCompositionReading, clientID } = this.props

    createBodyCompositionReading(clientID, { weight })
      .then((report) => {
        this.setState({ lastCreatedReport: report.id })
      })
  }

  render () {
    const {
      lastCreatedReport
    } = this.state
    const { readings } = this.props

    return (
      <div className='mb-5'>
        <h5 className='mb-3'>Body Composition</h5>

        <Restricted policy='device::profile::body_composition::readings::create'>
          <p>
            <button
              className='btn btn-outline-primary btn-sm mr-4'
              type='button'
              onClick={() => this.createReading()}
            >
              Create Full Test ID
            </button>

            <button
              className='btn btn-outline-primary btn-sm'
              type='button'
              onClick={() => this.createWeightOnlyReading()}
            >
              Enter Official Weight
            </button>
          </p>
        </Restricted>

        {readings.length > 0 && (
          <table className='table table-borderless table-striped'>
            <thead>
              <tr>
                <th>ID</th>
                <th>Test date</th>
                <th>Upload date</th>
              </tr>
            </thead>

            <tbody>
              {readings.map((report) => (
                <tr
                  key={`result-report-${report.id}`}
                >
                  <td>
                    {lastCreatedReport === report.id ? (
                      <span className='d-flex'>
                        <small className='text-success mr-2 font-weight-bold'>
                          New!
                        </small>
                        <span className='text-monospace h4'>
                          {report.id}
                        </span>
                      </span>
                    ) : (
                      <span className='text-monospace'>{report.id}</span>
                    )}
                  </td>
                  <td>{this.formatReportDate(report.testDate)}</td>
                  <td>{this.formatReportDate(report.uploadDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  const readings = selectBodyCompositionReadingsValues(state)

  return {
    readings
  }
}

const mapDispatchToProps = {
  createBodyCompositionReading,
  getClientBodyCompositionReadings
}

const connected = connect(mapStateToProps, mapDispatchToProps)(LocalAmbassador)

export default withRestricted(connected, 'device::profile::body_composition::readings')
