import React, { useState, useEffect } from 'react'
import billingApi from '@/lib/api/billing'

function InsuranceDetails (props) {
  const { clientID } = props
  const [healthInsurance, setHealthInsurance] = useState(null)

  useEffect(() => {
    billingApi.getCustomerHealthInsurance(clientID).then(data => {
      setHealthInsurance(data)
    })
  }, [])

  if (!healthInsurance) {
    return ''
  }

  const {
    insuranceCompany,
    insuranceCompanyPhone,
    policyholderName,
    policyholderBirthdate,
    memberIDNumber,
    groupNumber
  } = healthInsurance

  return (
    <div className='row mb-5'>
      <div className='col-12'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='mb-3'>Insurance details</h5>
          </div>
        </div>

        <div className='row'>
          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Insurance Company:</span>
              <br />
              <span className='text-monospace'>{insuranceCompany}</span>
            </p>
          </div>

          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Insurance Company Phone:</span>
              <br />
              <span className='text-monospace'>{insuranceCompanyPhone}</span>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Policyholder Name:</span>
              <br />
              <span className='text-monospace'>{policyholderName}</span>
            </p>
          </div>

          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Policyholder Birthdate:</span>
              <br />
              <span className='text-monospace'>{policyholderBirthdate}</span>
            </p>
          </div>

          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Member Id Number:</span>
              <br />
              <span className='text-monospace'>{memberIDNumber}</span>
            </p>
          </div>

          <div className='col-4'>
            <p>
              <span className='font-weight-bold'>Group Number:</span>
              <br />
              <span className='text-monospace'>{groupNumber}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsuranceDetails
