import React from 'react'
import { connect } from 'react-redux'
import PageLoading from '@/components/page-loading'
import {
  logoutUser
} from '@/lib/redux/actions'

class Logout extends React.Component {
  componentDidMount () {
    const { logoutUser } = this.props

    logoutUser()
      .then(() => {
        window.location.href = '/login'
      })
  }

  render () {
    return (
      <PageLoading>Logging out...</PageLoading>
    )
  }
}

const mapDispatchToProps = {
  logoutUser
}

export default connect(null, mapDispatchToProps)(Logout)
