import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.paymentPlans
      }

    default:
      return state
  }
}

export function getPaymentPlans () {
  return function (dispatch, getState, { api, schema }) {
    return api.getPaymentPlans().then(function (response) {
      const data = normalize(response, [schema.paymentPlan])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function createPaymentPlan (values) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi.createPaymentPlan(values).then(function (response) {
      const data = normalize(response, schema.paymentPlan)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
