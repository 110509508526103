import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.subscriptions
      }

    default:
      return state
  }
}

export function getClientSubscription (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientSubscription(clientID).then(function (response) {
      if (!response) return response

      const data = normalize(response, schema.subscription)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function cancelClientSubscription (subscriptionID) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi
      .cancelSubscription(subscriptionID)
      .then(function (response) {
        const data = normalize(response, schema.subscription)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function reSubscribeClient (subscriptionID) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi
      .reactivateSubscription(subscriptionID)
      .then(function (response) {
        const data = normalize(response, schema.subscription)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
