import React from 'react'
import { connect } from 'react-redux'
import { throttle } from 'lodash'
import Icon from './icon'
import ClientDisplayName from './client-display-name'
import ShippingAutomationNotes from './shipping-automation-notes'
import ShipmentModal from './modals/shipment-modal'
import paginationHandler from './pagination-handler'
import { getShipments } from '@/lib/redux/actions'
import { selectShipments } from '@/lib/redux/selectors'
import { formatTime } from '@/lib/functions'

class ShippingBatchShipments extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filters: {
        shippingBatchId: props.shippingBatch.id,
        shippingAutomationStatus: 'success'
      },
      reset: false,
      lastRefreshed: null
    }

    this.handleRefreshShipments = this.handleRefreshShipments.bind(this)
    this.reloadData = this.reloadData.bind(this)
    this.throttledReloadData = throttle(this.reloadData, 5000)
    this.loadNextPage = this.loadNextPage.bind(this)
  }

  componentDidMount () {
    this.reloadData()
  }

  filterByStatus (ev) {
    const { target: { value } } = ev

    this.updateFilters({ shippingAutomationStatus: value })
  }

  updateFilters (newFilters) {
    const { filters } = this.state
    const { resetPagination } = this.props

    this.setState({
      filters: {
        ...filters,
        ...newFilters
      }
    }, () => {
      resetPagination(this.reloadData)
    })
  }

  reloadData (reset) {
    const { pageSize, currentPage } = this.props.pagination

    for (let page = 1; page <= currentPage; page++) {
      this.loadPageData({ page, per_page: pageSize }, reset)
    }
  }

  loadNextPage () {
    const { pageSize, nextPage } = this.props.pagination
    this.loadPageData({ page: nextPage, per_page: pageSize })
  }

  loadPageData (pagination, reset = false) {
    const { filters } = this.state
    const { getShipments, updatePagination } = this.props
    const params = {
      shipping_batch_id: filters.shippingBatchId,
      shipping_automation_status: filters.shippingAutomationStatus,
      ...pagination
    }

    getShipments(params, reset)
      .then((data) => {
        const { pagination } = data

        this.setState({ lastRefreshed: new Date() })
        updatePagination(pagination)
      })
  }

  handleRefreshShipments () {
    this.throttledReloadData(true)
  }

  get filteredShipments () {
    const { shipments } = this.props
    const { shippingAutomationStatus } = this.state.filters

    return shipments.filter((shipment) => {
      if (shippingAutomationStatus === 'pending') {
        return shipment.shippingAutomationStatus === 'pending' ||
                shipment.shippingAutomationStatus === 'address_verification_pending'
      }

      return shipment.shippingAutomationStatus === shippingAutomationStatus
    })
  }

  render () {
    const { renderNextPageButton } = this.props
    const { lastRefreshed } = this.state

    return (
      <div className='card'>
        <div className='card-body'>
          <div className='form-inline mb-4'>
            <select
              className='custom-select custom-select-sm mr-auto'
              onChange={ev => this.filterByStatus(ev)}
            >
              <option value='success'>Successful Orders</option>
              <option value='failed'>Failed Orders</option>
              <option value='exception'>Shipping Exceptions</option>
              <option value='pending'>Orders Not Yet Created</option>
            </select>

            {lastRefreshed && (
              <div>
                <span className='mr-1'>
                  <b>Last refreshed:</b> {formatTime(lastRefreshed, 'h:mm:ss a')}
                </span>
                <button
                  type='button'
                  className='btn btn-light rounded'
                  onClick={this.handleRefreshShipments}
                >
                  <Icon icon='sync' />
                </button>
              </div>
            )}
          </div>

          <div className='table-responsive'>
            <table className='table table-striped table-borderless table-sm'>
              <thead>
                <tr>
                  <td>Client</td>
                  <td>Status</td>
                  <td>Order Creation Status</td>
                  <td>Shipped on</td>
                  <td>Notes</td>
                  <td />
                </tr>
              </thead>

              <tbody>
                {this.filteredShipments.map((shipment) => (
                  <tr key={shipment.id}>
                    <td>
                      <ClientDisplayName client={shipment.client} link />
                    </td>
                    <td>
                      {shipment.status}
                    </td>
                    <td>{shipment.shippingAutomationStatus}</td>
                    <td>{formatTime(shipment.shippedOn, 'LL')}</td>
                    <td><ShippingAutomationNotes shipment={shipment} /></td>
                    <td>
                      <button
                        className='btn btn-outline-primary btn-sm m-0'
                        type='button'
                        data-toggle='modal'
                        data-target={`#shipment-modal-${shipment.id}`}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}

                {this.filteredShipments.length === 0 && (
                  <tr>
                    <td colSpan='5' className='text-center'>No orders found</td>
                  </tr>
                )}
              </tbody>
            </table>

            {renderNextPageButton(this.loadNextPage)}
          </div>
        </div>

        {this.filteredShipments.map((shipment) => (
          <ShipmentModal
            {...shipment}
            modalId={`shipment-modal-${shipment.id}`}
            key={shipment.id}
          />
        ))}
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { id } = props.shippingBatch
  const shipments = selectShipments(state).filter((shipment) => {
    return shipment.shippingBatchID === id
  })

  return {
    shipments
  }
}

const mapDispatchToProps = {
  getShipments
}

const PaginatedShippingBatchShipments = paginationHandler(ShippingBatchShipments)
export default connect(mapStateToProps, mapDispatchToProps)(PaginatedShippingBatchShipments)
