import React from 'react'
import { connect } from 'react-redux'
import NavBar from '@/components/nav-bar'
import DatePicker from 'react-datepicker'
import ShippingTracks from '../components/shipping-tracks'
import { withRestricted } from '@/components/restricted'
import { formatTime } from '@/lib/functions'
import {
  getShippingTrackBatchesForDate
} from '@/lib/redux/actions'
import api from '../lib/api'
import '~/react-datepicker/dist/react-datepicker.css'

class Shipments extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      date: new Date(),
      tracks: null,
      currentTrack: null
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
    this.handleShippingBatchCreate = this.handleShippingBatchCreate.bind(this)
  }

  componentDidMount () {
    api.getTrackFromDate(new Date()).then((track) => {
      this.setState({ currentTrack: track })
    })
  }

  handleDateChange (date) {
    this.setState({ date, loaded: false })
  }

  handleLoad () {
    this.fetchShippingTrackBatches()
  }

  handleShippingBatchCreate () {
    this.fetchShippingTrackBatches()
  }

  fetchShippingTrackBatches () {
    const { getShippingTrackBatchesForDate } = this.props

    if (this._timeout) {
      clearTimeout(this._timeout)
    }

    getShippingTrackBatchesForDate(this.selectedDate)
      .then((tracks) => {
        const pending = tracks.some((track) => {
          return track.shippingBatches.some((batch) => {
            return batch.status === 'processing'
          })
        })

        if (pending) {
          this._timeout = setTimeout(() => {
            this.fetchShippingTrackBatches()
          }, 5000)
        }

        this.setState({ tracks })
      })
  }

  get selectedDate () {
    return this.state.date
  }

  get dateString () {
    return formatTime(this.selectedDate, 'YYYY-MM-DD')
  }

  render () {
    const { tracks, currentTrack } = this.state

    return (
      <div>
        <NavBar />

        <div className='container'>
          <h3>Shipments</h3>
          {currentTrack && (
            <p className='mb-5'>Shipping Track (This Week): <strong>{currentTrack.name}</strong></p>
          )}

          <div className='card mb-4'>
            <div className='card-body'>

              <div className='form-inline'>
                <label className='mr-2'>Select Date:</label>
                <DatePicker
                  className='form-control d-inline mr-3'
                  placeholderText='MM/DD/YYYY'
                  selected={this.selectedDate}
                  onChange={this.handleDateChange}
                  todayButton='Today'
                  monthsShown={2}
                />

                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={this.handleLoad}
                  disabled={!this.selectedDate}
                >
                  Load
                </button>
              </div>
            </div>
          </div>

          {tracks && (
            <ShippingTracks
              date={this.dateString}
              tracks={tracks}
              onShippingBatchCreate={this.handleShippingBatchCreate}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  getShippingTrackBatchesForDate
}
const connected = connect(null, mapDispatchToProps)(Shipments)
export default withRestricted(connected, 'device::shipments::create')
