import React from 'react'
import { connect } from 'react-redux'
import ShipmentRow from '../components/shipment-row'
import { withRestricted } from '../components/restricted'
import NavBar from '../components/nav-bar'
import { selectShipments, selectProductPresetKits, selectEnterpriseAccounts } from '../lib/redux/selectors'
import { getShipments, getEnterpriseAccounts } from '../lib/redux/actions'
import paginationHandler from '../components/pagination-handler'
import PageLoading from '../components/page-loading'

class ShipmentsTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      filters: {
        parentAccountID: '',
        kit: '',
        status: 'created'
      }
    }

    this.reloadData = this.reloadData.bind(this)
    this.loadNextPage = this.loadNextPage.bind(this)
    this.handleFilterParentAccount = this.handleFilterParentAccount.bind(this)
    this.handleFilterKit = this.handleFilterKit.bind(this)
    this.handleFilterStatus = this.handleFilterStatus.bind(this)
  }

  componentDidMount () {
    const { getEnterpriseAccounts } = this.props

    getEnterpriseAccounts()
    this.reloadData()
  }

  handleFilterParentAccount (ev) {
    const { target: { value } } = ev

    this.updateFilters({ parentAccountID: value })
  }

  handleFilterKit (ev) {
    const { target: { value } } = ev

    this.updateFilters({ kit: value })
  }

  handleFilterStatus (ev) {
    const { target: { value } } = ev

    this.updateFilters({ status: value })
  }

  updateFilters (newFilters) {
    const { filters } = this.state
    const { resetPagination } = this.props

    this.setState({
      filters: {
        ...filters,
        ...newFilters
      }
    }, () => {
      resetPagination(this.reloadData)
    })
  }

  reloadData () {
    const { pageSize, currentPage } = this.props.pagination

    for (let page = 1; page <= currentPage; page++) {
      this.loadPageData({ page, per_page: pageSize })
    }
  }

  loadNextPage () {
    const { pageSize, nextPage } = this.props.pagination
    this.loadPageData({ page: nextPage, per_page: pageSize })
  }

  loadPageData (pagination) {
    const { filters } = this.state
    const { getShipments, updatePagination } = this.props
    const reset = pagination.page === 1
    const params = {
      status: filters.status,
      ...pagination
    }
    if (filters.parentAccountID) {
      params.parentAccountID = filters.parentAccountID
    }
    if (filters.kit) {
      params.kit = filters.kit
    }

    this.setState({ loading: true })
    getShipments(params, reset)
      .then((data) => {
        const { pagination } = data

        this.setState({ loading: false })
        updatePagination(pagination)
      })
  }

  render () {
    const { renderNextPageButton, shipments, productPresetKits, parentAccounts } = this.props
    const { loading } = this.state

    return (
      <div>
        <NavBar />

        <div className='container'>
          <div>
            <h4>Shipments ({shipments.length})</h4>

            <form className='form-inline mb-3'>
              <div className='form-group mr-3'>
                <label>Parent Account:</label>
                <select className='custom-select custom-select-sm ml-2' onChange={this.handleFilterParentAccount}>
                  <option value=''>All</option>
                  {parentAccounts.map((account) => (
                    <option value={account.id} key={account.id}>{account.displayName}</option>
                  ))}
                </select>
              </div>
              <div className='form-group mr-3'>
                <label>Type:</label>
                <select className='custom-select custom-select-sm ml-2' onChange={this.handleFilterKit}>
                  <option value=''>All</option>
                  {productPresetKits.map((productPreset) => (
                    <option value={productPreset.slug} key={`kit-${productPreset.slug}`}>
                      {productPreset.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <label>Status:</label>
                <select className='custom-select custom-select-sm ml-2' onChange={this.handleFilterStatus}>
                  <option value='created'>Created</option>
                  <option value='scheduled'>Scheduled</option>
                  <option value='shipped'>Shipped</option>
                </select>
              </div>
            </form>
          </div>
          <div className='card'>
            <div className='card-body'>
              {loading
                ? <PageLoading />
                : (
                  <table className='table table-striped table-borderless table-sm w-100'>
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Created on</th>
                        <th>Ship by</th>
                        <th>Shipped on</th>
                        <th>Delivered on</th>
                        <th>Carrier service</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {shipments.map((shipment) => (
                        <ShipmentRow shipment={shipment} key={`shipment-row-${shipment.id}`} />
                      ))}
                    </tbody>
                  </table>
                  )}

              {renderNextPageButton(this.loadNextPage)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const shipments = selectShipments(state)
  const productPresetKits = selectProductPresetKits(state)
  const parentAccounts = selectEnterpriseAccounts(state)

  return {
    shipments,
    productPresetKits,
    parentAccounts
  }
}

const mapDispatchToProps = {
  getShipments,
  getEnterpriseAccounts
}

const PaginatedShipmentsTable = paginationHandler(ShipmentsTable)
const connected = connect(mapStateToProps, mapDispatchToProps)(PaginatedShipmentsTable)

export default withRestricted(connected, 'device::shipping::automation::create')
