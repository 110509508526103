import React, { useEffect, useState } from 'react'
import { withRestricted } from './restricted'
import enterpriseApi from '@/lib/api/enterprise'

function ParentAccounts (props) {
  const [affiliates, setAffiliates] = useState([])

  useEffect(() => {
    enterpriseApi.getAffiliates().then(function (records) {
      setAffiliates(records)
    })
  }, [])

  return (
    <div className='card mb-5'>
      <div className='card-body'>
        <h4 className='mb-5'>
          Affiliates <small>(formerly "Parent Accounts")</small>
        </h4>

        <div className='table-responsive'>
          <table className='table table-striped table-borderless table-sm w-100'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Total Referred Members</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {affiliates.map(affiliate => (
                <tr key={affiliate.id}>
                  <td>
                    <a href={`/accounts/${affiliate.id}`}>{affiliate.name}</a>
                  </td>
                  <td>{affiliate.type}</td>
                  <td>{affiliate.totalReferredMembers}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default withRestricted(ParentAccounts, 'device::new_clients::accounts')
