export * from './clients'
export * from './memberships'
export * from './search'
export * from './current-user'
export * from './shipments'
export * from './products'
export * from './product-readings'
export * from './product-presets'
export * from './payment-plans'
export * from './access-codes'
export * from './referral-codes'
export * from './subscriptions'
export * from './tickets'
export * from './accounts'
export * from './tasks'
export * from './agents'
export * from './replacements'
export * from './triggers'
export * from './sites'
export * from './body-composition-readings'
export * from './body-composition-reports'
export * from './ui'
export * from './access-policies'
export * from './shipping-tracks'

export const ADD_ENTITIES = 'ADD_ENTITIES'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const RESET_ENTITIES = 'RESET_ENTITIES'

export function addEntities (entities) {
  return {
    type: ADD_ENTITIES,
    payload: entities
  }
}

export function removeEntity (id) {
  return {
    type: REMOVE_ENTITY,
    payload: id
  }
}

export function resetEntities (entities) {
  return {
    type: RESET_ENTITIES,
    payload: entities
  }
}
