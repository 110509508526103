import { SHARED_PROGRAM_API_URL } from '@/lib/env'
import buildAPIClient from './build-client'

const apiClient = buildAPIClient(SHARED_PROGRAM_API_URL)

//  NOTE: [Deprecated] These endpoints are been replaced by the new enterprise-api, do not use
//       instead migrate them to enterprise-api and update these references
const BillingApi = {
  createSubscriptions (memberIds) {
    return apiClient
      .post('/billing/subscriptions/batch', { customer_ids: memberIds })
      .then(function (response) {
        return response.data
      })
  },

  getCustomerHealthInsurance (id) {
    return apiClient
      .get(`/billing/health_insurances/${id}`)
      .then(function (response) {
        return response.data.healthInsurance
      })
  }
}

export default BillingApi
