import { normalize } from 'normalizr'
import { ADD_ENTITIES, RESET_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.clients
      }

    case RESET_ENTITIES:
      return {
        ...action.payload.clients
      }

    default:
      return state
  }
}

export function getClient (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClient(id).then(function (response) {
      const data = normalize(response, schema.client)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function updateClient (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateClient(id, values).then(function (response) {
      const data = normalize(response, schema.client)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function confirmEligibility (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.confirmEligibility(id).then(function (response) {
      const data = normalize(response, schema.client)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
