import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import { updateClient, getClientAccessCode } from '@/lib/redux/actions'
import { selectAccessCodes } from '@/lib/redux/selectors'
import { withRestricted } from '@/components/restricted'
import ResponseError from '../response-error'

function copyAttrsWithPrefix (src, dst, srcPrefix = '', dstPrefix = '') {
  Object.keys(src).forEach(key => {
    if (!srcPrefix || key.startsWith(srcPrefix)) {
      const dstKey = key.replace(srcPrefix, '')
      dst[`${dstPrefix}${dstKey}`] = src[key]
    }
  })
}

class EditClientModal extends React.Component {
  constructor (props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  componentDidMount () {
    const { getClientAccessCode, clientID } = this.props

    getClientAccessCode(clientID)
  }

  submit (values) {
    const { updateClient, clientID } = this.props
    const address = {}

    copyAttrsWithPrefix(values, address, 'address_')
    values.address = address

    return updateClient(clientID, values).then(function () {
      window.$('#edit-client-modal').modal('hide')
    })
  }

  get emailRequired () {
    const { accessCode } = this.props

    if (!accessCode) return true

    return accessCode.emailRequired
  }

  get lastNameRequirement () {
    const { accessCode } = this.props

    if (!accessCode) return 'full'

    return accessCode.lastNameRequirement
  }

  get shippingAddressRequired () {
    const { accessCode } = this.props

    if (!accessCode) return true

    return accessCode.shippingAddressRequired
  }

  get phoneRequired () {
    const { accessCode } = this.props

    if (!accessCode) return true

    return accessCode.phoneRequired
  }

  render () {
    const { handleSubmit, handleChange, values, submitting, error } = this.props

    return (
      <div className='modal' id='edit-client-modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Client</h5>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form onSubmit={handleSubmit(this.submit)}>
                <div className='form-check mb-4'>
                  <input
                    name='vip'
                    type='checkbox'
                    className='form-check-input'
                    onChange={handleChange}
                    checked={!!values.vip}
                  />
                  <label className='form-check-label'>VIP</label>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label>First Name</label>
                      <input
                        type='text'
                        name='firstName'
                        className='form-control'
                        onChange={handleChange}
                        value={values.firstName || ''}
                        required
                      />
                    </div>
                  </div>

                  <div className='col-6'>
                    {this.lastNameRequirement !== 'none' && (
                      <div className='form-group'>
                        <label>
                          {this.lastNameRequirement === 'initial'
                            ? 'Last Name Initial'
                            : 'Last Name'}
                        </label>
                        <input
                          type='text'
                          name='lastName'
                          className='form-control'
                          onChange={handleChange}
                          value={values.lastName || ''}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>

                {this.phoneRequired && (
                  <div className='form-group'>
                    <label>Phone Number</label>
                    <input
                      type='tel'
                      name='phoneNumber'
                      className='form-control'
                      onChange={handleChange}
                      value={values.phoneNumber || ''}
                      required
                    />
                  </div>
                )}

                <div className='form-group'>
                  <label>E-mail</label>
                  <input
                    type='email'
                    name='email'
                    className='form-control'
                    onChange={handleChange}
                    value={values.email || ''}
                    required
                  />
                </div>

                {this.shippingAddressRequired && (
                  <fieldset>
                    <legend>Shipping Address</legend>

                    <p className='text-info font-italic'>
                      NOTE: Updating the shipping address won't affect existing
                      shipments.
                    </p>

                    <div className='form-group'>
                      <label>Street Line 1</label>
                      <input
                        type='text'
                        name='address_street1'
                        className='form-control'
                        onChange={handleChange}
                        value={values.address_street1 || ''}
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <label>Street Line 2</label>
                      <input
                        type='text'
                        name='address_street2'
                        className='form-control'
                        onChange={handleChange}
                        value={values.address_street2 || ''}
                      />
                    </div>

                    <div className='row'>
                      <div className='col-6'>
                        <div className='form-group'>
                          <label>Zip Code</label>
                          <input
                            type='text'
                            name='address_zipCode'
                            className='form-control'
                            onChange={handleChange}
                            value={values.address_zipCode || ''}
                            required
                          />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label>City</label>
                          <input
                            type='text'
                            name='address_city'
                            className='form-control'
                            onChange={handleChange}
                            value={values.address_city || ''}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-6'>
                        <div className='form-group'>
                          <label>State</label>
                          <input
                            type='text'
                            name='address_state'
                            className='form-control'
                            onChange={handleChange}
                            value={values.address_state || ''}
                            required
                          />
                        </div>
                      </div>

                      <div className='col-6'>
                        <div className='form-group'>
                          <label>Country</label>
                          <input
                            type='text'
                            name='address_country'
                            className='form-control'
                            onChange={handleChange}
                            value={values.address_country || ''}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                )}

                {error && <ResponseError error={error} />}
                <button
                  type='submit'
                  className='btn btn-primary btn-block'
                  disabled={submitting}
                >
                  {submitting ? 'Saving...' : 'Save'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { currentUser } = state
  const client = state.clients[props.clientID]
  const accessCodes = selectAccessCodes(state)
  const accessCode =
    client &&
    accessCodes.filter(function (accessCode) {
      return accessCode.code === client.accessCode
    })[0]
  const initialValues = {
    ...client
  }

  if (client.address) {
    copyAttrsWithPrefix(client.address, initialValues, '', 'address_')
  }

  return {
    initialValues,
    accessCode,
    currentUser
  }
}

const mapDispatchToProps = {
  updateClient,
  getClientAccessCode
}

const form = FormHandler(EditClientModal)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default withRestricted(connected, 'device::profile::update')
