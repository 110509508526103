import React from 'react'
import { connect } from 'react-redux'
import {
  getClientSubscription,
  getClientMembership,
  cancelClientSubscription,
  reSubscribeClient,
  getShippingTracksSummary
} from '@/lib/redux/actions'
import { selectSubscriptions, selectMemberships } from '@/lib/redux/selectors'
import { formatTime } from '@/lib/functions'
import Restricted from '@/components/restricted'
import ShippingTrackDetails from './shipping-track-details'
import ShippingException from './shipping-exception'
import billingApi from '@/lib/api/billing'
import enterpriseApi from '@/lib/api/enterprise'

class SubscriptionDetails extends React.Component {
  componentDidMount () {
    const {
      getClientMembership,
      getClientSubscription,
      getShippingTracksSummary,
      clientID
    } = this.props

    getClientSubscription(clientID).then(subscription => {
      if (subscription) getShippingTracksSummary()
    })

    getClientMembership(clientID)
  }

  pauseSubscription () {
    const { subscription } = this.props

    if (!confirm('Are you sure you want to pause the subscription?')) return

    enterpriseApi
      .pauseSubscription(subscription.id)
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        alert(err.message)
      })
  }

  unpauseSubscription () {
    const { subscription } = this.props

    if (!confirm('Are you sure you want to unpause the subscription?')) return

    enterpriseApi
      .resumeSubscription(subscription.id)
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        alert(err.message)
      })
  }

  cancelSubscription () {
    const { subscription, cancelClientSubscription } = this.props

    if (!confirm('Are you sure you want to cancel the subscription?')) return

    cancelClientSubscription(subscription.id)
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        alert(err.message)
      })
  }

  reSubscribeClient () {
    const { subscription, reSubscribeClient } = this.props

    if (!confirm('Are you sure you want to re-subscribe this client?')) return

    reSubscribeClient(subscription.id)
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        alert(err.message)
      })
  }

  // TODO: Move this to enterprise-api, it is using batch endpoint from billing-api
  enrollClient (clientID) {
    billingApi
      .createSubscriptions([clientID])
      .then(data => {
        alert('Member enrollment enqueued.')
      })
      .catch(() => {
        alert('Member couldnt be enrolled. Something went wrong.')
      })
  }

  render () {
    const { subscription, client } = this.props

    if (!subscription && client && client.memberState === 'registered') {
      return (
        <div className='row mb-5'>
          <div className='col-6'>
            <h5 className='mb-3'>Subscription details</h5>

            <p className='mb-3'>
              <strong>Status</strong>
              <br />
              {client.memberState}
            </p>
          </div>

          <div className='col-6'>
            <div className='border rounded border-danger p-2'>
              <div>
                <h5 className='text-danger mb-3'>Danger Zone</h5>

                <Restricted policy='device::subscription::update'>
                  <div className='mb-5'>
                    <h5>Enroll member</h5>

                    <p>This member doesnt have any subscription.</p>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => this.enrollClient(client.id)}
                    >
                      Enroll member
                    </button>
                  </div>
                </Restricted>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (!subscription) return null

    return (
      <div className='row mb-5'>
        <div className='col-6'>
          <h5 className='mb-3'>Subscription details</h5>

          <Restricted policy='device::subscription'>
            <p className='mb-3'>
              <strong>Payment plan</strong>
              <br />
              {subscription.paymentPlan?.name}
            </p>

            <p className='mb-3'>
              <strong>Status</strong>
              <br />
              {subscription.status}
            </p>

            <p className='mb-3'>
              <strong>Started at</strong>
              <br />
              {formatTime(subscription.startedAt, 'll')}
            </p>

            {subscription.canceledAt && (
              <p className='mb-3'>
                <strong>Canceled at</strong>
                <br />
                {formatTime(subscription.canceledAt, 'll')}
              </p>
            )}

            {subscription.pausedAt && (
              <p className='mb-3'>
                <strong>Paused at</strong>
                <br />
                {formatTime(subscription.pausedAt, 'll')}
              </p>
            )}

            {subscription.paymentSourceInfo && (
              <p>
                <strong>Credit Card</strong>
                <br />
                {subscription.paymentSourceInfo.brand} ending with{' '}
                {subscription.paymentSourceInfo.last4}
              </p>
            )}
          </Restricted>

          <Restricted policy='device::shipping::automation'>
            <ShippingTrackDetails client={client} />
            {client.shippingTrackID && <ShippingException client={client} />}
          </Restricted>
        </div>

        <div className='col-6'>
          <Restricted policy='device::subscription::update'>
            <div className='border rounded border-danger p-2'>
              {!subscription.canceledAt && (
                <h5 className='text-danger mb-3'>Danger Zone</h5>
              )}

              {subscription.pausedAt
                ? (
                  <div className='mb-5'>
                    <h5>Unpause subscription</h5>

                    <p>
                      Subscription is <b>PAUSED</b>.<br />
                      This means that no payments have been charged to this client{' '}
                      since {formatTime(subscription.pausedAt, 'll')}.
                    </p>
                    <p>
                      Click the button below to unpause subscription and start
                      charging again.
                    </p>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => this.unpauseSubscription()}
                    >
                      Unpause subscription
                    </button>
                  </div>
                  )
                : (
                    !subscription.canceledAt && (
                      <div className='mb-5'>
                        <h5>Pause subscription</h5>

                        <p>
                          Click the button below to pause the subscription.
                          <br />
                          Pausing subscription will stop charging <b>ALL</b>{' '}
                          payments until you manually unpause it again.
                        </p>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm'
                          onClick={() => this.pauseSubscription()}
                        >
                          Pause subscription
                        </button>
                      </div>
                    )
                  )}

              {subscription.canceledAt
                ? (
                  <div>
                    <h5>Re-subscribe client</h5>

                    <p>Make this client active again.</p>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => this.reSubscribeClient()}
                    >
                      Re-subscribe
                    </button>
                  </div>
                  )
                : (
                  <div>
                    <h5>Cancel subscription</h5>

                    <p>
                      Make this client inactive by canceling their subscription.
                    </p>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => this.cancelSubscription()}
                    >
                      Cancel subscription
                    </button>
                  </div>
                  )}
            </div>
          </Restricted>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  // NOTE: Assuming the only subscription in state is for the client.
  // We need a way to tell whose client, is the subscription.
  const subscription = selectSubscriptions(state)[0]
  const membership = selectMemberships(state)[props.clientID]

  return {
    subscription,
    membership,
    client: state.clients[props.clientID]
  }
}

const mapDispatchToProps = {
  getClientSubscription,
  getClientMembership,
  cancelClientSubscription,
  reSubscribeClient,
  getShippingTracksSummary
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails)
