import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.accessCodes
      }

    default:
      return state
  }
}

export function getCorporateCodes (accountID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getCorporateCodes(accountID).then(function (response) {
      const data = normalize(response, [schema.accessCode])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function getSiteAccessCodes (siteID, params) {
  return function (dispatch, getState, { api, schema }) {
    return api.getSiteAccessCodes(siteID, params).then(function (response) {
      const data = normalize(response, [schema.accessCode])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function getClientAccessCode (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientAccessCode(clientID).then(function (response) {
      const data = normalize(response, schema.accessCode)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function inviteClient (values) {
  return function (dispatch, getState, { api, schema }) {
    return api.inviteClient(values).then(function (response) {
      const data = normalize(response, schema.accessCode)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function createCorporateRegistrationCode (values) {
  return function (dispatch, getState, { api, schema }) {
    return api
      .createCorporateRegistrationCode(values)
      .then(function (response) {
        const data = normalize(response, schema.accessCode)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
