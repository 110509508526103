import React from 'react'

class Address extends React.Component {
  render () {
    const {
      street1,
      street2,
      city,
      state,
      zipCode,
      country
    } = this.props

    return (
      <address>
        {street1}<br />
        {street2 && (
          <>
            {street2}<br />
          </>
        )}
        {city}, {state} {zipCode}<br />
        {country}
      </address>
    )
  }
}

export default Address
