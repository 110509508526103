import React from 'react'
import FormHandler from '@/components/form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '@/components/restricted'
import api from '../../lib/api'
import ClientDisplayName from '@/components/client-display-name'

class ExpressPickupModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      client: props.client,
      accessCode: null,
      dmrs: [],
      error: undefined
    }

    this.onAccessCodeChange = this.onAccessCodeChange.bind(this)
    this.eligibilityLegend = this.eligibilityLegend.bind(this)
  }

  componentDidMount () {
    const { client, changeAll } = this.props

    if (client) {
      changeAll({ accessCode: client.accessCode })
      this.retrieveAccessCode(client.id)
    }

    api.paginateDeviceMasterRecords({ page: 1, per_page: 100 }).then(data => {
      this.setState({ dmrs: data.dmrs.map(d => d.dmr) })
    })
  }

  eligibilityLegend (client, accessCode) {
    if (!accessCode || !accessCode.eligibilityRequired) return null

    return client.subscriptionEligible ? (
      <span className='text-success mr-2 ml-auto'>Eligibility Confirmed</span>
    ) : (
      <span className='text-danger mr-2 ml-auto'>Eligibility Pending</span>
    )
  }

  submit (values) {
    if (!this.state.client) {
      alert(`Client code "${values.accessCode}" not found.`)
      return
    }

    const { id } = this.state.client

    return api
      .expressDevicePickup(id, {
        type: 'base_unit',
        ...values
      })
      .then(data => {
        this.onDeviceAssignedSuccessfully()
      })
      .catch(request => {
        const { data, status } = request.response

        if (status === 422 && data.error === 'assigned_to_another_client') {
          console.log('assigned_to_another_client')
          this.setState({
            error:
              'Unable to assign this serial number. It has already been assigned to another account'
          })
          return
        }

        // Threat it as success
        if (status === 422 && data.error === 'already_assigned_to_client') {
          this.onDeviceAssignedSuccessfully()
          return
        }

        if (status === 422 && data.error) {
          this.setState({
            error: data.error
          })
          return
        }
      })
  }

  onDeviceAssignedSuccessfully () {
    const { reset } = this.props

    this.setState({ error: undefined })
    alert('Device assigned and delivered!')
    reset()
  }

  onAccessCodeChange (ev) {
    const { handleChange } = this.props
    const value = ev.target.value.trim()

    if (value.length >= 6) {
      api.searchClients(value).then(data => {
        if (data[0]) {
          this.setState({ client: data[0], error: undefined })
          this.retrieveAccessCode(data[0].id)
        } else {
          this.setState({
            error: `The Order Confirmation Number “${value}” was not found`
          })
        }
      })
    } else {
      this.setState({ client: undefined, error: undefined })
    }

    return handleChange(ev)
  }

  retrieveAccessCode (clientId) {
    api.getClientAccessCode(clientId).then(data => {
      this.setState({ accessCode: data })
    })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      modal,
      children
    } = this.props

    const { client, dmrs, accessCode, error } = this.state

    return (
      <>
        {children({ toggle: modal.toggle })}
        <modal.Modal>
          <modal.Header>On-site Pickup</modal.Header>

          <form onSubmit={handleSubmit(values => this.submit(values))}>
            <modal.Body>
              <div className='form-group'>
                <label>Order Confirmation Number</label>
                <input
                  type='text'
                  name='accessCode'
                  placeholder='e.g. 123456'
                  className='form-control'
                  onChange={this.onAccessCodeChange}
                  value={values.accessCode || ''}
                  required
                />
              </div>

              <div className='form-group'>
                <label>Name</label>
                <div className='form-control border-0'>
                  {values.accessCode && client && (
                    <ClientDisplayName client={client} link>
                      {this.eligibilityLegend(client, accessCode)}
                    </ClientDisplayName>
                  )}
                </div>
              </div>

              <div className='form-group'>
                <label>Serial Number</label>
                <input
                  type='number'
                  name='serialNumber'
                  placeholder='e.g. 123456'
                  className='form-control'
                  onChange={handleChange}
                  value={values.serialNumber || ''}
                  required
                />
              </div>

              <div className='form-group'>
                <label>DMR</label>
                <select
                  className='form-control custom-select custom-select-sm'
                  name='newBaseUnitDmr'
                  onChange={handleChange}
                  value={values.newBaseUnitDmr || ''}
                >
                  <option value=''>- Latest -</option>
                  {dmrs.map(dmr => (
                    <option value={dmr} key={dmr}>
                      {dmr}
                    </option>
                  ))}
                </select>
              </div>
            </modal.Body>

            <modal.Footer>
              <span className='text-danger text-wrap w-50 mr-auto'>
                {error}
              </span>

              <button
                type='submit'
                className='btn btn-primary'
                disabled={submitting}
              >
                {submitting ? 'Assigning...' : 'Assign'}
              </button>

              <modal.Cancel />
            </modal.Footer>
          </form>
        </modal.Modal>
      </>
    )
  }
}

const form = FormHandler(ExpressPickupModal)
const modaled = withModalManager(form)

export default withRestricted(modaled, 'device::products::assign')
