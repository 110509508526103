import React from 'react'
import { formatTime } from '@/lib/functions'
import { connect } from 'react-redux'
import ShippingBatchShipments from './shipping-batch-shipments'
import { createShippingBatchForTrack } from '@/lib/redux/actions'

class ShippingBatch extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      error: null
    }

    this.handleCreateLabels = this.handleCreateLabels.bind(this)
  }

  get statusLabel () {
    const { createdAt } = this.shippingBatch

    if (this.isProcessing) {
      return 'Creating orders in ShipStation... You may need to click "Update All Stores" in the ShipStation toolbar for this to complete.'
    }

    if (this.isProcessed) {
      const formattedDate = formatTime(createdAt, 'LL')
      return `Orders Created on ${formattedDate}`
    }

    return 'Orders Not Created'
  }

  get isProcessing () {
    const { status } = this.shippingBatch
    return status === 'processing'
  }

  get isProcessed () {
    const { status } = this.shippingBatch
    return status === 'processed'
  }

  get shippingBatch () {
    return this.props.shippingBatch
  }

  get canCreateLabels () {
    const { createdAt } = this.shippingBatch
    return !createdAt
  }

  get shipmentsCreatedCount () {
    if (!this.isProcessed) return 0

    const { shipmentsSummary } = this.shippingBatch
    return shipmentsSummary.exception + shipmentsSummary.success
  }

  handleCreateLabels () {
    const { date, track, createShippingBatchForTrack, onCreate } = this.props

    this.setState({ error: null })

    return createShippingBatchForTrack({ date, trackId: track.id })
      .then((batch) => onCreate(batch, track.id))
      .catch((error) => {
        this.setState({ error: error.graphQLErrors[0].message })
      })
  }

  render () {
    const { track, shippingBatch } = this.props
    const { error } = this.state

    return (
      <div>
        <div className='card mb-4'>
          <div className='card-body d-flex'>
            <div className='order-1'>
              {error && (
                <span className='text-danger mr-2'>{error}</span>
              )}
              <button
                className={`btn btn-${this.canCreateLabels ? 'primary' : 'secondary'}`}
                type='button'
                onClick={this.handleCreateLabels}
                disabled={!this.canCreateLabels}
              >
                Create Orders
              </button>
            </div>

            <div className='order-0 mr-auto'>
              <p>
                <span className='text-secondary mr-2'>
                  Week Number:
                </span>
                <strong>{this.shippingBatch.week}</strong>
              </p>
              <p>
                <span className='text-secondary mr-2'>
                  Shipping Track:
                </span>
                <strong>{track.name}</strong>
              </p>
              <p>
                <span className='text-secondary mr-2'>
                  Current Status:
                </span>
                <strong>{this.statusLabel}</strong>
              </p>
              <p>
                <span className='text-secondary mr-2'>
                  Shipments Created:
                </span>
                <strong>{this.shipmentsCreatedCount}</strong>
              </p>
            </div>
          </div>
        </div>

        {shippingBatch.id !== 0 && (
          <ShippingBatchShipments shippingBatch={shippingBatch} />
        )}

      </div>
    )
  }
}

const mapDispatchToProps = {
  createShippingBatchForTrack
}

export default connect(null, mapDispatchToProps)(ShippingBatch)
