import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.clients
      }
    default:
      return state
  }
}

export function getClientMembership (id) {
  return function (dispatch, getState, { enterpriseApi, schema }) {
    return enterpriseApi.getMemberMembership(id).then(function (response) {
      const data = normalize(response, schema.membership)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
