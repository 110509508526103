import querystring from 'qs'
import moment from 'moment-timezone'
import { values } from 'lodash'
import { UPS_SERVICES, FEDEX_SERVICES } from './constants'

export const ACCESS_TOKEN_KEY = '_ut'
export const TEMP_ACCESS_TOKEN_KEY = '_tut'
export const CURRENT_TENANT_KEY = '_tnt.v2'

export function getParams () {
  const search = window.location.search.replace('?', '')
  return querystring.parse(search)
}

export function toParams (hash) {
  return querystring.stringify(hash)
}

export function storeKey (key, value) {
  const { localStorage } = window
  localStorage.setItem(key, value)
}

export function fetchKey (key) {
  const { localStorage } = window
  return localStorage.getItem(key)
}

export function removeKey (key) {
  const { localStorage } = window
  localStorage.removeItem(key)
}

export function formatPhoneNumber (str) {
  if (!str) return str

  const cleaned = str.replace(/[^+\d]/g, '')
  const match = cleaned.match(/^(.*)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const intlCode = match[1] ? `${match[1]} ` : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return str
}

export function formatCents (cents) {
  return `$${cents / 100}`
}

export function toCents (value) {
  return Math.round(parseFloat(value) * 100)
}

export function formatTime (date, format = 'lll', timeZone = null) {
  if (!date) return

  const momentDate = timeZone ? moment.tz(date, timeZone) : moment(date)

  return momentDate.format(format)
}

export function convertToTimeZone (date, timeZone) {
  if (!date) return

  return moment(date).tz(timeZone)
}

export function localTimeZone () {
  return moment.tz.guess()
}

export function subtractTime (from, amount, unit) {
  return moment(from).subtract(amount, unit).toDate()
}

export function addTime (from, amount, unit) {
  return moment(from).add(amount, unit).toDate()
}

export function parseDate (str, timeZone = null) {
  if (!str) return null

  const momentDate = timeZone ? moment.tz(str, timeZone) : moment(str)

  return momentDate.toDate()
}

export function setPageTitle (title) {
  document.getElementsByTagName(
    'title'
  )[0].innerHTML = `${title} - Device Portal`
}

export function calculateErrorRate (success, errors) {
  const total = success + errors
  const rate = errors / total

  if (isNaN(rate)) {
    return '0'
  }

  return (rate * 100).toFixed(1)
}

export function isToday (date) {
  return moment(date).isSame(new Date(), 'day')
}

export function weekNumber (date) {
  return moment(date).isoWeek()
}

export function beginningOfTime (time, unit) {
  return moment(time).startOf(unit).toDate()
}

export function endOfTime (time, unit) {
  return moment(time).endOf(unit).toDate()
}

export function inGroupsOf (collection, groupSize) {
  const groups = collection.reduce(function (memo, item, index) {
    const key = index % groupSize
    memo[key] = memo[key] || []
    memo[key].push(item)

    return memo
  }, {})

  return values(groups)
}

export function preventDefault (callback) {
  return function (ev) {
    ev.preventDefault()
    callback()
  }
}

export function authorizationHeaders () {
  const headers = { 'X-Portal-App': 'device' }
  const token = fetchKey(ACCESS_TOKEN_KEY)

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

export function formatWeekDate (week, year) {
  const date = moment().year(year).day(1).isoWeek(week)
  return formatTime(date, 'LL')
}

export function formatBillingInterval (paymentPlan) {
  if (paymentPlan.intervalCount > 1) {
    return `${paymentPlan.intervalCount} ${paymentPlan.interval}s`
  }

  return `${paymentPlan.intervalCount} ${paymentPlan.interval}`
}

export function shippingServiceName (slug) {
  let service = FEDEX_SERVICES.filter(s => s.slug === slug)[0]
  service = service || UPS_SERVICES.filter(s => s.slug === slug)[0]
  return service ? service.name : ''
}
