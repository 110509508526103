import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {
  BUGSNAG_KEY,
  APP_VERSION,
  APP_STAGE
} from '@/lib/env'
import store from '@/lib/redux/store'

let ErrorBoundary = ({ children }) => children

if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    appType: 'device-portal',
    appVersion: APP_VERSION,
    releaseStage: APP_STAGE,
    plugins: [new BugsnagPluginReact()],
    beforeSend: function (report) {
      const { currentUser } = store.getState()

      report.user = currentUser

      if (/401|4/.test(report.errorMessage)) {
        report.ignore()
      }
    }
  })

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
}

export default ErrorBoundary
