import React from 'react'
import NavBar from '@/components/nav-bar'
import SearchResults from '../../components/search-results'
import store from '@/lib/redux/store'
import * as Actions from '@/lib/redux/actions'
import { getParams } from '@/lib/functions'

class ClientsSearch extends React.Component {
  componentDidMount () {
    const params = getParams()

    store.dispatch(Actions.searchClients(params.query))
  }

  render () {
    return (
      <div>
        <NavBar left='Search Results' backUrl='/' showSearch />
        <div className='container'>
          <SearchResults />
        </div>
      </div>
    )
  }
}

export default ClientsSearch
