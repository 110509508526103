import React from 'react'
import { connect } from 'react-redux'
import SearchBar from '@/components/search-bar'
import Restricted from '@/components/restricted'

function NavLink (props) {
  const { href, children } = props
  const activeClass = href === window.location.pathname ? 'active' : ''

  return (
    <a className={`flex-fill ${activeClass}`} href={href}>
      {children}
    </a>
  )
}

class UserNavBar extends React.Component {
  render () {
    const { currentUser, left, right, backUrl } = this.props

    return (
      <div className='drop-shadow mb-5'>
        <nav className='main-page-nav navbar bg-white border-bottom navbar-light'>
          <div className='container'>
            <div className='navbar-brand'>
              {backUrl && (
                <a className='mr-4' href={backUrl}>
                  <img src='/assets/arrow-back.svg' height='16' alt='Back' />
                </a>
              )}
              <a href='/' className='mr-3'>
                <img
                  src='/assets/logo.svg'
                  height='30'
                  className='align-top'
                  alt='Invoy'
                />
              </a>
              {left || 'Device Portal'}
            </div>

            {currentUser && (
              <div className='d-flex flex-fill justify-content-end'>
                <SearchBar />

                {right || (
                  <div className='dropdown'>
                    <button
                      className='btn'
                      type='button'
                      data-toggle='dropdown'
                    >
                      Hi {currentUser.firstName}
                    </button>
                    <div className='dropdown-menu dropdown-menu-right'>
                      <a href='/logout' className='dropdown-item'>
                        Log out
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </nav>

        {currentUser && (
          <div className='bg-white'>
            <nav className='container d-flex justify-content-center top-nav'>
              <Restricted policy='device::profile::tasks'>
                <NavLink href='/tasks'>TASKS</NavLink>
              </Restricted>

              <Restricted policy='device::shipments'>
                <NavLink href='/shipments'>CUSTOM SHIPMENTS</NavLink>
              </Restricted>

              <Restricted policy='device::shipping::automation'>
                <NavLink href='/shipping'>AUTOMATED SHIPMENTS</NavLink>
              </Restricted>

              <NavLink href='/clients'>MEMBERS</NavLink>

              <Restricted policy='device::new_clients'>
                <NavLink href='/new_clients'>NEW CLIENTS</NavLink>
              </Restricted>

              <Restricted policy='device::products'>
                <NavLink href='/dmrs'>DMRs</NavLink>
              </Restricted>
            </nav>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { currentUser } = state

  return {
    currentUser
  }
}

export default connect(mapStateToProps)(UserNavBar)
