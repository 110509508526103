import React from 'react'
import moment from 'moment'
import { countBy } from 'lodash'
import ClientDisplayName from '@/components/client-display-name'

function assignedClassName (tasks, trigger) {
  const triggerTasks = tasks.filter(function (task) {
    return task.trigger === trigger
  })
  const allAssigned = triggerTasks.every(function (task) {
    return task.agent
  })

  return allAssigned ? 'assigned' : ''
}

function ClientRow (props) {
  const { id, tasks } = props
  const oldestTask = tasks[0]
  const openTaskTime = moment(oldestTask.createdAt).fromNow()
  const triggers = countBy(tasks, 'trigger')

  return (
    <div className='d-flex align-items-center data-row text-color'>
      <span className='mr-auto d-flex'>
        <span className='text-nowrap mr-5'>
          <ClientDisplayName client={props} link />
        </span>
      </span>

      <span className='text-right mr-3 pt-2'>
        {Object.keys(triggers).map((trigger) => (
          <span
            className={`badge badge-tag ${assignedClassName(tasks, trigger)} badge-sm mr-2 mb-2`}
            key={`client-${id}-trigger-${trigger}`}
          >
            {trigger} {triggers[trigger] > 1 && (
              <small className='font-weight-bold'>x{triggers[trigger]}</small>
            )}
          </span>
        ))}
      </span>
      <small className='subtitle text-nowrap mr-3'>{openTaskTime}</small>
    </div>
  )
}

export default ClientRow
