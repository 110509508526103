import { combineReducers } from 'redux'
import clients from './clients'
import memberships from './memberships'
import search from './search'
import currentUser from './current-user'
import shipments from './shipments'
import products from './products'
import productReadings from './product-readings'
import productPresets from './product-presets'
import paymentPlans from './payment-plans'
import accessCodes from './access-codes'
import referralCodes from './referral-codes'
import subscriptions from './subscriptions'
import tickets from './tickets'
import accounts from './accounts'
import tasks from './tasks'
import agents from './agents'
import replacements from './replacements'
import triggers from './triggers'
import sites from './sites'
import bodyCompositionReadings from './body-composition-readings'
import bodyCompositionReports from './body-composition-reports'
import ui from './ui'
import accessPolicies from './access-policies'
import shippingTracks from './shipping-tracks'

const rootReducer = combineReducers({
  clients,
  memberships,
  search,
  currentUser,
  shipments,
  products,
  productReadings,
  productPresets,
  paymentPlans,
  accessCodes,
  referralCodes,
  subscriptions,
  tickets,
  accounts,
  tasks,
  agents,
  replacements,
  triggers,
  sites,
  bodyCompositionReadings,
  bodyCompositionReports,
  ui,
  accessPolicies,
  shippingTracks
})

export default rootReducer
