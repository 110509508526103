import React from 'react'
import { connect } from 'react-redux'
import {
  AGE_REQUIREMENTS,
  DEVICE_ASSIGNMENT,
  LAST_NAME_REQUIREMENTS,
  INVITATION_EMAIL_TEMPLATE_IDS,
  EXPRESS_PICKUP_EMAIL_TEMPLATE_IDS
} from '../../lib/constants'
import { updateSite } from '../../lib/redux/actions'
import FormHandler from '../form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '../restricted'

function cleanupEmails (value) {
  const emails = []

  if (value.trim() !== '') {
    value.split(/, ?/).forEach(email => {
      if (email.trim() !== '') {
        emails.push(email.trim())
      }
    })
  }

  return emails
}

function EditSiteModal (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    reset,
    updateSite,
    site
  } = props

  const onSubmit = values => {
    const {
      workEmailHosts,
      expressPickupEmailTemplate,
      invitationEmailTemplate,
      ...otherValues
    } = values

    updateSite(site.id, {
      site: {
        ...otherValues,
        invitationEmailTemplate:
          invitationEmailTemplate === '' ? null : invitationEmailTemplate,
        expressPickupEmailTemplate:
          expressPickupEmailTemplate === '' ? null : expressPickupEmailTemplate,
        workEmailHosts: cleanupEmails(workEmailHosts)
      }
    }).then(() => {
      reset()
      modal.hide()
    })
  }

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Edit Site</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <div className='form-group'>
              <label>Name</label>
              <input
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                className='form-control'
              />
            </div>

            <div className='form-group'>
              <label>ToS URL</label>
              <input
                type='url'
                name='tosURL'
                value={values.tosURL}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. http://example.com/tos'
              />
            </div>

            <div className='form-group'>
              <label>Shipping carrier</label>
              <input
                name='shippingCarrier'
                value={values.shippingCarrier}
                className='form-control'
                readOnly
              />
            </div>

            <div className='form-group'>
              <label>Shipping service</label>
              <input
                name='shippingService'
                value={values.shippingService}
                className='form-control'
                readOnly
              />
            </div>

            <fieldset>
              <legend>Email Templates</legend>

              <div className='form-group'>
                <label>Invitation Template</label>
                <select
                  name='invitationEmailTemplate'
                  value={values.invitationEmailTemplate}
                  className='form-control'
                  onChange={handleChange}
                >
                  <option value=''>- None -</option>
                  {INVITATION_EMAIL_TEMPLATE_IDS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Express Pickup Template</label>
                <select
                  name='expressPickupEmailTemplate'
                  value={values.expressPickupEmailTemplate}
                  className='form-control'
                  onChange={handleChange}
                >
                  <option value=''>- None -</option>
                  {EXPRESS_PICKUP_EMAIL_TEMPLATE_IDS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>

            <fieldset>
              <legend>Rules</legend>

              <div className='form-group'>
                <label>Age requirement</label>
                <select
                  name='ageRequirement'
                  value={values.ageRequirement}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {AGE_REQUIREMENTS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Device assignment</label>
                <select
                  name='deviceAssignment'
                  value={values.deviceAssignment}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {DEVICE_ASSIGNMENT.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Last name requirement</label>
                <select
                  name='lastNameRequirement'
                  value={values.lastNameRequirement}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {LAST_NAME_REQUIREMENTS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Work Email Hosts:</label>
                <input
                  type='text'
                  name='workEmailHosts'
                  value={values.workEmailHosts}
                  className='form-control'
                  onChange={handleChange}
                  placeholder='eg. example.com,acme.com'
                />
                <span className='-small form-text text-muted'>
                  Comma separated hosts
                </span>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='emailRequired'
                    checked={!!values.emailRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Email required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='genderRequired'
                    checked={!!values.genderRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Gender required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='middleInitialRequired'
                    checked={!!values.middleInitialRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Middle initial required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='menopausalStatusRequired'
                    checked={!!values.menopausalStatusRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Menopausal status required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='phoneRequired'
                    checked={!!values.phoneRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Phone required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='shippingAddressRequired'
                    checked={!!values.shippingAddressRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Shipping address required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='skipPracticeBreathTest'
                    checked={!!values.skipPracticeBreathTest}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Skip practice breath test
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='healthKitGoogleFitEnabled'
                    checked={!!values.healthKitGoogleFitEnabled}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Health kit / Google Fit enabled
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='eligibilityRequired'
                    checked={!!values.eligibilityRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Subscription eligibility required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='requireInsuranceProvider'
                    checked={!!values.requireInsuranceProvider}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Insurance Provider
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='hideAccessCodeInput'
                    checked={!!values.hideAccessCodeInput}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Hide Access Code Input
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='enableInPersonPickup'
                    checked={
                      !!values.enableInPersonPickup ||
                      values.deviceAssignment === 'express_pickup'
                    }
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Enable In-Personal Shipment Pickup
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='requiresWaitlistValidation'
                    checked={!!values.requiresWaitlistValidation}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Waitlist Validation
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='paymentSourceRequired'
                    checked={!!values.paymentSourceRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Client Payment Source
                </label>
              </div>
            </fieldset>
          </modal.Body>

          <modal.Footer>
            <button className='btn btn-primary' type='submit'>
              Update
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

function mapStateToProps (state, props) {
  const { site } = props

  return {
    initialValues: {
      name: site.name,
      shippingAddressRequired: site.shippingAddressRequired,
      phoneRequired: site.phoneRequired,
      emailRequired: site.emailRequired,
      deviceAssignment: site.deviceAssignment,
      lastNameRequirement: site.lastNameRequirement,
      ageRequirement: site.ageRequirement,
      genderRequired: site.genderRequired,
      menopausalStatusRequired: site.menopausalStatusRequired,
      healthKitGoogleFitEnabled: site.healthKitGoogleFitEnabled,
      shippingCarrier: site.shippingCarrier,
      shippingService: site.shippingService,
      paymentSourceRequired: site.paymentSourceRequired,
      requireInsuranceProvider: site.requireInsuranceProvider,
      skipPracticeBreathTest: site.skipPracticeBreathTest,
      invitationEmailTemplate: site.invitationEmailTemplate,
      expressPickupEmailTemplate: site.expressPickupEmailTemplate,
      workEmailHosts: site.workEmailHosts.join(', '),
      tosURL: site.tosURL
    },
    site
  }
}

const mapDispatchToProps = {
  updateSite
}

const form = FormHandler(EditSiteModal)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)
const modaled = withModalManager(connected)

export default withRestricted(modaled, 'device::new_clients::accounts::create')
