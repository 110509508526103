import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import { withModalManager } from '../modal-manager'
import { assignProduct } from '@/lib/redux/actions'
import { withRestricted } from '@/components/restricted'
import api from '../../lib/api'

class AssignDeviceModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = { dmrs: [] }
  }

  componentDidMount () {
    api.paginateDeviceMasterRecords({ page: 1, per_page: 100 }).then(data => {
      this.setState({ dmrs: data.dmrs.map(d => d.dmr) })
    })
  }

  submit (values) {
    const { assignProduct, clientID, reset } = this.props

    return assignProduct(clientID, values)
      .then(function () {
        alert('Device assigned!')
        reset()
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      modal,
      children
    } = this.props
    const { dmrs, type } = this.state

    return (
      <>
        {children({ toggle: modal.toggle })}
        <modal.Modal>
          <modal.Header>Assign Device</modal.Header>

          <form onSubmit={handleSubmit(values => this.submit(values))}>
            <modal.Body>
              <div className='form-group'>
                <label>Type</label>
                <select
                  name='type'
                  className='form-control'
                  onChange={handleChange}
                  value={values.type || type || ''}
                  required
                >
                  <option>- Select -</option>
                  <option value='base_unit'>Base Unit</option>
                  <option value='mouth_piece'>Mouth Piece</option>
                </select>
              </div>

              <div className='form-group'>
                <label>Serial Number</label>
                <input
                  type='text'
                  name='serialNumber'
                  placeholder='e.g. 123456'
                  className='form-control'
                  onChange={handleChange}
                  value={values.serialNumber || ''}
                  required
                />
              </div>

              <div className='form-group'>
                <label>DMR</label>
                <select
                  className='form-control custom-select custom-select-sm'
                  name='dmr'
                  onChange={handleChange}
                  value={values.dmr || ''}
                >
                  <option value=''>- Select -</option>
                  {dmrs.map(dmr => (
                    <option value={dmr} key={dmr}>
                      {dmr}
                    </option>
                  ))}
                </select>
              </div>
            </modal.Body>

            <modal.Footer>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={submitting}
              >
                {submitting ? 'Assigning...' : 'Assign'}
              </button>

              <modal.Cancel />
            </modal.Footer>
          </form>
        </modal.Modal>
      </>
    )
  }
}

const mapDispatchToProps = {
  assignProduct
}

const form = FormHandler(AssignDeviceModal)
const connected = connect(null, mapDispatchToProps)(form)
const modaled = withModalManager(connected)

export default withRestricted(modaled, 'device::products::assign')
