import Promise from 'bluebird'
import moment from 'moment-timezone'
import camelize from 'camelize'
import snakeize from 'snakeize'
import axios from 'axios'
import gql from 'graphql-tag'
import {
  storeKey,
  removeKey,
  ACCESS_TOKEN_KEY,
  TEMP_ACCESS_TOKEN_KEY,
  toParams,
  authorizationHeaders,
  formatTime
} from '@/lib/functions'
import {
  API_SERVICE_URL,
  SHARED_API_GATEWAY_URL,
  SHARED_PROGRAM_API_URL
} from '@/lib/env'
import { TASK_STATUSES } from './schema'
import graphql from './graphql'

function toQueryString (params) {
  return params ? `?${toParams(snakeize(params))}` : ''
}

function buildAPIClient (baseURL) {
  const client = axios.create({
    baseURL,
    withCredentials: true
  })

  client.interceptors.request.use(
    function (config) {
      config.data = snakeize(config.data)
      config.headers = { ...config.headers, ...authorizationHeaders() }

      if (/\/device-portal$/.test(config.baseURL)) {
        config.baseURL = SHARED_API_GATEWAY_URL + '/device-portal'
      }

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    function (response) {
      const acronyms = { id: 'ID', url: 'URL' }

      response.originalData = response.data
      response.data = camelize(response.data, { acronyms })

      return response
    },
    function (error) {
      if (error?.response?.status === 401) {
        window.location = '/logout'
      }

      return Promise.reject(error)
    }
  )

  return client
}

const device = buildAPIClient(`${API_SERVICE_URL}/device-portal`)
const program = buildAPIClient(SHARED_PROGRAM_API_URL)
const api = buildAPIClient(API_SERVICE_URL)

export default {
  filterClients (params) {
    return device.post('/clients/filter', params).then(function (response) {
      return response.data
    })
  },

  getClient (id) {
    return device.get(`/clients/${id}`).then(function (response) {
      return response.data.client
    })
  },

  searchClients (query, params = {}) {
    return device
      .get(`/clients/search${toQueryString({ ...params, query })}`)
      .then(function (response) {
        return response.data.clients
      })
  },

  getClientSubscription (clientID) {
    return device
      .get(`/clients/${clientID}/subscription`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  getProductPresets () {
    return device.get('/product-presets').then(function (response) {
      return response.data
    })
  },

  cancelClientSubscription (clientID) {
    return device
      .post(`/clients/${clientID}/subscription/cancel`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  reSubscribeClient (clientID) {
    return device
      .post(`/clients/${clientID}/re-subscribe`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  getClientShipments (clientID) {
    return device
      .get(`/clients/${clientID}/shipments`)
      .then(function (response) {
        return response.data.shipments
      })
  },

  updateClient (id, values) {
    return device.patch(`/clients/${id}`, values).then(function (response) {
      return response.data.client
    })
  },

  confirmEligibility (id) {
    return device
      .post(`/clients/${id}/confirm-eligibility`)
      .then(function (response) {
        return response.data.client
      })
  },

  getClientNotes (id) {
    return device.get(`/clients/${id}/notes`).then(function (response) {
      return response.data.notes
    })
  },

  updateClientNotes (id, notes) {
    return device
      .patch(`/clients/${id}/notes`, { notes })
      .then(function (response) {
        return response.data.notes
      })
  },

  verifyCurrentUser () {
    return api.post('/auth/verify').then(function (response) {
      return response.data.user
    })
  },

  loginUser (values) {
    return api.post('/auth/login', values).then(function (response) {
      if (response.data.otpRequired) {
        storeKey(TEMP_ACCESS_TOKEN_KEY, response.data.token)
      } else {
        storeKey(ACCESS_TOKEN_KEY, response.data.token)
      }
      return response
    })
  },

  logoutUser () {
    return api.post('/auth/logout').then(function (response) {
      removeKey(ACCESS_TOKEN_KEY)
      return response
    })
  },

  verifyOTP (values) {
    return api.post('/auth/mfa/verify', values).then(function (response) {
      removeKey(TEMP_ACCESS_TOKEN_KEY)
      storeKey(ACCESS_TOKEN_KEY, response.data.token)
      return response.data.token
    })
  },

  resetPassword (email) {
    return api.post('/passwords/reset', { email }).then(function (response) {
      return response.data
    })
  },

  getShipments (params) {
    return device
      .get(`/shipments${toQueryString(params)}`)
      .then(function (response) {
        return response.data
      })
  },

  createClientShipment (clientID) {
    return device
      .post(`/clients/${clientID}/shipments`)
      .then(function (response) {
        return response.data.shipment
      })
  },

  cancelShipment (id) {
    return device.post(`/shipments/${id}/cancel`).then(function (response) {
      return response.data.shipment
    })
  },

  scheduleShipment (id, values) {
    return device
      .post(`/shipments/${id}/schedule`, values)
      .then(function (response) {
        return response.data.shipment
      })
  },

  addPartToShipment (shipmentId, values) {
    return device
      .post(`/shipments/${shipmentId}/parts`, values)
      .then(function (response) {
        return response.data.shipment
      })
  },

  addKitToShipment (shipmentId, values) {
    return device
      .post(`/shipments/${shipmentId}/kits`, values)
      .then(function (response) {
        return response.data.shipment
      })
  },

  handoffShipment (shipmentId, values) {
    return device
      .post(`/shipments/${shipmentId}/handoff`, values)
      .then(function (response) {
        return response.data.shipment
      })
  },

  removeProductFromShipment (shipmentId, productId) {
    return device
      .delete(`/shipments/${shipmentId}/products/${productId}`)
      .then(function (response) {
        return response.data.shipment
      })
  },

  updateProduct (id, values) {
    return device.patch(`/products/${id}`, values).then(function (response) {
      return response.data.product
    })
  },

  getClientProducts (clientID) {
    return device
      .get(`/clients/${clientID}/products`)
      .then(function (response) {
        return response.data.products
      })
  },

  deAssignProduct (id) {
    return device.post(`/products/${id}/de-assign`).then(function (response) {
      return response.data.product
    })
  },

  assignProduct (clientID, values) {
    return device
      .post(`/clients/${clientID}/products/assign`, values)
      .then(function (response) {
        return response.data.product
      })
  },

  expressDevicePickup (clientID, values) {
    return device
      .post(`/clients/${clientID}/products/express_pickup`, values)
      .then(function (response) {
        return response.data.product
      })
  },

  getDevices (params) {
    return device
      .get(`/devices${toQueryString(params)}`)
      .then(function (response) {
        return response.data.devices
      })
  },

  getDeviceBySerialNumber (serialNumber) {
    const params = toQueryString({ serial_number: serialNumber })
    return device
      .get(`/devices/serial_number${params}`)
      .then(function (response) {
        return response.data.device
      })
      .catch(error => {
        if (error.response.status === 404) return null
        throw error
      })
  },

  getProduct (id) {
    return device.get(`/products/${id}`).then(function (response) {
      return response.data.product
    })
  },

  getProductReadings (productID) {
    return device
      .get(`/products/${productID}/readings`)
      .then(function (response) {
        return response.data.productReadings
      })
  },

  deleteProductReadings (productID) {
    return device.delete(`/products/${productID}/readings`)
  },

  createProduct (values) {
    return device.post('/products', values).then(function (response) {
      return response.data.product
    })
  },

  updateShipmentAddress (shipmentID, values) {
    return device
      .patch(`/shipments/${shipmentID}/address`, values)
      .then(function (response) {
        return response.data.shipment
      })
  },

  updateShipment (id, values) {
    return device.patch(`/shipments/${id}`, values).then(function (response) {
      return response.data.shipment
    })
  },

  getPaymentPlans () {
    return device.get('/payment-plans').then(function (response) {
      return response.data.paymentPlans
    })
  },

  getEnterpriseAccounts () {
    return device.get('/enterprise/accounts').then(function (response) {
      return response.data.accounts
    })
  },

  getAccount (id) {
    return device.get(`/accounts/${id}`).then(function (response) {
      return response.data.account
    })
  },

  getAccountSubscription (accountID) {
    return device
      .get(`/accounts/${accountID}/subscription`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  inviteClient (values) {
    return device.post('/clients/invite', values).then(function (response) {
      return response.data.accessCode
    })
  },

  bulkInviteClients (values) {
    return device
      .post('/clients/bulk-invite', values)
      .then(function (response) {
        return response.data.bulkResult
      })
  },

  createCorporateRegistrationCode (values) {
    return device.post('/corporate-codes', values).then(function (response) {
      return response.data.accessCode
    })
  },

  getClientAccessCode (clientID) {
    return device
      .get(`/clients/${clientID}/access-code`)
      .then(function (response) {
        return response.data.accessCode
      })
  },

  getReferredAndJoined (code) {
    return device
      .get(`/access-codes/${code}/referred-joined`)
      .then(function (response) {
        return response.data.clients
      })
  },

  getCorporateCodes (accountID) {
    return device
      .get(`/accounts/${accountID}/corporate-codes`)
      .then(function (response) {
        return response.data.accessCodes
      })
  },

  getReferralCodes (accountID) {
    return program
      .get('/enterprise-api/referral-codes')
      .then(function (response) {
        return response.data.referralCodes
      })
  },

  createReferralCode (values) {
    return program
      .post('/enterprise-api/referral-codes', { referral_code: values })
      .then(function (response) {
        return response.data.referralCode
      })
  },

  getClientTickets (clientID) {
    return device.get(`/clients/${clientID}/tickets`).then(function (response) {
      return response.data.tickets
    })
  },

  createTicket (clientID, values) {
    return device
      .post(`/clients/${clientID}/tickets`, values)
      .then(function (response) {
        return response.data.ticket
      })
  },

  createTicketMessage (clientID, ticketID, values) {
    return device
      .post(`/clients/${clientID}/tickets/${ticketID}/messages`, values)
      .then(function (response) {
        return response.data.ticketMessage
      })
  },

  getTicketUploadURL (room) {
    return api.get(`/api/chat/${room}/upload`).then(function (response) {
      return response.originalData
    })
  },

  uploadTicketImage (room, file) {
    return this.getTicketUploadURL(room).then(function (response) {
      const data = new FormData()
      const { fields, url } = response

      Object.keys(fields).forEach(function (field) {
        data.append(field, fields[field])
      })
      data.append('file', file)

      return axios.post(url, data).then(function () {
        return fields.key
      })
    })
  },

  fetchFileSignedUrl (url) {
    return api.get(url).then(function (response) {
      return response.data.url
    })
  },

  getWaitlist () {
    return device.get('/waitlist').then(function (response) {
      return response.data.waitlist
    })
  },

  deleteWaitlist (id) {
    return device.delete(`/waitlist/${id}`)
  },

  getOpenTasks () {
    return device.get('/tasks/open').then(function (response) {
      return response.data.tasks
    })
  },

  getFilteredTasks (params) {
    return device
      .get(`/tasks/filter${toQueryString(params)}`)
      .then(function (response) {
        return response.data
      })
  },

  getTodayTasks () {
    return device.get('/tasks/today').then(function (response) {
      return response.data.tasks
    })
  },

  getClientTasks (clientID) {
    return device.get(`/clients/${clientID}/tasks`).then(function (response) {
      return response.data.tasks
    })
  },

  markTaskAsCompleted (taskID) {
    return this.updateTask(taskID, { status: TASK_STATUSES.COMPLETED })
  },

  markTaskAsInvalid (taskID) {
    return this.updateTask(taskID, { status: TASK_STATUSES.INVALID })
  },

  createTask (clientID, values) {
    return device
      .post(`/clients/${clientID}/tasks`, values)
      .then(function (response) {
        return response.data.task
      })
  },

  deleteTask (id) {
    return device.delete(`/tasks/${id}`)
  },

  updateTask (id, values) {
    return device.patch(`/tasks/${id}`, values).then(function (response) {
      return response.data.task
    })
  },

  getAgents () {
    return device.get('/agents').then(function (response) {
      return response.data.agents
    })
  },

  getReplacements () {
    return device.get('/shipments/replacements').then(function (response) {
      return response.data.replacements
    })
  },

  getClientReplacements (clientID) {
    return device
      .get(`/clients/${clientID}/shipments/replacements`)
      .then(function (response) {
        return response.data.replacements
      })
  },

  updateReplacement (shipmentID, productID, values) {
    return device
      .patch(`/shipments/${shipmentID}/replacements/${productID}`, values)
      .then(function (response) {
        return response.data.replacement
      })
  },

  getTriggers () {
    return device.get('/triggers').then(function (response) {
      return response.data.triggers
    })
  },

  getWeeklyMetrics (params) {
    return device
      .get(`/metrics/weekly${toQueryString(params)}`)
      .then(function (response) {
        return response.data.metrics
      })
  },

  getAccountInvoicingReport (accountID, params) {
    return device
      .get(`/accounts/${accountID}/invoicing/report${toQueryString(params)}`)
      .then(function (response) {
        return response.data.report
      })
  },

  getBodyCompositionReportUploadURL () {
    return api
      .get('/api/body-composition/reports/upload')
      .then(function (response) {
        return response.originalData
      })
  },

  uploadBodyCompositionReport (file) {
    return this.getBodyCompositionReportUploadURL().then(function (response) {
      const data = new FormData()
      const { fields, url } = response

      Object.keys(fields).forEach(function (field) {
        data.append(field, fields[field])
      })
      data.append('file', file)

      return axios.post(url, data).then(function () {
        return fields.key
      })
    })
  },

  getSite (id) {
    return device.get(`/sites/${id}`).then(function (response) {
      return response.data.site
    })
  },

  getSites () {
    return device.get('/sites').then(function (response) {
      return response.data.sites
    })
  },

  getAccountSites (accountID) {
    return device.get(`/accounts/${accountID}/sites`).then(function (response) {
      return response.data.sites
    })
  },

  createSite (accountID, values) {
    return device
      .post(`/accounts/${accountID}/sites`, values)
      .then(function (response) {
        return response.data.site
      })
  },

  updateSite (siteID, values) {
    return device.patch(`/sites/${siteID}`, values).then(function (response) {
      return response.data.site
    })
  },

  getSiteAccessCodes (siteID, params) {
    return device
      .get(`/sites/${siteID}/access-codes${toQueryString(params)}`)
      .then(function (response) {
        return response.data.accessCodes
      })
  },

  createBodyCompositionReading (id, values) {
    return device
      .post(`/clients/${id}/body-composition-readings`, { values })
      .then(function (response) {
        return response.data.reading
      })
  },

  getClientBodyCompositionReadings (clientID) {
    return device
      .get(`/clients/${clientID}/body-composition-readings`)
      .then(function (response) {
        return response.data.readings
      })
  },

  getBodyCompositionReports () {
    return device.get('/body-composition-reports').then(function (response) {
      return response.data.reports
    })
  },

  createBodyCompositionReport (fileKey, csv) {
    const tzOffset = moment().format('ZZ')
    return device
      .post('/body-composition-reports/upload', {
        fileKey,
        tzOffset,
        csv
      })
      .then(function (response) {
        return response.data
      })
  },

  resetClientPassword (id) {
    return device
      .post(`/clients/${id}/reset-password`)
      .then(function (response) {
        return response.data.url
      })
  },

  escalateTaskToProgram (taskID, values) {
    return device
      .post(`/tasks/${taskID}/escalate-program`, values)
      .then(function (response) {
        return response.data.task
      })
  },

  getAccessPolicies () {
    return api.get('/api/policies').then(function (response) {
      return response.data.policies
    })
  },

  setupDemoKit (params) {
    return api.post('/auth/demo-kit/setup', params).then(function (response) {
      return response.data
    })
  },

  getShippingTrackBatchesForDate (date) {
    const query = gql`
      query getShippingTrackBatchesForDate($date: ISO8601Date!) {
        shippingTracks(date: $date) {
          nodes {
            id
            name
            nextDateSchedule
            nextWeekSchedule
            shippingBatches {
              id
              status
              week
              shipmentsSummary
              createdAt
            }
          }
        }
      }
    `
    return graphql
      .query({
        query,
        variables: { date }
      })
      .then(({ data }) => {
        return data.shippingTracks.nodes
      })
  },

  getTrackFromDate (date) {
    const query = gql`
      query ShippingTracksQuery($date: ISO8601Date!) {
        shippingTracks(date: $date) {
          nodes {
            id
            name
            nextDateSchedule
            nextWeekSchedule
          }
        }
      }
    `
    return graphql // njsscan-ignore: node_sqli_injection
      .query({
        query,
        variables: { date: formatTime(date, 'YYYY-MM-DD') }
      })
      .then(({ data }) => {
        return data.shippingTracks.nodes[0]
      })
  },

  getShippingTracksSummary () {
    const query = gql`
      query getTracksSummary {
        shippingTracks {
          nodes {
            id
            name
            nextDateSchedule
            nextWeekSchedule
          }
        }
      }
    `
    return graphql.query({ query }).then(({ data }) => {
      return data.shippingTracks.nodes
    })
  },

  getShippingTrack (id) {
    const query = gql`
      query ShippingTrackQuery($id: Int!) {
        shippingTrack(id: $id) {
          id
          name
          nextDateSchedule
          nextWeekSchedule
          shippingBatches {
            id
          }
        }
      }
    `
    return graphql
      .query({
        query,
        variables: { id: parseInt(id, 10) }
      })
      .then(({ data }) => {
        return data.shippingTrack
      })
  },

  getClientShippingExceptions (clientId) {
    const query = gql`
      query getClientShippingExceptions($clientId: String!) {
        shippingExceptions(archived: false, clientId: $clientId) {
          nodes {
            id
            reason
            matchedWeeks {
              week
              year
            }
          }
        }
      }
    `
    return graphql
      .query({
        query,
        variables: { clientId }
      })
      .then(({ data }) => {
        return data.shippingExceptions.nodes
      })
  },

  createShippingException ({ reason, until, clientId }) {
    const mutation = gql`
      mutation CreateShippingException(
        $reason: String!
        $until: ISO8601Date!
        $clientId: String!
      ) {
        updateUpcomingShippingExceptions(
          shippingException: {
            reason: $reason
            until: $until
            clientId: $clientId
          }
        ) {
          id
          reason
          matchedWeeks {
            week
            year
          }
        }
      }
    `
    return graphql
      .mutate({
        mutation,
        variables: {
          reason,
          until: formatTime(until, 'YYYY-MM-DD'),
          clientId
        }
      })
      .then(({ data }) => {
        return data.updateUpcomingShippingExceptions
      })
  },

  createShippingBatchForTrack ({ date, trackId }) {
    const mutation = gql`
      mutation createShippingBatchForTrack(
        $date: ISO8601Date!
        $trackId: Int!
      ) {
        createShippingBatch(date: $date, trackId: $trackId) {
          id
          status
          week
          createdAt
        }
      }
    `
    return graphql
      .mutate({
        mutation,
        variables: {
          date: formatTime(date, 'YYYY-MM-DD'),
          trackId
        }
      })
      .then(({ data }) => {
        return data.createShippingBatch
      })
  },

  archiveShippingException (id) {
    const mutation = gql`
      mutation archiveShippingException($id: String!) {
        archiveShippingException(id: $id) {
          id
        }
      }
    `
    return graphql
      .mutate({
        mutation,
        variables: {
          id
        }
      })
      .then(({ data }) => {
        return data.archiveShippingException
      })
  },

  paginateDeviceMasterRecords (params) {
    return device
      .get(`/device-master-records${toQueryString(params)}`)
      .then(function (response) {
        return response.data
      })
  },

  createDeviceMasterRecord (values) {
    return device
      .post('/device-master-records', { dmr: values })
      .then(function (response) {
        return response.data.dmr
      })
  },

  updateDeviceMasterRecord (id, values) {
    return device
      .patch(`/device-master-records/${id}`, { dmr: values })
      .then(function (response) {
        return response.data.dmr
      })
  },

  deleteDeviceMasterRecord (id) {
    return device.delete(`/device-master-records/${id}`)
  }
}
