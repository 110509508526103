import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import { REPLACEMENT_REASONS } from '@/lib/api/schema'
import { PRODUCT_TYPES } from '../lib/constants'
import {
  selectBaseUnits,
  selectMouthPieces,
  selectShipments,
  selectProductPresetKits
} from '@/lib/redux/selectors'
import { addKitToShipment, getClientReplacements } from '@/lib/redux/actions'
import { withRestricted } from '@/components/restricted'
import api from '../lib/api'

function filterDeviceKit (products, type) {
  return products.filter(function (product) {
    return product.type === type
  })[0]
}

function filterProductPresetKit (productPresets, kit) {
  return productPresets.filter(function (productPreset) {
    return productPreset.slug === kit
  })[0]
}

const ReplacementReasonSelect = function (props) {
  return (
    <select
      name={props.name}
      className='form-control'
      onChange={props.handleChange}
      value={props.values[props.name] || ''}
    >
      <option value=''>- Replacement Reason -</option>
      {REPLACEMENT_REASONS.map(reason => (
        <option value={reason} key={reason}>
          {reason}
        </option>
      ))}
    </select>
  )
}

class AddKitForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dmrs: []
    }
    this.submit = this.submit.bind(this)
    this.onKitChange = this.onKitChange.bind(this)
  }

  componentDidMount () {
    api.paginateDeviceMasterRecords({ page: 1, per_page: 100 }).then(data => {
      this.setState({ dmrs: data.dmrs.map(d => d.dmr) })
    })
  }

  submit (values) {
    const {
      addKitToShipment,
      shipmentID,
      reset,
      clientID,
      getClientReplacements
    } = this.props

    return addKitToShipment(shipmentID, values)
      .then(() => {
        reset()
        getClientReplacements(clientID)
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  get selectedKit () {
    const {
      values: { kit },
      productPresets
    } = this.props

    return filterProductPresetKit(productPresets, kit)
  }

  get noSerialNumberRequired () {
    return this.selectedKit && this.selectedKit.noSerialNumberRequired
  }

  get baseUnitRequired () {
    return this.selectedKit && this.selectedKit.baseUnitRequired
  }

  get mouthPieceRequired () {
    // 4.0 Mouth piece doesn't have SN
    return false
  }

  get oldBaseUnitRequired () {
    return this.selectedKit && this.selectedKit.oldBaseUnitRequired
  }

  get oldMouthPieceRequired () {
    // 4.0 Mouth piece doesn't have SN
    return false
  }

  get replacementReasonRequired () {
    return this.selectedKit && this.selectedKit.replacementReasonRequired
  }

  existingSerialNumbersSelect (name, type) {
    const { baseUnits, mouthPieces, handleChange, values } = this.props
    const devices = type === PRODUCT_TYPES.BASE_UNIT ? baseUnits : mouthPieces

    return (
      <select
        className='form-control'
        name={name}
        onChange={handleChange}
        value={values[name] || ''}
        required
      >
        <option value=''>- Select -</option>
        {devices.map(device => (
          <option
            value={device.serialNumber}
            key={`${name}-product-${device.id}`}
          >
            {device.serialNumber}
          </option>
        ))}
      </select>
    )
  }

  onKitChange (ev) {
    const { reset, handleChange } = this.props

    reset()
    return handleChange(ev)
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      shipment,
      replacements,
      clientID,
      productPresets
    } = this.props
    const { dmrs } = this.state

    if (!shipment) return null

    const newBaseUnit = filterDeviceKit(
      shipment.products,
      PRODUCT_TYPES.BASE_UNIT
    )
    const newMouthPiece = filterDeviceKit(
      shipment.products,
      PRODUCT_TYPES.MOUTH_PIECE
    )
    const oldBaseUnit = filterDeviceKit(
      shipment.replacedProducts,
      PRODUCT_TYPES.BASE_UNIT
    )
    const oldMouthPiece = filterDeviceKit(
      shipment.replacedProducts,
      PRODUCT_TYPES.MOUTH_PIECE
    )
    const baseUnitReplacement = oldBaseUnit && replacements[oldBaseUnit.id]
    const mouthPieceReplacement =
      oldMouthPiece && replacements[oldMouthPiece.id]
    let kit = filterDeviceKit(shipment.products, PRODUCT_TYPES.KIT)

    if (shipment.kit) {
      const productPreset = filterProductPresetKit(productPresets, shipment.kit)
      kit = {
        description: (productPreset && productPreset.name) || shipment.kit
      }
    }

    return (
      <div>
        {kit ? (
          <div>
            <p className='font-weight-bold'>{kit.description}</p>

            {(newBaseUnit || newMouthPiece || oldBaseUnit || oldMouthPiece) && (
              <table className='table table-sm'>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>New Serial Number</th>
                    <th>New DMR</th>
                    {(oldBaseUnit || oldMouthPiece) && (
                      <>
                        <th>Old Serial Number</th>
                        <th>Replacement reason</th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {(newBaseUnit || oldBaseUnit) && (
                    <tr>
                      <td className='align-middle'>Base Unit</td>
                      <td>
                        {newBaseUnit && newBaseUnit.serialNumber}
                        {(newBaseUnit && newBaseUnit.client === clientID) || (
                          <small className='font-italic ml-1'>
                            (Unassigned)
                          </small>
                        )}
                      </td>
                      <td>{newBaseUnit && newBaseUnit.dmr}</td>
                      {oldBaseUnit && (
                        <>
                          <td>{oldBaseUnit.serialNumber}</td>
                          <td>
                            {baseUnitReplacement &&
                              baseUnitReplacement.replacementReason}
                          </td>
                        </>
                      )}
                    </tr>
                  )}

                  {(newMouthPiece || oldMouthPiece) && (
                    <tr>
                      <td className='align-middle'>Mouth Piece</td>
                      <td>
                        {newMouthPiece.serialNumber}
                        {newMouthPiece.client === clientID || (
                          <small className='font-italic ml-1'>
                            (Unassigned)
                          </small>
                        )}
                      </td>
                      <td>{newMouthPiece.dmr}</td>
                      {oldMouthPiece && (
                        <>
                          <td>{oldMouthPiece.serialNumber}</td>
                          <td>
                            {mouthPieceReplacement &&
                              mouthPieceReplacement.replacementReason}
                          </td>
                        </>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit(this.submit)}>
            <div className='form-inline mb-4'>
              <select
                name='kit'
                className='form-control mr-4'
                onChange={this.onKitChange}
                value={values.kit || ''}
                required
              >
                <option value=''>- Select Kit -</option>
                {productPresets.map(productPreset => (
                  <option
                    value={productPreset.slug}
                    key={`kit-${productPreset.slug}`}
                  >
                    {productPreset.name}
                  </option>
                ))}
              </select>
            </div>

            {this.noSerialNumberRequired || (
              <table className='table table-sm'>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>New Serial Number</th>
                    <th>New DMR</th>
                    {(this.oldBaseUnitRequired ||
                      this.oldMouthPieceRequired) && (
                      <>
                        <th>Old Serial Number</th>
                        <th>Replacement Reason</th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {(this.oldBaseUnitRequired || this.baseUnitRequired) && (
                    <tr>
                      <td className='align-middle'>Base Unit</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          name='newBaseUnitSerialNumber'
                          placeholder='e.g. 123456'
                          onChange={handleChange}
                          value={values.newBaseUnitSerialNumber || ''}
                          required
                        />
                      </td>
                      <td>
                        <select
                          className='form-control custom-select custom-select-sm'
                          name='newBaseUnitDmr'
                          onChange={handleChange}
                          value={values.newBaseUnitDmr || ''}
                        >
                          <option value=''>- Select -</option>
                          {dmrs.map(dmr => (
                            <option value={dmr} key={dmr}>
                              {dmr}
                            </option>
                          ))}
                        </select>
                      </td>
                      {this.oldBaseUnitRequired && (
                        <>
                          <td>
                            {this.existingSerialNumbersSelect(
                              'oldBaseUnitSerialNumber',
                              PRODUCT_TYPES.BASE_UNIT
                            )}
                          </td>
                          <td>
                            <ReplacementReasonSelect
                              name='baseUnitReplacementReason'
                              handleChange={handleChange}
                              values={values || ''}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  )}

                  {(this.oldMouthPieceRequired || this.mouthPieceRequired) && (
                    <tr>
                      <td className='align-middle'>Mouth Piece</td>
                      <td>
                        <input
                          type='text'
                          className='form-control'
                          name='newMouthPieceSerialNumber'
                          placeholder='e.g. 123456'
                          onChange={handleChange}
                          value={values.newMouthPieceSerialNumber || ''}
                          required
                        />
                      </td>
                      <td>
                        <select
                          className='form-control custom-select custom-select-sm'
                          name='newMouthPieceDmr'
                          onChange={handleChange}
                          value={values.newMouthPieceDmr || ''}
                        >
                          <option value=''>- Select -</option>
                          {dmrs.map(dmr => (
                            <option value={dmr} key={dmr}>
                              {dmr}
                            </option>
                          ))}
                        </select>
                      </td>
                      {this.oldMouthPieceRequired && (
                        <>
                          <td>
                            {this.existingSerialNumbersSelect(
                              'oldMouthPieceSerialNumber',
                              PRODUCT_TYPES.MOUTH_PIECE
                            )}
                          </td>
                          <td>
                            <ReplacementReasonSelect
                              name='mouthPieceReplacementReason'
                              handleChange={handleChange}
                              values={values}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            <button
              className='btn btn-primary'
              type='submit'
              disabled={submitting}
            >
              Add Kit
            </button>
          </form>
        )}
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { replacements } = state
  const baseUnits = selectBaseUnits(state)
  const mouthPieces = selectMouthPieces(state)
  const shipments = selectShipments(state)
  const productPresets = selectProductPresetKits(state)
  const shipment = shipments.filter(function (s) {
    return s.id === props.shipmentID
  })[0]

  return {
    form,
    baseUnits,
    mouthPieces,
    shipment,
    replacements,
    productPresets
  }
}

const mapDispatchToProps = {
  addKitToShipment,
  getClientReplacements
}

const form = FormHandler(AddKitForm)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default withRestricted(connected, 'device::shipments::update')
