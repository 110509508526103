import React from 'react'
import { connect } from 'react-redux'
import { getPaymentPlans } from '@/lib/redux/actions'
import { selectPaymentPlans } from '@/lib/redux/selectors'
import Icon from './icon'
import { formatCents, formatBillingInterval } from '@/lib/functions'

const PaymentPlansTable = function (props) {
  const { plans } = props

  return (
    <div className='table-responsive'>
      <table className='table table-striped table-borderless table-sm w-100'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Trial</th>
            <th>Signup Fee</th>
            <th>Monthly Price</th>
            <th>Enterprise plan</th>
            <th>Internal Test</th>
            <th>Billing Interval</th>
          </tr>
        </thead>
        <tbody>
          {plans.map(plan => (
            <tr key={plan.id}>
              <td>{plan.name}</td>
              <td>{plan.trialDays} days</td>
              <td>{formatCents(plan.signupFeeCents)}</td>
              <td>{formatCents(plan.monthlyPriceCents)}</td>
              <td>{plan.enterprise ? 'Yes' : 'No'}</td>
              <td>{plan.test ? 'Yes' : 'No'}</td>
              <td>{formatBillingInterval(plan)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

class PaymentPlans extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showLegacy: false
    }

    this.handleToggleExpand = this.handleToggleExpand.bind(this)
  }

  componentDidMount () {
    const { getPaymentPlans } = this.props

    getPaymentPlans()
  }

  get currentPlans () {
    const { paymentPlans } = this.props
    return paymentPlans.filter(p => !p.legacy)
  }

  get legacyPlans () {
    const { paymentPlans } = this.props
    return paymentPlans.filter(p => p.legacy)
  }

  handleToggleExpand () {
    const { showLegacy } = this.state

    this.setState({ showLegacy: !showLegacy })
  }

  render () {
    const { showLegacy } = this.state

    return (
      <div className='card mb-5'>
        <div className='card-body'>
          <h5 className='mb-4'>Payment Plans ({this.currentPlans.length})</h5>

          <PaymentPlansTable plans={this.currentPlans} />

          <h5 className='mb-4' onClick={this.handleToggleExpand}>
            Legacy ({this.legacyPlans.length}){' '}
            <Icon icon={`chevron-${showLegacy ? 'down' : 'right'}`} />
          </h5>

          {showLegacy && <PaymentPlansTable plans={this.legacyPlans} />}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const paymentPlans = selectPaymentPlans(state)

  return {
    paymentPlans
  }
}

const mapDispatchToProps = {
  getPaymentPlans
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlans)
