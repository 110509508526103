import React from 'react'
import { connect } from 'react-redux'
import { formatTime } from '../lib/functions'

function ReferralCodes (props) {
  const { referralCodes, paymentPlans } = props

  return (
    <div className='card mb-5'>
      <div className='card-body'>
        <h4 className='mb-5'>Referral Codes</h4>

        <div className='table-responsive'>
          <table className='table table-striped table-borderless table-sm w-100'>
            <thead>
              <tr>
                <th>Code</th>
                <th>Coverage Type</th>
                <th>Payment Plan</th>
                <th>Expires on</th>
                <th>VIP</th>
                <th>Waitlist Registration</th>
                <th>Internal Description</th>
                <th>External Description</th>
                <th>Required Domains</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {referralCodes.map(referralCode => (
                <tr key={referralCode.id}>
                  <td className='text-monospace'>{referralCode.code}</td>
                  <td>{referralCode.coverageType}</td>
                  <td>
                    {paymentPlans[referralCode.paymentPlanID] &&
                      paymentPlans[referralCode.paymentPlanID].name}
                  </td>
                  <td>{formatTime(referralCode.expiryDate, 'll')}</td>
                  <td>{referralCode.vip ? 'Yes' : 'No'}</td>
                  <td>{referralCode.waitlistRegistration ? 'Yes' : 'No'}</td>
                  <td>{referralCode.internalDescription}</td>
                  <td>{referralCode.externalDescription}</td>
                  <td>
                    {referralCode.requiredEmailVerificationDomains
                      ? referralCode.requiredEmailVerificationDomains.join(', ')
                      : 'None'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state) {
  const paymentPlans = state.paymentPlans

  return {
    paymentPlans
  }
}

export default connect(mapStateToProps)(ReferralCodes)
