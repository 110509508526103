import React from 'react'
import { connect } from 'react-redux'
import FormHandler from '../form-handler'
import ResponseError from '../response-error'
import { withModalManager } from '../modal-manager'
import api from '../../lib/api'

function EditDmrModal (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    reset,
    error,
    submitting,
    submitted,
    dmr,
    onUpdated
  } = props
  const onSubmit = values => {
    return api.updateDeviceMasterRecord(dmr.id, values).then(() => {
      reset()
      modal.hide()
      onUpdated()
    })
  }

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Update DMR</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <div className='form-group mb-2'>
              <label>DMR:</label>
              <input
                type='text'
                className='form-control'
                name='dmr'
                value={values.dmr || ''}
                onChange={handleChange}
                placeholder='SYS-ABC-123'
                required
              />
            </div>
            <div className='form-group mb-2'>
              <label className='mr-2'>Notes:</label>
              <textarea
                rows='4'
                className='form-control'
                name='notes'
                value={values.notes || ''}
                onChange={handleChange}
              />
            </div>
          </modal.Body>

          <modal.Footer>
            <button
              type='submit'
              className='btn btn-primary mb-2'
              disabled={submitting}
            >
              Save changes
            </button>

            {submitted && <p className='text-success'>Success!</p>}
            {error && <ResponseError error={error} />}

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const Form = FormHandler(EditDmrModal, {
  enableReinitialize: true
})
function mapStateToProps (_, { dmr }) {
  return {
    initialValues: {
      dmr: dmr.dmr,
      notes: dmr.notes
    }
  }
}
const Connected = connect(mapStateToProps)(Form)

export default withModalManager(Connected)
