import React from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import FormHandler from '@/components/form-handler'
import { withModalManager } from '../modal-manager'
import {
  updateClient
} from '@/lib/redux/actions'
import api from '@/lib/api'
import '~/react-datepicker/dist/react-datepicker.css'
import { selectShippingTracks } from '@/lib/redux/selectors'

class ChangeTrackModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedDate: new Date()
    }
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handleChangeDate (date) {
    this.setState({ selectedDate: date })
  }

  submitByDate (date) {
    return api.getTrackFromDate(date).then((track) => {
      return this.submitByTrack(track.id)
    })
  }

  submitByTrack (trackID) {
    const { updateClient, client } = this.props
    return updateClient(client.id, { shippingTrackID: trackID })
  }

  onSubmit (values) {
    const { selectedDate } = this.state
    const { reset, modal } = this.props
    let result

    if (values.changeMethod === 'select-date') {
      result = this.submitByDate(selectedDate)
    } else {
      result = this.submitByTrack(values.trackID)
    }

    result.then(() => {
      reset()
      modal.toggle()
    })
  }

  render () {
    const {
      handleChange,
      handleSubmit,
      values,
      tracks,
      submitting,
      modal,
      children
    } = this.props
    const { selectedDate } = this.state

    return (
      <>
        {children({ toggle: modal.toggle })}

        <modal.Modal>
          <modal.Header>Change Shipping Track</modal.Header>

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <modal.Body>
              <div className='custom-control custom-radio mb-3'>
                <input
                  type='radio'
                  className='custom-control-input'
                  id='select-track'
                  name='changeMethod'
                  value='select-track'
                  checked={values.changeMethod === 'select-track'}
                  onChange={handleChange}
                />
                <label className='custom-control-label' htmlFor='select-track'>
                  Shpping Track:

                  <select
                    className='custom-select my-1 mr-2'
                    name='trackID'
                    onChange={handleChange}
                    value={values.trackID || ''}
                  >
                    <option value=''>No track set</option>
                    {tracks.map((track) => (
                      <option key={track.id} value={track.id}>Track {track.name}</option>
                    ))}
                  </select>
                </label>
              </div>

              <div className='custom-control custom-radio mb-4'>
                <input
                  type='radio'
                  className='custom-control-input'
                  id='select-date'
                  name='changeMethod'
                  value='select-date'
                  checked={values.changeMethod === 'select-date'}
                  onChange={handleChange}
                />
                <label className='custom-control-label' htmlFor='select-date'>
                  Change by Date:

                  <DatePicker
                    className='form-control'
                    placeholderText='MM/DD/YYYY'
                    selected={selectedDate}
                    onChange={this.handleChangeDate}
                  />
                </label>
              </div>
            </modal.Body>

            <modal.Footer>
              <button
                className='btn btn-primary'
                disabled={submitting}
              >
                Save
              </button>

              <modal.Cancel />
            </modal.Footer>
          </form>
        </modal.Modal>
      </>
    )
  }
}

const form = FormHandler(ChangeTrackModal)

function mapStateToProps (state, props) {
  const tracks = selectShippingTracks(state)

  return {
    tracks,
    initialValues: {
      trackID: props.client.shippingTrackID,
      changeMethod: 'select-track'
    }
  }
}

const mapDispatchToProps = {
  updateClient
}
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default withModalManager(connected)
