import React, { useState, useEffect } from 'react'
import FormHandler from '../form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '../restricted'
import { PRODUCT_TYPES } from '../../lib/constants'
import api from '../../lib/api'

function SetupDemoKit (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    reset,
    error,
    submitting
  } = props
  const [serialNumberSubmitted, setSerialNumberSubmitted] = useState(null)
  const [dmrs, setDmrs] = useState([])
  const [invalidError, setInvalidError] = useState(null)
  const setupDemoKit = values => {
    const setupMark = api.setupDemoKit({ ...values, profileName: 'Mark' })
    const setupKeith = api.setupDemoKit({ ...values, profileName: 'Keith' })
    const setupDolores = api.setupDemoKit({ ...values, profileName: 'Dolores' })

    return Promise.all([setupMark, setupKeith, setupDolores]).then(() => {
      setSerialNumberSubmitted(values.serialNumber)
      reset()
    })
  }
  const onSubmit = values => {
    const { serialNumber } = values
    setSerialNumberSubmitted(null)
    setInvalidError(null)
    return api.getDeviceBySerialNumber(serialNumber).then(device => {
      if (device && device.dmr !== values.dmr) {
        return setInvalidError(
          'The DMR does not correspond to the existing records.'
        )
      }
      if (device && device.client) {
        return setInvalidError('This SN is already assigned to another Client.')
      }
      if (!device) {
        return api
          .createProduct({ type: PRODUCT_TYPES.BASE_UNIT, ...values })
          .then(() => setupDemoKit(values))
      }
      return setupDemoKit(values)
    })
  }

  useEffect(() => {
    api.paginateDeviceMasterRecords({ page: 1, per_page: 100 }).then(data => {
      setDmrs(data.dmrs.map(d => d.dmr))
    })
  }, [])

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Setup Demo Kit</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <p>
              If the serial number entered is not in the system yet, it'll be
              created with the entered DMR.
            </p>
            <p>
              If the serial number exists already, it'll find the serial number
              and validate that the DMR matches.
            </p>

            <div className='form-group'>
              <label>Serial Number</label>
              <input
                type='text'
                name='serialNumber'
                value={values.serialNumber || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. 501234'
                required
              />
            </div>

            <div className='form-group'>
              <label>DMR</label>
              <select
                name='dmr'
                value={values.dmr || ''}
                className='custom-select custom-select-sm'
                onChange={handleChange}
                required
              >
                <option value=''>- Select -</option>
                {dmrs.map(dmr => (
                  <option value={dmr} key={dmr}>
                    {dmr}
                  </option>
                ))}
              </select>
            </div>

            {error && (
              <p className='text-danger'>{error.response.data.error}</p>
            )}

            {invalidError && <p className='text-danger'>{invalidError}</p>}

            {serialNumberSubmitted && (
              <>
                <p className='text-success'>
                  The demo kit is being setup, please wait a couple of minutes
                  before logging in with any of the following emails:
                </p>
                <ul>
                  <li>dolores.{serialNumberSubmitted}@invoy.com</li>
                  <li>keith.{serialNumberSubmitted}@invoy.com</li>
                  <li>mark.{serialNumberSubmitted}@invoy.com</li>
                </ul>
              </>
            )}
          </modal.Body>

          <modal.Footer>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={submitting}
            >
              {submitting ? 'Setting up...' : 'Setup'}
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const form = FormHandler(SetupDemoKit)
const modaled = withModalManager(form)

export default withRestricted(modaled, 'demo_kit:setup')
