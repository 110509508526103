import React from 'react'
import Icon from './icon'

const paginationHandler = (WrappedComponent, config = {}) => {
  class EnhancedComponent extends React.Component {
    constructor (props) {
      super(props)

      const pagination = {
        loading: false,
        direction: config.direction || 'down',
        pageSize: config.perPage || 50,
        currentPage: 1,
        nextPage: null
      }

      this.state = {
        initialPagination: pagination,
        pagination
      }

      this.resetPagination = this.resetPagination.bind(this)
      this.updatePagination = this.updatePagination.bind(this)
      this.renderNextPageButton = this.renderNextPageButton.bind(this)
    }

    resetPagination (callback) {
      const { initialPagination } = this.state

      this.setState({
        pagination: initialPagination
      }, () => {
        callback()
      })
    }

    updatePagination (newPagination) {
      const { pagination } = this.state

      this.setState({
        pagination: {
          ...pagination,
          ...newPagination,
          loading: false
        }
      })
    }

    loadNextPage (callback) {
      const { pagination } = this.state

      this.setState({
        pagination: {
          ...pagination,
          loading: true
        }
      }, () => {
        callback && callback()
      })
    }

    renderNextPageButton (callback) {
      const { pagination } = this.state

      if (!pagination.nextPage) return ''
      if (pagination.nextPage === 1) return ''

      return (
        <div className='d-block text-center'>
          <button
            type='button'
            onClick={() => { this.loadNextPage(callback) }}
            className='btn btn-light'
          >
            <Icon icon={`chevron-${pagination.direction}`} />
          </button>
        </div>
      )
    }

    render () {
      const { pagination } = this.state

      return (
        <WrappedComponent
          {...this.props}
          pagination={pagination}
          resetPagination={this.resetPagination}
          updatePagination={this.updatePagination}
          renderNextPageButton={this.renderNextPageButton}
        />
      )
    }
  }

  return EnhancedComponent
}

export default paginationHandler
