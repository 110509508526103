import React from 'react'

function ResponseError ({ error }) {
  if (!error) return null

  const { data } = error.response

  return (
    <div className='text-danger'>
      {data.error && <p>{data.error}</p>}
      {data.errors && (
        <ul>
          {Object.keys(data.errors).map(attr =>
            data.errors[attr].map((err, i) => (
              <li key={`${attr}-${i}`}>
                {attr} {err}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  )
}

export default ResponseError
