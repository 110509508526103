import React, { useState, useEffect, useCallback } from 'react'
import { debounce, map, uniq } from 'lodash'
import NavBar from '@/components/nav-bar'
import Restricted from '@/components/restricted'
import ClientsFilters from '@/components/clients-filters'
import ClientsList from '@/components/clients-list'
import api from '@/lib/api'

const INITIAL_FILTERS = {
  referralCodes: [],
  subscriptionStatuses: [],
  parentAccounts: [],
  registrationStart: null,
  registrationEnd: null
}

const INITIAL_SORTING = {
  column: 'registered_at',
  direction: 'desc'
}

const INITIAL_PAGINATION = {
  page: 1,
  pageSize: 100
}

function parseParams (filters, sorting, pagination) {
  const newParams = {
    filters: {},
    pagination
  }

  if (filters.referralCodes?.length) {
    newParams.filters.referralCodes = filters.referralCodes
  }

  if (filters.parentAccounts?.length) {
    newParams.filters.parentAccounts = filters.parentAccounts
  }

  if (filters.subscriptionStatuses?.length) {
    const normalizedSubscriptionStatuses = uniq(
      map(filters.subscriptionStatuses, status => {
        if (status === 'registered_waitlisted') {
          newParams.filters.waitlist = true
          return 'registered'
        }

        return status
      })
    )

    newParams.filters.subscriptionStatuses = normalizedSubscriptionStatuses
  }

  if (filters.registrationStart && filters.registrationEnd) {
    newParams.filters.registrationStart = filters.registrationStart
    newParams.filters.registrationEnd = filters.registrationEnd
  }

  if (sorting.column && sorting.column) {
    newParams.sorting = sorting
  }

  return newParams
}

const Clients = function () {
  const [clientsResults, setClientsResults] = useState({})
  const [parentAccounts, setParentAccounts] = useState([])

  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [sorting, setSorting] = useState(INITIAL_SORTING)
  const [pagination, setPagination] = useState(INITIAL_PAGINATION)
  const [fetchingResults, setFetchingResults] = useState(false)

  const debouncedRetrieveMembers = useCallback(
    debounce((filters, sorting, resultsLimit) => {
      setFetchingResults(true)

      api
        .filterClients(parseParams(filters, sorting, resultsLimit))
        .then(data => {
          setClientsResults(data)
          setFetchingResults(false)
        })
    }, 250),
    []
  )

  useEffect(() => {
    api.getEnterpriseAccounts().then(setParentAccounts)
  }, [])

  const handleFiltersChange = filters => {
    setFilters(filters)
  }
  const handleSortingChange = sorting => {
    setSorting(sorting)
  }
  const handlePageChange = pagination => {
    setPagination(pagination)
  }

  useEffect(() => {
    debouncedRetrieveMembers(filters, sorting, pagination)
  }, [filters, sorting, pagination])

  return (
    <div>
      <NavBar />

      <div className='container'>
        <div className='mb-3'>
          <ClientsFilters
            initialFilters={filters}
            parentAccounts={parentAccounts}
            onFiltersChange={handleFiltersChange}
          />
        </div>

        <div className='d-flex mb-3'>
          <div className='mr-auto' />
          <Restricted policy='device::profile::body_composition::reports'>
            <a href='/inbody'>Body Composition CSVs</a>
          </Restricted>
        </div>

        <ClientsList
          clients={clientsResults.clients}
          pagination={clientsResults.pagination}
          sorting={sorting}
          loading={fetchingResults}
          onSortingChange={handleSortingChange}
          onPaginationChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default Clients
