import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.bodyCompositionReports
      }

    default:
      return state
  }
}

export function getBodyCompositionReports () {
  return function (dispatch, getState, { api, schema }) {
    return api.getBodyCompositionReports()
      .then(function (response) {
        const data = normalize(response, [schema.bodyCompositionReport])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createBodyCompositionReport (fileName, file) {
  return function (dispatch, getState, { api, schema }) {
    return api.createBodyCompositionReport(fileName, file)
      .then(function (response) {
        const data = normalize(response.report, schema.bodyCompositionReport)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
