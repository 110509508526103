import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import { escalateTaskToProgram } from '@/lib/redux/actions'
import { withRestricted } from '@/components/restricted'

class EscalateTaskModal extends React.Component {
  onSubmit (values) {
    const { escalateTaskToProgram, id, modalId } = this.props

    window.$(`#${modalId}`).modal('hide')

    return escalateTaskToProgram(id, values)
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      modalId
    } = this.props

    return (
      <div className='modal' id={modalId}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Escalate Task
              </h5>
              <button className='close' type='button' data-dismiss='modal'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <div className='form-group'>
                  <label>Notes</label>
                  <textarea
                    name='notes'
                    rows='5'
                    className='form-control'
                    placeholder='Enter notes for the program analyst'
                    onChange={handleChange}
                    value={values.notes}
                    required
                  />
                </div>
                <button className='btn btn-primary' type='submit' disabled={submitting}>
                  {submitting ? 'Saving...' : 'Save'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  escalateTaskToProgram
}

const form = FormHandler(EscalateTaskModal)
const connected = connect(null, mapDispatchToProps)(form)

export default withRestricted(connected, 'device::profile::tasks::update')
