import { normalize } from 'normalizr'
import { ADD_ENTITIES, REMOVE_ENTITY, addEntities, removeEntity } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.tasks
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state; // eslint-disable-line

      return others

    default:
      return state
  }
}

export function getOpenTasks () {
  return function (dispatch, getState, { api, schema }) {
    return api.getOpenTasks()
      .then(function (response) {
        const data = normalize(response, [schema.task])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getTodayTasks () {
  return function (dispatch, getState, { api, schema }) {
    return api.getTodayTasks()
      .then(function (response) {
        const data = normalize(response, [schema.task])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getClientTasks (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientTasks(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.task])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getFilteredTasks (filters) {
  return function (dispatch, getState, { api, schema }) {
    return api.getFilteredTasks(filters)
      .then(function (response) {
        const data = normalize(response.tasks, [schema.task])
        const dispatchData = {
          tasks: {
            ...data.entities.tasks
          }
        }

        dispatch(addEntities(dispatchData))

        return response
      })
  }
}

export function markTaskAsCompleted (taskID) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTaskAsCompleted(taskID)
      .then(function (response) {
        const data = normalize(response, schema.task)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function markTaskAsInvalid (taskID) {
  return function (dispatch, getState, { api, schema }) {
    return api.markTaskAsInvalid(taskID)
      .then(function (response) {
        const data = normalize(response, schema.task)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createTask (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createTask(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.task)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function deleteTask (id) {
  return function (dispatch, getState, { api }) {
    return api.deleteTask(id)
      .then(function (response) {
        dispatch(removeEntity(id))

        return response
      })
  }
}

export function updateTask (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateTask(id, values)
      .then(function (response) {
        const data = normalize(response, schema.task)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function escalateTaskToProgram (taskID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.escalateTaskToProgram(taskID, values)
      .then(function (response) {
        const data = normalize(response, schema.task)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
