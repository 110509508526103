import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.shippingTracks
      }

    default:
      return state
  }
}

export function getShippingTrackBatchesForDate (date) {
  return function (dispatch, getState, { api, schema }) {
    return api.getShippingTrackBatchesForDate(date)
      .then(function (response) {
        const data = normalize(response, [schema.shippingTrack])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getShippingTrack (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getShippingTrack(id)
      .then(function (response) {
        const data = normalize(response, schema.shippingTrack)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createShippingBatchForTrack ({ date, trackId }) {
  return function (dispatch, getState, { api }) {
    return api.createShippingBatchForTrack({ date, trackId })
      .then(function (batch) {
        const track = getState().shippingTracks[trackId]

        if (track) {
          const entities = {
            shippingTracks: {
              [trackId]: {
                ...track,
                shippingBatches: [
                  ...track.shippingBatches,
                  batch
                ]
              }
            }
          }

          dispatch(addEntities(entities))
        }

        return batch
      })
  }
}

export function getShippingTracksSummary () {
  return function (dispatch, getState, { api, schema }) {
    return api.getShippingTracksSummary()
      .then(function (response) {
        const data = normalize(response, [schema.shippingTrack])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
