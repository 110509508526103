import React from 'react'
import { connect } from 'react-redux'
import NavBar from '@/components/nav-bar'
import PageLoading from '@/components/page-loading'
import ClientDisplayName from '@/components/client-display-name'
import { formatTime, calculateErrorRate } from '@/lib/functions'
import {
  getProduct,
  getProductReadings,
  updateProduct,
  deleteProductReadings,
  getClient
} from '@/lib/redux/actions'
import { selectProductReadingVaues } from '@/lib/redux/selectors'
import { allow } from '@/components/restricted'

function extractMetadata (reading) {
  return {
    stateLog: reading.stateLog,
    stats: reading.stats,
    duration: reading.duration
  }
}

class DeviceDetails extends React.Component {
  constructor (props) {
    super(props)

    this.deleteDeviceHistory = this.deleteDeviceHistory.bind(this)
  }

  componentDidMount () {
    const { getProduct, getProductReadings, getClient } = this.props

    getProduct(this.deviceID)
      .then(function (product) {
        getClient(product.client)
      })
    getProductReadings(this.deviceID)
  }

  deleteDeviceHistory () {
    if (!confirm('Are you sure you want to delete all readings?')) return

    const { deleteProductReadings } = this.props

    deleteProductReadings(this.deviceID)
      .then(function () {
        location.reload()
      })
  }

  get deviceID () {
    const { match: { params } } = this.props

    return params.id
  }

  get allowDeviceUpdate () {
    return allow('device::products::update')
  }

  updateDeviceStatus (status) {
    const { updateProduct } = this.props

    return updateProduct(this.deviceID, { status })
  }

  render () {
    const {
      id,
      serialNumber,
      dmr,
      assignedAt,
      client,
      status,
      firstReadingDate,
      lastReadingDate,
      successReadingsCount,
      errorReadingsCount,
      readings
    } = this.props

    if (!id) return <PageLoading />

    const errorRate = calculateErrorRate(successReadingsCount, errorReadingsCount)

    return (
      <div>
        <NavBar />

        <div className='container'>
          <div className='card'>
            <div className='card-body'>
              <h4>Serial Number: {serialNumber || 'N/A'}</h4>
              <p className='mb-4 text-soft'>
                {dmr}
              </p>

              <div className='row mb-5'>
                <div className='col-6'>

                  <p>
                    <b>Assigned At</b><br />
                    {formatTime(assignedAt)}
                  </p>
                  <p>
                    <b>Assigned To</b><br />
                    {client ? (
                      <ClientDisplayName client={client} link />
                    ) : 'Not Assigned'}
                  </p>
                  <p>
                    <b>Status</b><br />
                    <span className='form-inline'>
                      <select
                        className='custom-select custom-select-sm'
                        defaultValue={status}
                        onChange={ev => this.updateDeviceStatus(ev.target.value)}
                        disabled={!this.allowDeviceUpdate}
                      >
                        <option value='functional'>Functional</option>
                        <option value='defective'>Defective</option>
                      </select>
                    </span>
                  </p>
                  <p>
                    <b>Error rate</b><br />
                    {errorRate}%
                  </p>
                </div>

                <div className='col-6'>
                  <p>
                    <b>First reading date</b><br />
                    {firstReadingDate}
                  </p>
                  <p>
                    <b>Last reading date</b><br />
                    {lastReadingDate}
                  </p>
                  <p>
                    <b>Total success readings</b><br />
                    {successReadingsCount}
                  </p>
                  <p>
                    <b>Total error readings</b><br />
                    {errorReadingsCount}
                  </p>
                </div>
              </div>

              <div className='d-flex'>
                <h5 className='mr-auto'>Readings</h5>

                {location.hostname === 'device.invoy.dev' && (
                  <button
                    className='btn btn-link text-danger'
                    type='button'
                    onClick={this.deleteDeviceHistory}
                  >
                    Delete device history
                  </button>
                )}
              </div>

              <table className='table table-borderless table-striped mb-5'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Steps log</th>
                    <th>Value</th>
                    <th>Firmware</th>
                    <th>Metadata</th>
                  </tr>
                </thead>

                <tbody>
                  {readings.map((reading) => (
                    <tr
                      key={`reading-${reading.id}`}
                    >
                      <td>{reading.date}</td>
                      <td>{formatTime(reading.timestamp * 1000, 'HH:mm z', reading.timeZone)}</td>
                      <td>{reading.status}</td>
                      <td>{reading.stepsLog.join(', ')}</td>
                      <td>{reading.value}</td>
                      <td>{reading.firmwareVersion}</td>
                      <td>
                        <pre className='overflow-auto' style={{ maxHeight: '100px' }}>
                          {JSON.stringify(extractMetadata(reading), null, 2)}
                        </pre>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { match: { params: { id } } } = props
  const product = state.products[id]
  const readings = selectProductReadingVaues(state)
  const client = product && product.client && state.clients[product.client]
  const currentUser = state.currentUser

  return {
    ...product,
    readings,
    client,
    currentUser
  }
}

const mapDispatchToProps = {
  getProduct,
  getProductReadings,
  updateProduct,
  deleteProductReadings,
  getClient
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails)
