import React from 'react'
import { connect } from 'react-redux'
import FormHandler from '@/components/form-handler'
import { SHIPMENT_STATUSES } from '@/lib/api/schema'
import {
  cancelShipment,
  scheduleShipment,
  addPartToShipment,
  removeProductFromShipment,
  updateShipment
} from '@/lib/redux/actions'
import {
  formatPhoneNumber,
  formatTime,
  shippingServiceName
} from '@/lib/functions'
import AddressForm from '../address-form'
import Address from '@/components/address'
import DatePicker from 'react-datepicker'
import AddKitForm from '../add-kit-form'
import ClientDisplayName from '@/components/client-display-name'
import Restricted, { allow } from '@/components/restricted'
import {
  FEDEX_SERVICES,
  UPS_SERVICES,
  PRODUCT_TYPES
} from '../../lib/constants'
import '~/react-datepicker/dist/react-datepicker.css'

function isAddressVerified (status) {
  return /success/i.test(status)
}

class ShipmentModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showAddressForm: false,
      shipBy: null,
      shippingService: props.shippingService || '',
      expedited: !!props.expedited
    }
    this.handleShowAddress = this.showAddressForm.bind(this)
    this.handleHideAddress = this.hideAddressForm.bind(this)
    this.handleShippingServiceChange = this.handleShippingServiceChange.bind(
      this
    )
    this.handleExpeditedChange = this.handleExpeditedChange.bind(this)
  }

  get isCreated () {
    const { status } = this.props
    const { CREATED } = SHIPMENT_STATUSES

    return status === CREATED
  }

  get isScheduled () {
    const { status } = this.props
    const { SCHEDULED } = SHIPMENT_STATUSES

    return status === SCHEDULED
  }

  get canEditItems () {
    const allowed = allow('device::shipments::update')
    return allowed && this.isCreated
  }

  get canEditAddress () {
    const allowed = allow('device::shipments::update')
    return allowed && (this.isCreated || this.isScheduled)
  }

  get canSchedule () {
    if (!this.canEditItems) return false

    const { address, kits, parts } = this.props
    return address && (kits.length > 0 || parts.length > 0)
  }

  addressVerification (status) {
    const { showAddressForm } = this.state

    if (!status) return null

    if (isAddressVerified(status)) {
      return <p className='text-success'>{status}</p>
    }

    return (
      <p className='text-danger'>
        {status}
        {showAddressForm || (
          <a
            href='#'
            className='ml-3'
            onClick={ev => {
              ev.preventDefault()
              this.showAddressForm()
            }}
          >
            Click here to fix it
          </a>
        )}
      </p>
    )
  }

  showAddressForm () {
    this.setState({ showAddressForm: true })
  }

  hideAddressForm () {
    this.setState({ showAddressForm: false })
  }

  cancelShipment () {
    if (!confirm('Are you sure you want to cancel this shipment?')) return

    const { cancelShipment, id } = this.props

    this.hideModal()
    cancelShipment(id).catch(error => {
      const msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error

      this.showModal()
      alert(msg || 'Could not cancel shipment. Please try again')
    })
  }

  hideModal () {
    const { modalId } = this.props

    window.$(`#${modalId}`).modal('hide')
  }

  showModal () {
    const { modalId } = this.props

    window.$(`#${modalId}`).modal('show')
  }

  addCustomPart (values) {
    const { addPartToShipment, id, reset } = this.props

    return addPartToShipment(id, { ...values, type: PRODUCT_TYPES.PART })
      .then(() => {
        reset()
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  removeCustomPart (partID) {
    const { removeProductFromShipment, id } = this.props

    removeProductFromShipment(id, partID)
  }

  changeShipBy (date) {
    this.setState({ shipBy: date })
  }

  scheduleShipment () {
    const confirmation =
      'We are sending this shipment to Shipstation, ' +
      'you will not be able to edit this shipment from portal after scheduled. ' +
      '\n\nAre you sure you want to continue?'
    if (!confirm(confirmation)) return

    const { scheduleShipment, id } = this.props
    const shipBy = formatTime(this.state.shipBy, 'YYYY-MM-DD')

    if (!shipBy) {
      return alert(
        'Please enter a date by when the shipment should be shipped.'
      )
    }

    this.hideModal()

    return scheduleShipment(id, { shipBy }).catch(error => {
      const msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error

      this.showModal()
      alert(msg || 'Failed to schedule shipment. Please try again')
    })
  }

  handleShippingServiceChange (ev) {
    const { updateShipment, id } = this.props
    const shippingService = ev.target.value

    this.setState({ shippingService })
    updateShipment(id, { shippingService })
  }

  handleExpeditedChange (ev) {
    const { updateShipment, id } = this.props
    const expedited = ev.target.checked

    this.setState({ expedited })
    updateShipment(id, { expedited })
  }

  get availableCarrierServices () {
    const { carrier } = this.props
    const { expedited } = this.state
    let services = []

    switch (carrier) {
      case 'ups':
        services = UPS_SERVICES
        break
      case 'fedex':
        services = FEDEX_SERVICES
        break
    }

    return expedited ? services.filter(s => s.expedited) : services
  }

  render () {
    const {
      id,
      modalId,
      status,
      client,
      contactPhone,
      trackingNumber,
      trackingURL,
      carrier,
      parts,
      address,
      shippedOn,
      deliveredOn,
      remoteAddressVerified,
      submitting,
      handleSubmit,
      handleChange,
      values
    } = this.props
    const { showAddressForm, shipBy, shippingService, expedited } = this.state

    return (
      <div className='modal' id={modalId}>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Shipment details</h5>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <div className='row mb-3'>
                <div className='col'>
                  <strong>Status:</strong> {status}
                  {status === 'scheduled' && (
                    <p className="text-info small">
                      You may need to click "Update All Stores" in the ShipStation toolbar to see this shipment.
                    </p>
                  )}
                </div>

                {this.canEditItems && (
                  <div className='col'>
                    <label>
                      <input
                        type='checkbox'
                        name='expedited'
                        checked={expedited}
                        onChange={this.handleExpeditedChange}
                        className='form-check-input'
                      />
                      Expedited
                    </label>
                  </div>
                )}
              </div>

              {(trackingNumber || carrier) && (
                <div className='row mb-3'>
                  {trackingNumber && (
                    <div className='col'>
                      <strong>Tracking No.:</strong>{' '}
                      <a
                        href={trackingURL}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {trackingNumber}
                      </a>
                    </div>
                  )}
                  {carrier && (
                    <>
                      <div className='col'>
                        <strong>Carrier:</strong> {carrier}
                      </div>

                      <div className='col'>
                        <strong>Carrier service:</strong>
                        {this.canEditItems
                          ? (
                            <select
                              className='custom-select
                            custom-select-sm'
                              value={shippingService}
                              onChange={this.handleShippingServiceChange}
                            >
                              <option value=''>- Select -</option>
                              {this.availableCarrierServices.map(service => (
                                <option value={service.slug} key={service.slug}>
                                  {service.name}
                                </option>
                              ))}
                            </select>
                            )
                          : (
                              shippingServiceName(shippingService)
                            )}
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className='row mb-3'>
                {shipBy && (
                  <div className='col'>
                    <strong>Ship by:</strong> {formatTime(shipBy, 'll')}
                  </div>
                )}
                {shippedOn && (
                  <div className='col'>
                    <strong>Shipped on:</strong> {shippedOn}
                  </div>
                )}
                {deliveredOn && (
                  <div className='col'>
                    <strong>Delivered on:</strong> {deliveredOn}
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  <strong>Client:</strong>{' '}
                  <ClientDisplayName client={client} link />
                </div>
                {contactPhone && (
                  <div className='col'>
                    <strong>Contact phone:</strong>{' '}
                    {formatPhoneNumber(contactPhone)}
                  </div>
                )}
              </div>

              <strong>Address:</strong>
              <br />
              {address
                ? (
                  <Address {...address} />
                  )
                : (
                    this.canEditAddress &&
                !showAddressForm && (
                  <button
                    type='button'
                    className='btn btn-link btn-sm'
                    onClick={this.handleShowAddress}
                  >
                    Add Address
                  </button>
                    )
                  )}

              {this.canEditAddress &&
                this.addressVerification(remoteAddressVerified)}

              {showAddressForm && (
                <fieldset>
                  <legend>Shipping Address</legend>
                  <AddressForm
                    address={address}
                    shipmentID={id}
                    onUpdate={this.handleHideAddress}
                  />
                </fieldset>
              )}

              <hr />

              <h4>Kit</h4>

              <AddKitForm shipmentID={id} clientID={client.id} />

              <hr />

              <h4>Custom Parts</h4>

              <form
                onSubmit={handleSubmit(values => this.addCustomPart(values))}
              >
                <table className='table table-sm'>
                  <thead>
                    <tr>
                      <th>Quantity</th>
                      <th>Description</th>
                      <th>Weight (Oz)</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {parts.map(part => (
                      <tr key={`part-${part.id}`}>
                        <td>{part.quantity}</td>
                        <td>{part.description}</td>
                        <td>{part.weightOz}</td>
                        <td>
                          {this.canEditItems && (
                            <a
                              className='text-danger'
                              href='#'
                              onClick={ev => {
                                ev.preventDefault()
                                this.removeCustomPart(part.id)
                              }}
                            >
                              &times;
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}

                    {this.canEditItems && (
                      <tr>
                        <td>
                          <input
                            type='number'
                            step='1'
                            min='1'
                            name='quantity'
                            placeholder='Quantity'
                            className='form-control mr-2'
                            onChange={handleChange}
                            value={values.quantity || ''}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            name='description'
                            placeholder='Description'
                            className='form-control mr-2'
                            onChange={handleChange}
                            value={values.description || ''}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            step='1'
                            min='0'
                            name='weightOz'
                            placeholder='weight (onces)'
                            className='form-control mr-2'
                            onChange={handleChange}
                            value={values.weightOz || ''}
                          />
                        </td>
                        <td>
                          <button
                            className='btn btn-outline-primary'
                            type='submit'
                            disabled={submitting}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </form>

              {this.canSchedule && (
                <Restricted policy='device::shipments::schedule'>
                  <hr />

                  <h4>Schedule shipment</h4>

                  <div className='container'>
                    <div className='row'>
                      <div className='col-auto'><label>Ship by:</label></div>

                      <div className='col-auto'>
                        <DatePicker
                          className='form-control'
                          placeholderText='Ship by'
                          minDate={new Date()}
                          selected={shipBy || ''}
                          onChange={date => this.changeShipBy(date)}
                        />
                      </div>

                      <div className='col-auto'>
                        <button
                          type='button'
                          className='btn btn-outline-primary col'
                          onClick={() => this.scheduleShipment()}
                        >
                          Schedule shipment
                        </button>
                      </div>
                    </div>
                  </div>
                </Restricted>
              )}
            </div>

            <div className='modal-footer'>
              {this.canEditItems && (
                <button
                  type='button'
                  className='btn btn-link text-danger'
                  onClick={() => this.cancelShipment()}
                >
                  Cancel shipment
                </button>
              )}

              <button
                type='button'
                className='btn btn-primary ml-auto'
                data-dismiss='modal'
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const parts = props.products.filter(function (product) {
    return product.type === PRODUCT_TYPES.PART
  })
  const kits = props.products.filter(function (product) {
    return product.type === PRODUCT_TYPES.KIT
  })

  return {
    form: `shipmentParts${props.id}`,
    parts,
    kits
  }
}

const mapDispatchToProps = {
  cancelShipment,
  scheduleShipment,
  addPartToShipment,
  removeProductFromShipment,
  updateShipment
}

const form = FormHandler(ShipmentModal)

export default connect(mapStateToProps, mapDispatchToProps)(form)
