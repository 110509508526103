import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.sites
      }

    default:
      return state
  }
}

export function getSites () {
  return function (dispatch, getState, { api, schema }) {
    return api.getSites().then(function (response) {
      const data = normalize(response, [schema.site])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function getSite (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getSite(id).then(function (response) {
      const data = normalize(response, schema.site)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function getAccountSites (accountID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getAccountSites(accountID).then(function (response) {
      const data = normalize(response, [schema.site])

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function createSite (accountID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createSite(accountID, values).then(function (response) {
      const data = normalize(response, schema.site)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}

export function updateSite (siteID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateSite(siteID, values).then(function (response) {
      const data = normalize(response, schema.site)

      dispatch(addEntities(data.entities))

      return response
    })
  }
}
