import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  REMOVE_ENTITY,
  RESET_ENTITIES,
  addEntities,
  removeEntity,
  resetEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.products
      }

    case REMOVE_ENTITY:
      const { [action.payload]: _value, ...others } = state; // eslint-disable-line

      return others

    case RESET_ENTITIES:
      return {
        ...action.payload.products
      }

    default:
      return state
  }
}

export function getClientProducts (clientID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getClientProducts(clientID)
      .then(function (response) {
        const data = normalize(response, [schema.product])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function addPartToShipment (shipmentId, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.addPartToShipment(shipmentId, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function addKitToShipment (shipmentId, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.addKitToShipment(shipmentId, values)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function removeProductFromShipment (shipmentId, productId) {
  return function (dispatch, getState, { api, schema }) {
    return api.removeProductFromShipment(shipmentId, productId)
      .then(function (response) {
        const data = normalize(response, schema.shipment)

        dispatch(addEntities(data.entities))
        dispatch(removeEntity(productId))

        return response
      })
  }
}

export function updateProduct (id, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.updateProduct(id, values)
      .then(function (response) {
        const data = normalize(response, schema.product)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function deAssignProduct (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.deAssignProduct(id)
      .then(function (response) {
        const data = normalize(response, schema.product)
        const shipments = getState().shipments
        const entities = {
          ...data.entities,
          shipments: {}
        }

        Object.keys(shipments).map(function (shipmentID) {
          const shipment = shipments[shipmentID]
          const index = shipment.products.indexOf(id)

          if (index > -1) {
            entities.shipments[shipment.id] = {
              ...shipment,
              products: [
                ...shipment.products.slice(0, index),
                ...shipment.products.slice(index + 1)
              ]
            }
          }
        })

        dispatch(addEntities(entities))

        return response
      })
  }
}

export function assignProduct (clientID, values) {
  return function (dispatch, getState, { api, schema }) {
    return api.assignProduct(clientID, values)
      .then(function (response) {
        const data = normalize(response, schema.product)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getDevices (params, reset = false) {
  return function (dispatch, getState, { api, schema }) {
    return api.getDevices(params)
      .then(function (response) {
        const data = normalize(response, [schema.product])

        if (reset) {
          dispatch(resetEntities(data.entities))
        } else {
          dispatch(addEntities(data.entities))
        }

        return response
      })
  }
}

export function getProduct (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getProduct(id)
      .then(function (response) {
        const data = normalize(response, schema.product)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function createProduct (values) {
  return function (dispatch, getState, { api, schema }) {
    return api.createProduct(values)
      .then(function (response) {
        const data = normalize(response, schema.product)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
