import React from 'react'
import { connect } from 'react-redux'
import {
  markTaskAsCompleted,
  markTaskAsInvalid,
  deleteTask
} from '../lib/redux/actions'
import {
  formatTime,
  convertToTimeZone,
  localTimeZone
} from '@/lib/functions'
import EditTaskModal from './modals/edit-task-modal'
import EscalateTaskModal from './modals/escalate-task-modal'
import Restricted from '@/components/restricted'

class TaskRow extends React.Component {
  constructor (props) {
    super(props)
    this.markAsCompleted = this.markAsCompleted.bind(this)
    this.markAsInvalid = this.markAsInvalid.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
  }

  markAsCompleted () {
    const { id, markTaskAsCompleted } = this.props
    markTaskAsCompleted(id)
  }

  markAsInvalid () {
    const { id, markTaskAsInvalid } = this.props
    markTaskAsInvalid(id)
  }

  deleteTask () {
    if (!confirm('Are you sure you want to delete this task?')) return

    const { id, deleteTask } = this.props
    deleteTask(id)
  }

  render () {
    const {
      id,
      trigger,
      number,
      description,
      startDate,
      agent,
      type
    } = this.props
    const localStartDate = convertToTimeZone(startDate, localTimeZone())
    const editTaskModalId = `edit-task-${id}`

    return (
      <div className='row data-row align-items-center'>
        <div className='col'>
          <div className='row align-items-center'>
            <div className='col-auto'>
              <div className='circled'>{number}</div>
            </div>

            <div className='col description'>
              <div className='d-flex align-items-end'>
                <b className='mr-2'>{trigger} {type ? `- ${type}` : ''}</b>
                <small>
                  {formatTime(localStartDate, 'dddd M/D')}
                </small>
              </div>

              {agent && (
                <b className='mr-1'>
                  {agent.firstName}:
                </b>
              )}

              {description}
            </div>
          </div>
        </div>

        <Restricted policy='device::profile::tasks::update'>
          <div className='col-auto'>
            <button
              type='button'
              className='btn btn-primary btn-sm mr-2'
              onClick={this.markAsCompleted}
            >
              Resolved
            </button>

            <div className='d-inline-block dropleft'>
              <a href='#' className='p-2' data-toggle='dropdown'>
                <img src='/assets/dot-menu.svg' alt='More' />
              </a>
              <div className='dropdown-menu'>
                <button
                  className='btn dropdown-item small'
                  type='button'
                  onClick={this.markAsInvalid}
                >
                  Irrelevant / Incorrect
                </button>
                <div className='dropdown-divider' />
                <button
                  className='btn dropdown-item small'
                  type='button'
                  data-toggle='modal'
                  data-target={`#${editTaskModalId}`}
                >
                  Edit Task
                </button>
                <div className='dropdown-divider' />
                <button
                  className='btn dropdown-item small'
                  type='button'
                  data-toggle='modal'
                  data-target={`#escalate-task-${id}`}
                >
                  Escalate to Program
                </button>
                <div className='dropdown-divider' />
                <button
                  className='btn text-danger dropdown-item small'
                  type='button'
                  onClick={this.deleteTask}
                >
                  Delete Task
                </button>
              </div>
            </div>
          </div>

          <EditTaskModal modalId={editTaskModalId} id={id} />
          <EscalateTaskModal modalId={`escalate-task-${id}`} id={id} />
        </Restricted>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const agent = state.agents[props.agent]
  const { currentUser } = state

  return {
    agent,
    currentUser
  }
}

const mapDispatchToProps = {
  markTaskAsCompleted,
  markTaskAsInvalid,
  deleteTask
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskRow)
