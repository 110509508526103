import React, { useState } from 'react'
import { withModalManager } from '../modal-manager'
import { connect } from 'react-redux'
import { handoffShipment } from '@/lib/redux/actions'
import { SHIPMENT_STATUSES } from '@/lib/api/schema'
import { PRODUCT_TYPES } from '../../lib/constants'
import { withRestricted } from '@/components/restricted'

function HandoffShipment (props) {
  const { handoffShipment, shipment, modal, children } = props
  if (shipment.status !== SHIPMENT_STATUSES.CREATED) return null

  const [carrier, setCarrier] = useState('')
  const hasReplacement = shipment.replacedProducts.length > 0
  const missingSerialNumbers = !shipment.products.every(function (product) {
    let hasSn = true
    let onePerSn = true

    if (product.type === PRODUCT_TYPES.BASE_UNIT) {
      hasSn = product.serialNumber && product.serialNumber.trim()
      onePerSn = product.quantity === 1
    }

    return hasSn && onePerSn
  })

  const handleSubmit = ev => {
    ev.preventDefault()

    if (missingSerialNumbers) {
      return alert('Please complete the shipment details before handing off.')
    }

    const values = {
      carrier
    }
    if (hasReplacement) {
      values.returnCarrier = carrier
    }

    return handoffShipment(shipment.id, values)
      .then(() => modal.hide())
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>Handoff Shipment</modal.Header>

        <form onSubmit={handleSubmit}>
          <modal.Body>
            <div className='form-group'>
              <label>Location</label>
              <select
                name='carrier'
                className='form-control'
                onChange={({ target }) => setCarrier(target.value)}
                value={carrier}
                required
              >
                <option value=''>- Select -</option>
                <option value='Invoy-Charlotte'>Charlotte</option>
                <option value='Invoy-HQ'>HQ</option>
                <option value='Invoy-Kiosk'>Invoy Kiosk</option>
                <option value='Invoy-Other'>Other</option>
              </select>
            </div>
          </modal.Body>

          <modal.Footer>
            <button className='btn btn-primary' type='submit'>
              Save
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const mapDispatchToProps = {
  handoffShipment
}

const connected = connect(null, mapDispatchToProps)(HandoffShipment)
const modaled = withModalManager(connected)

export default withRestricted(modaled, 'device::shipments::update')
