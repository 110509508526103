import React from 'react'
import { connect } from 'react-redux'
import store from '@/lib/redux/store'
import * as Actions from '@/lib/redux/actions'
import { selectTriggers } from '@/lib/redux/selectors'

class TriggersReference extends React.Component {
  componentDidMount () {
    store.dispatch(Actions.getTriggers())
  }

  get triggers () {
    return this.props.triggers || []
  }

  render () {
    return (
      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-8'>
            <h4>Triggers Reference</h4>

            <div className='table-responsive'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Cool off days</th>
                  </tr>
                </thead>
                <tbody>

                  {this.triggers.map((trigger) => (
                    <tr key={`trigger-${trigger.id}`}>
                      <td>{trigger.id}</td>
                      <td>{trigger.name}</td>
                      <td>{trigger.description}</td>
                      <td>{trigger.coolOffDays}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStatetoProps (state) {
  return {
    triggers: selectTriggers(state)
  }
}

export default connect(mapStatetoProps)(TriggersReference)
