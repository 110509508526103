import { normalize } from 'normalizr'
import {
  ADD_ENTITIES,
  addEntities
} from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.triggers
      }

    default:
      return state
  }
}

export function getTriggers () {
  return function (dispatch, getState, { api, schema }) {
    return api.getTriggers()
      .then(function (response) {
        const data = normalize(response, [schema.trigger])
        dispatch(addEntities(data.entities))
        return response
      })
  }
}
