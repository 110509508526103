import React from 'react'
import { connect } from 'react-redux'
import ShippingAutomationNotes from './shipping-automation-notes'
import ShipmentModal from './modals/shipment-modal'
import HandoffShipmentModal from './modals/handoff-shipment'
import Restricted from '@/components/restricted'
import {
  getClientProducts,
  getClientShipments,
  getClientReplacements,
  deAssignProduct,
  createClientShipment
} from '@/lib/redux/actions'
import { selectBaseUnits, selectMouthPieces, selectShipments } from '@/lib/redux/selectors'
import { SHIPMENT_STATUSES } from '@/lib/api/schema'
import { formatTime } from '@/lib/functions'

function getShipmentModalId (id) {
  return `shipment-modal-${id}`
}

const ShipmentModalLink = function (props) {
  if (props.disabled) return props.children

  return (
    <a
      href='#'
      data-toggle='modal'
      data-target={`#${getShipmentModalId(props.shipmentID)}`}
    >
      {props.children}
    </a>
  )
}

class DevicesAndShipments extends React.Component {
  componentDidMount () {
    const {
      getClientProducts,
      getClientShipments,
      getClientReplacements,
      clientID
    } = this.props

    getClientProducts(clientID)
    getClientShipments(clientID)
    getClientReplacements(clientID)
  }

  productShipment (shipmentID) {
    if (!shipmentID) return null

    const { shipments } = this.props
    return shipments.filter(function (shipment) {
      return shipment.id === shipmentID
    })[0]
  }

  productShipmentStatus (product) {
    const { replacements } = this.props
    const replacement = replacements[product.id]

    if (replacement) {
      const replacementShipment = this.productShipment(replacement.shipmentID)

      return (
        <ShipmentModalLink shipmentID={replacement.shipmentID}>
          replacement {replacementShipment && replacementShipment.status}
        </ShipmentModalLink>
      )
    }

    const shipment = this.productShipment(product.lastShipment)
    if (!shipment) return null

    let status = shipment.status

    switch (status) {
      case SHIPMENT_STATUSES.SCHEDULED:
        status = `Scheduled to ship by ${shipment.shipBy}`
        break
      case SHIPMENT_STATUSES.SHIPPED:
        status = `Shipped on ${shipment.shippedOn}`
        break
      case SHIPMENT_STATUSES.DELIVERED:
        status = `Delivered on ${shipment.deliveredOn}`
        break
    }

    return (
      <ShipmentModalLink shipmentID={product.lastShipment}>
        {status}
      </ShipmentModalLink>
    )
  }

  deAssignProduct (product) {
    const { deAssignProduct } = this.props

    if (!confirm('Do you want to de-assign this product from the client?')) return

    deAssignProduct(product.id)
  }

  createShipment () {
    const { createClientShipment, shipments, clientID } = this.props
    const existingShipment = shipments.filter(function (shipment) {
      return shipment.client.id === clientID &&
        shipment.status === SHIPMENT_STATUSES.CREATED
    })[0]

    if (existingShipment) {
      return alert('There is an active shipment already. Only one active shipment can be created at a time.')
    }

    if (!confirm('A new shipment will be created. Are you sure you want to continue?')) return

    createClientShipment(clientID)
      .then(function (shipment) {
        const modalID = getShipmentModalId(shipment.id)

        window.$(`#${modalID}`).modal('show')
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  devicesTable (devices) {
    return (
      <table className='table table-borderless table-striped mb-5'>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>DMR</th>
            <th>Version</th>
            <th>Assigned Date</th>
            <th>Shipping Status</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {devices.map((device) => (
            <tr
              key={`device-${device.id}`}
            >
              <td>
                <a href={`/devices/${device.id}`}>
                  {device.serialNumber}
                </a>
              </td>
              <td>{device.dmr}</td>
              <td>{device.version}</td>
              <td>{formatTime(device.assignedAt)}</td>
              <td>{this.productShipmentStatus(device)}</td>
              <td>
                <Restricted policy='device::products::de_assign'>
                  <button
                    type='button'
                    className='btn btn-sm text-danger'
                    onClick={() => this.deAssignProduct(device)}
                  >
                    De-assign
                  </button>
                </Restricted>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  render () {
    const { baseUnits, mouthPieces, shipments } = this.props

    return (
      <div>
        <Restricted policy='device::products'>
          <h5>Base Units</h5>
          {this.devicesTable(baseUnits)}

          <h5>Mouth Pieces</h5>
          {this.devicesTable(mouthPieces)}
        </Restricted>

        <Restricted policy='device::shipments'>
          <div className='d-flex'>
            <h5 className='mr-auto'>Shipments</h5>

            <Restricted policy='device::shipments::create'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => this.createShipment()}
              >
                Create Shipment
              </button>
            </Restricted>
          </div>

          <table className='table table-borderless table-striped'>
            <thead>
              <tr>
                <th>Status</th>
                <th>Created on</th>
                <th>Shipped on</th>
                <th>Tracking</th>
                <th>Shipping Automation Status</th>
                <th>Notes</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {shipments.reverse().map((shipment) => (
                <tr key={`client-shipment-${shipment.id}`}>
                  <td>
                    {shipment.status}

                    <ShipmentModal {...shipment} modalId={getShipmentModalId(shipment.id)} />
                  </td>
                  <td>{formatTime(shipment.createdAt, 'll')}</td>
                  <td>{shipment.shippedOn && formatTime(shipment.shippedOn, 'll')}</td>
                  <td>
                    {shipment.trackingURL
                      ? (
                        <a href={shipment.trackingURL} target='_blank' rel='noopener noreferrer'>
                          {shipment.carrier}
                        </a>
                        )
                      : shipment.carrier}
                  </td>
                  <td>{shipment.shippingAutomationStatus}</td>
                  <td><ShippingAutomationNotes shipment={shipment} /></td>
                  <td>
                    <button
                      type='button'
                      className='btn btn-link btn-sm mr-2'
                      data-toggle='modal'
                      data-target={`#${getShipmentModalId(shipment.id)}`}
                    >
                      View<Restricted policy='device::shipments::update'>/Edit</Restricted>
                    </button>

                    <Restricted policy='device::shipments::update'>
                      <HandoffShipmentModal
                        shipment={shipment}
                      >
                        {({ toggle }) => (
                          <button
                            type='button'
                            className='btn btn-link btn-sm mr-2'
                            onClick={toggle}
                          >
                            Handoff
                          </button>
                        )}
                      </HandoffShipmentModal>
                    </Restricted>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Restricted>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { replacements } = state
  const baseUnits = selectBaseUnits(state).filter(function (product) {
    return product.client === props.clientID
  })
  const mouthPieces = selectMouthPieces(state).filter(function (product) {
    return product.client === props.clientID
  })
  const allShipments = selectShipments(state)
  const shipments = allShipments.filter(function (shipment) {
    return shipment.client.id === props.clientID
  })
  const currentUser = state.currentUser

  return {
    baseUnits,
    mouthPieces,
    shipments,
    replacements,
    currentUser
  }
}

const mapDispatchToProps = {
  getClientProducts,
  getClientShipments,
  getClientReplacements,
  deAssignProduct,
  createClientShipment
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesAndShipments)
