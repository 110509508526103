import React from 'react'
import FormHandler from '@/components/form-handler'
import api from '@/lib/api'
import Restricted, { allow, withRestricted } from '@/components/restricted'

class Notes extends React.Component {
  componentDidMount () {
    const { clientID, change } = this.props

    api.getClientNotes(clientID)
      .then((notes) => {
        change('notes', notes || '')
      })
  }

  submit (values) {
    const { clientID } = this.props
    const { notes } = values

    return api.updateClientNotes(clientID, notes)
  }

  render () {
    const {
      handleSubmit,
      submitting,
      handleChange,
      values
    } = this.props

    return (
      <div className='mb-4'>
        <h4 className='mb-3'>Notes</h4>

        <div className='card'>
          <div className='card-body'>
            <form onSubmit={handleSubmit(values => this.submit(values))}>
              <div className='form-group'>
                <textarea
                  component='textarea'
                  onChange={handleChange}
                  value={values.notes}
                  name='notes'
                  rows='4'
                  className='form-control'
                  readOnly={!allow('device::profile::notes::update')}
                />
              </div>

              <Restricted policy='device::profile::notes::update'>
                <button
                  className='btn btn-primary'
                  type='submit'
                  disabled={submitting}
                >
                  {submitting ? 'Saving notes...' : 'Save notes'}
                </button>
              </Restricted>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const form = FormHandler(Notes, {
  enableReinitialize: true
})

export default withRestricted(form, 'device::profile::notes')
