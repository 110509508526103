import React from 'react'
import { connect } from 'react-redux'
import TaskRow from './task-row'
import { withRestricted } from '@/components/restricted'
import {
  getClientTasks
} from '@/lib/redux/actions'
import {
  selectOpenTasks
} from '@/lib/redux/selectors'

class ClientTasks extends React.Component {
  componentDidMount () {
    const {
      getClientTasks,
      clientID
    } = this.props

    getClientTasks(clientID)
  }

  render () {
    const { tasks } = this.props

    return (
      <div className='container mb-5'>
        <div className='data-table'>
          {tasks.map((task, i) => (
            <TaskRow
              {...task}
              number={i + 1}
              key={`task-${task.id}`}
            />
          ))}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const tasks = selectOpenTasks(state)

  return {
    tasks
  }
}

const mapDispatchToProps = {
  getClientTasks
}

const connected = connect(mapStateToProps, mapDispatchToProps)(ClientTasks)

export default withRestricted(connected, 'device::profile::tasks')
