import React from 'react'
import FormHandler from '@/components/form-handler'
import UserNavBar from '@/components/nav-bar'
import { loginUser } from '@/lib/redux/actions'
import store from '@/lib/redux/store'
import { RESET_PASSWORD_URL } from '@/lib/env'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  onSubmit (values) {
    return store.dispatch(loginUser(values))
      .then((response) => {
        if (response.data.otpRequired) {
          window.location = '/verify'
        } else {
          window.location = '/'
        }
      }).catch((error) => {
        this.setState({ error: error.response.data.error })
      })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting
    } = this.props
    const { error } = this.state

    return (
      <div>
        <UserNavBar />

        <div className='container py-5'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-lg-6'>
              <div className='card'>
                <div className='card-body'>
                  <h1 className='card-title'>Login</h1>

                  <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                    <div className='form-group'>
                      <label>Email</label>
                      <input
                        type='email'
                        name='email'
                        placeholder='Email'
                        className='form-control'
                        onChange={handleChange}
                        value={values.email || ''}
                        required
                        autoFocus
                      />
                    </div>
                    <div className='form-group mb-4'>
                      <label>Password</label>
                      <input
                        type='password'
                        name='password'
                        placeholder='Password'
                        className='form-control'
                        onChange={handleChange}
                        value={values.password || ''}
                        required
                      />
                      <a
                        href={RESET_PASSWORD_URL}
                        className='form-text small text-right'
                      >
                        Forgot your password?
                      </a>
                    </div>
                    {error && (
                      <p className='text-danger'>{error}</p>
                    )}
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      disabled={submitting}
                    >
                      {submitting ? 'Logging you in...' : 'Log in'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormHandler(Login)
