import React from 'react'
import { connect } from 'react-redux'
import { formatTime } from '@/lib/functions'
import { selectAccessCodes } from '@/lib/redux/selectors'

class AccessCodesTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      page: 1,
      loadMore: true
    }
  }

  componentDidMount () {
    const { fetch } = this.props

    fetch(1)
  }

  componentDidUpdate (prevProps, prevState) {
    const { page } = prevState
    const { fetch } = this.props

    if (page !== this.state.page) {
      fetch(this.state.page)
        .then((accessCodes) => {
          if (accessCodes.length === 0) {
            this.setState({ loadMore: false })
          }
        })
    }
  }

  loadNextPage () {
    const { page } = this.state

    this.setState({ page: page + 1 })
  }

  render () {
    const { accessCodes } = this.props
    const { loadMore } = this.state

    return (
      <div>
        <div className='table-responsive'>
          <table className='table table-borderless table-striped table-sm'>
            <thead>
              <tr>
                <th>Code</th>
                <th>Created at</th>
                <th>Email</th>
                <th>Serial Numbers</th>
                <th>Expiration Date</th>
                <th>Used</th>
              </tr>
            </thead>

            <tbody>
              {accessCodes.map((accessCode) => (
                <tr key={`access-code-${accessCode.id}`}>
                  <td className='font-weight-bold text-monospace'>{accessCode.code}</td>
                  <td>{formatTime(accessCode.createdAt)}</td>
                  <td>{accessCode.invitation.email}</td>
                  <td>
                    {accessCode.invitation.baseUnitSerialNumber && (
                      <>
                        Base Unit:
                        {' '}
                        <span className='text-monospace'>
                          {accessCode.invitation.baseUnitSerialNumber}
                        </span>
                      </>
                    )}
                  </td>
                  <td>{accessCode.expirationDate}</td>
                  <td>{accessCode.client ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {accessCodes.length > 0 && loadMore && (
          <button
            type='button'
            className='btn-primary btn-block btn-lg rounded'
            onClick={() => this.loadNextPage()}
          >
            Load more
          </button>
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  const accessCodes = selectAccessCodes(state)

  return {
    accessCodes
  }
}

export default connect(mapStateToProps)(AccessCodesTable)
