import React from 'react'
import { connect } from 'react-redux'
import { getParams } from '@/lib/functions'
import moment from 'moment-timezone'
import Restricted from '@/components/restricted'
import { selectSearchResultValues } from '@/lib/redux/selectors'
import ClientDisplayName from '@/components/client-display-name'
import ExpressPickupModal from '@/components/modals/express-pickup-modal'

function ClientRow (props) {
  const { accessCode, subscriptionStatus, createdAt } = props

  return (
    <tr>
      <td>
        <ClientDisplayName client={props} link />
      </td>
      <td>{moment(createdAt).fromNow(true)}</td>
      <td>{subscriptionStatus}</td>
      <td>
        <Restricted policy='device::products::assign'>
          <ExpressPickupModal client={props}>
            {({ toggle }) => (
              <button
                className='btn btn-outline-primary btn-sm mr-3 d-none'
                type='button'
                onClick={toggle}
              >
                On-site Pickup
              </button>
            )}
          </ExpressPickupModal>
        </Restricted>
      </td>
    </tr>
  )
}

class SearchResults extends React.Component {
  get clients () {
    return this.props.clients
  }

  render () {
    const searchTerm = getParams().query

    return (
      <div className='card clients-search'>
        <div className='card-body'>
          {searchTerm && (
            <h4 className='mb-5'>
              {this.clients.length} search results for "{searchTerm}"
            </h4>
          )}
          <table className='table table-striped table-borderless table-sm w-100'>
            <thead>
              <tr>
                <th>Client</th>
                <th>Duration</th>
                <th>Subscription Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.clients.map(client => (
                <ClientRow {...client} key={`client-row-${client.id}`} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const clients = selectSearchResultValues(state)

  return {
    clients
  }
}

export default connect(mapStateToProps)(SearchResults)
