import React, { useEffect } from 'react'
import Loading from '../../components/page-loading'
import NavBar from '../../components/nav-bar'
import ReferralCodes from '../../components/referral-codes'
import ReferralCodeForm from '../../components/modals/referral_code_modal'
import Restricted, { withRestricted } from '../../components/restricted'
import { connect } from 'react-redux'
import {
  capitalizeFirstLetter,
  getAccount,
  getReferralCodes
} from '@/lib/redux/actions'
import {
  selectReferralCodes
} from '@/lib/redux/selectors'

function AccountDetails (props) {
  const {
    match: {
      params: { id }
    }
  } = props
  const {
    account,
    getAccount,
    getReferralCodes,
    referralCodes
  } = props

  useEffect(() => {
    getAccount(id)
    getReferralCodes(id)
  }, [id])

  if (!account) return <Loading />

  return (
    <div>
      <NavBar />

      <div className='container'>
        <div className='d-flex mb-4'>
          <h3 className='mr-auto'>
            {account.displayName}
            {account.type && (
              <p className='small'>Affiliate Type: {capitalizeFirstLetter(account.type)}</p>
            )}
          </h3>
          <div>
            <Restricted policy='device::new_clients::referral_code'>
              <ReferralCodeForm
                affiliateID={account.id}
                coverageType={account.type}
              >
                {({ toggle }) => (
                  <button
                    type='button'
                    className='btn btn-primary btn-sm mr-2'
                    onClick={toggle}
                  >
                    New referral code
                  </button>
                )}
              </ReferralCodeForm>
            </Restricted>
          </div>
        </div>

        <ReferralCodes
          referralCodes={referralCodes.filter(
            c => c.affiliateID === account.id
          )}
        />
      </div>
    </div>
  )
}

function mapStateToProps (state, props) {
  const {
    match: {
      params: { id }
    }
  } = props
  const account = state.accounts[id]
  const referralCodes = selectReferralCodes(state)

  return {
    account,
    referralCodes
  }
}

const mapDispatchToProps = {
  getAccount,
  getReferralCodes
}

const connected = connect(mapStateToProps, mapDispatchToProps)(AccountDetails)

export default withRestricted(connected, 'device::new_clients::accounts')
