import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.accounts
      }

    default:
      return state
  }
}

export function getEnterpriseAccounts () {
  return function (dispatch, getState, { api, schema }) {
    return api.getEnterpriseAccounts()
      .then(function (response) {
        const data = normalize(response, [schema.account])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getAccount (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getAccount(id)
      .then(function (response) {
        const data = normalize(response, schema.account)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function getAccountSubscription (id) {
  return function (dispatch, getState, { api, schema }) {
    return api.getAccountSubscription(id)
      .then(function (response) {
        const data = normalize(response, schema.subscription)

        dispatch(addEntities(data.entities))

        return response
      })
  }
}
