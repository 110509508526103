import React from 'react'
import QRCode from 'qrcode'
import api from '@/lib/api'
import { withRestricted } from '@/components/restricted'

class ResetPasswordModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      url: '',
      dataURL: ''
    }
  }

  resetPassword () {
    const { clientID } = this.props

    api.resetClientPassword(clientID)
      .then((url) => {
        QRCode.toDataURL(url)
          .then((dataURL) => {
            this.setState({ url, dataURL })
          })
      })
  }

  render () {
    const { url, dataURL } = this.state

    return (
      <div id='reset-password-modal' className='modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Reset password</h5>
            </div>
            <div className='modal-body'>
              <p>
                Click this button if you want to reset the client's password.
                <br />
                Note that each click will generate a unique one-time use URL.
              </p>

              <button
                className='btn btn-primary btn-block mb-3'
                type='button'
                onClick={() => this.resetPassword()}
              >
                Generate URL
              </button>

              <input
                type='text'
                value={url}
                className='form-control mb-3'
                readOnly
              />

              {dataURL && (
                <p className='text-center'>
                  <img src={dataURL} alt={url} />
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRestricted(ResetPasswordModal, 'device::profile::reset_password')
