import React, { useState, useEffect } from 'react'
import { uniq, filter, sortBy } from 'lodash'
import DatePicker from 'react-datepicker'
import Dropdown from '@/components/dropdown'
import { SUBSCRIPTION_STATUSES } from '@/lib/constants'
import { formatTime } from '@/lib/functions'

function displaySelectedFilters (filters) {
  if (filters.referralCodes.length) return true
  if (filters.subscriptionStatuses.length) return true
  if (filters.parentAccounts.length) return true
  if (filters.registrationStart || filters.registrationEnd) return true

  return false
}

function ClientsFilters ({ initialFilters, parentAccounts, onFiltersChange }) {
  const [filters, updateFilters] = useState(initialFilters)
  const [searchTerm, setSearchTerm] = useState('')
  const [startEnrollmentDate, setStartEnrollmentDate] = useState(
    filters.registrationStart
  )
  const [endEnrollmentDate, setEndEnrollmentDate] = useState(
    filters.registrationEnd
  )

  const handleSearchTermChanged = ev => {
    setSearchTerm(ev.target.value)
  }

  const handleSearchTermKeyPressed = ev => {
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      updateFilters({
        ...filters,
        referralCodes: uniq([...filters.referralCodes, searchTerm])
      })

      setSearchTerm('')
    }
  }

  const handleRemoveReferralCode = referralCode => {
    const newReferralCodes = filter(
      filters.referralCodes,
      item => item !== referralCode
    )

    updateFilters({
      ...filters,
      referralCodes: newReferralCodes
    })
  }

  const handleAddSubscriptionStatusFilter = ev => {
    updateFilters({
      ...filters,
      subscriptionStatuses: uniq([
        ...filters.subscriptionStatuses,
        ev.target.value
      ])
    })
  }
  const handleRemoveSubscriptionStatusFilter = subscriptionStatus => {
    const newSubscriptionStatuses = filter(
      filters.subscriptionStatuses,
      item => item !== subscriptionStatus
    )

    updateFilters({
      ...filters,
      subscriptionStatuses: newSubscriptionStatuses
    })
  }

  const handleAddParentAccountFilter = ev => {
    updateFilters({
      ...filters,
      parentAccounts: uniq([...filters.parentAccounts, ev.target.value])
    })
  }
  const handleRemoveParentAccountFilter = parentAccount => {
    const newParentAccounts = filter(
      filters.parentAccounts,
      item => item !== parentAccount
    )

    updateFilters({
      ...filters,
      parentAccounts: newParentAccounts
    })
  }

  const handleChangeEnrollmentDates = (startDate, endDate) => {
    setStartEnrollmentDate(startDate)
    setEndEnrollmentDate(endDate)

    if (endDate) {
      updateFilters({
        ...filters,
        registrationStart: startDate,
        registrationEnd: endDate
      })
    }
  }
  const handleRemoveEnrollmentDates = () => {
    setStartEnrollmentDate(null)
    setEndEnrollmentDate(null)

    updateFilters({
      ...filters,
      registrationStart: null,
      registrationEnd: null
    })
  }

  const handleResetFilters = () => {
    setStartEnrollmentDate(null)
    setEndEnrollmentDate(null)

    updateFilters({
      subscriptionStatuses: [],
      parentAccounts: [],
      referralCodes: [],
      registrationStart: null,
      registrationEnd: null
    })
  }

  useEffect(() => {
    onFiltersChange(filters)
  }, [filters])

  return (
    <div>
      <div className='d-flex align-items-center'>
        <div className='mr-auto'>Filters:</div>
        <div className='form-inline justify-content-end'>
          <input
            className='form-control mr-1 mb-1'
            name='search'
            type='text'
            value={searchTerm}
            placeholder='Search by referral code'
            onChange={handleSearchTermChanged}
            onKeyUp={handleSearchTermKeyPressed}
          />

          <select
            name='subscriptionStatus'
            value=''
            onChange={handleAddSubscriptionStatusFilter}
            className='custom-select mr-1 mb-1'
          >
            <option value=''>Subscription Status</option>
            {Object.keys(SUBSCRIPTION_STATUSES).map(
              key =>
                filters.subscriptionStatuses.indexOf(key) === -1 && (
                  <option value={key} key={key}>
                    {SUBSCRIPTION_STATUSES[key]}
                  </option>
                )
            )}
          </select>

          <select
            name='parentAccount'
            value=''
            onChange={handleAddParentAccountFilter}
            className='custom-select mr-1 mb-1'
          >
            <option value=''>Enterprise Account</option>
            {sortBy(parentAccounts, 'displayName').map(
              account =>
                filters.parentAccounts.indexOf(account.displayName) === -1 && (
                  <option value={account.internalName} key={account.id}>
                    {account.displayName}
                  </option>
                )
            )}
          </select>

          <Dropdown className='mr-1 mb-1'>
            {({ Toggle, Menu, toggle }) => (
              <>
                <Toggle color='light' className='border bg-white' caret>
                  Account Created
                </Toggle>

                <Menu style={{ width: '500px' }} className='text-center'>
                  <DatePicker
                    className='form-control'
                    selected={startEnrollmentDate}
                    onChange={([startDate, endDate]) => {
                      handleChangeEnrollmentDates(startDate, endDate)

                      if (endDate) toggle()
                    }}
                    startDate={startEnrollmentDate}
                    endDate={endEnrollmentDate}
                    dateFormat='yyyy-MM-dd'
                    placeholderText='Enrollment Dates'
                    selectsRange
                    monthsShown={2}
                    inline
                  />
                </Menu>
              </>
            )}
          </Dropdown>
        </div>
      </div>

      {displaySelectedFilters(filters) && (
        <div className='d-flex align-items-center border-top pt-2'>
          <div>
            {filters.referralCodes.map(code => (
              <button
                className='btn btn-light border bg-secondary text-white'
                type='button'
                key={code}
                onClick={() => handleRemoveReferralCode(code)}
              >
                {code} <span className='badge'>&times;</span>
              </button>
            ))}

            {filters.subscriptionStatuses.map(statusId => (
              <button
                className='btn btn-light border bg-secondary text-white'
                type='button'
                key={statusId}
                onClick={() => handleRemoveSubscriptionStatusFilter(statusId)}
              >
                {SUBSCRIPTION_STATUSES[statusId]}{' '}
                <span className='badge'>&times;</span>
              </button>
            ))}

            {filters.parentAccounts.map(accountName => (
              <button
                className='btn btn-light border bg-secondary text-white'
                type='button'
                key={accountName}
                onClick={() => handleRemoveParentAccountFilter(accountName)}
              >
                {accountName} <span className='badge'>&times;</span>
              </button>
            ))}

            {endEnrollmentDate && (
              <button
                className='btn btn-light border bg-secondary text-white'
                type='button'
                onClick={handleRemoveEnrollmentDates}
              >
                {formatTime(startEnrollmentDate, 'll')} -{' '}
                {formatTime(endEnrollmentDate, 'll')}
                <span className='badge'>&times;</span>
              </button>
            )}
          </div>

          <button
            className='btn btn-link text-info mr-2'
            onClick={handleResetFilters}
          >
            Clear All Filters
          </button>
        </div>
      )}
    </div>
  )
}

export default ClientsFilters
