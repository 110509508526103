import { SHARED_PROGRAM_API_URL } from '../env'
import buildAPIClient from './build-client'

const enterpriseApi = buildAPIClient(`${SHARED_PROGRAM_API_URL}/enterprise-api`)

export default {
  createAffiliate (values) {
    return enterpriseApi.post('/affiliates', values).then(function (response) {
      return response.data.affiliate
    })
  },

  getAffiliates () {
    return enterpriseApi.get('/affiliates').then(function (response) {
      return response.data.affiliates
    })
  },

  createPaymentPlan (values) {
    return enterpriseApi
      .post('/payment-plans', values)
      .then(function (response) {
        return response.data.paymentPlan
      })
  },

  getPaymentPlan (paymentPlanId) {
    return enterpriseApi
      .get('/payment-plans/#{paymentPlanId}')
      .then(function (response) {
        return response.data.paymentPlan
      })
  },

  getMemberMembership (memberID) {
    return enterpriseApi
      .get(`/members/${memberID}/membership`)
      .then(function (response) {
        return response.data.membership
      })
  },

  pauseSubscription (id) {
    return enterpriseApi
      .post(`/subscriptions/${id}/pause`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  resumeSubscription (id) {
    return enterpriseApi
      .post(`/subscriptions/${id}/resume`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  cancelSubscription (id) {
    return enterpriseApi
      .post(`/subscriptions/${id}/cancel`)
      .then(function (response) {
        return response.data.subscription
      })
  },

  reactivateSubscription (id) {
    return enterpriseApi
      .post(`/subscriptions/${id}/reactivate`)
      .then(function (response) {
        return response.data.subscription
      })
  }
}
