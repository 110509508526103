import React from 'react'
import camelize from 'camelize'
import { config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcons from '@fortawesome/free-solid-svg-icons'
import * as regularIcons from '@fortawesome/free-regular-svg-icons'

config.autoAddCss = false

function getFontAwesomeIcon (icon, style) {
  const icons = style === 'solid' ? solidIcons : regularIcons
  return icons[camelize(`fa-${icon}`)]
}

export default function Icon ({ style = 'solid', icon, ...props }) {
  const iconProps = {
    icon: getFontAwesomeIcon(icon, style),
    fixedWidth: true,
    ...props
  }

  return (
    <FontAwesomeIcon {...iconProps} />
  )
}
