import React from 'react'
import Spinner from '@/components/spinner'
import '@/styles/page-loading.scss'

class PageLoading extends React.Component {
  render () {
    const { children } = this.props

    return (
      <div className='page-loading'>
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
          <span className='lead text-center'>
            <Spinner />
            <br />
            {children}
          </span>
        </div>
      </div>
    )
  }
}

export default PageLoading
