import React from 'react'
import api from '@/lib/api'

class ChatImage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      signedUrl: null
    }
  }

  componentDidMount () {
    const { src } = this.props

    if (!src) return

    api.fetchFileSignedUrl(src)
      .then((signedUrl) => {
        this.setState({ signedUrl })
      })
  }

  render () {
    const { signedUrl } = this.state

    if (!signedUrl) return null

    return (
      <a href={signedUrl} className='d-block' target='_blank' rel='noopener noreferrer'>
        <img className='img-fluid' src={signedUrl} alt='' />
      </a>
    )
  }
}

export default ChatImage
