import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import {
  updateShipmentAddress
} from '@/lib/redux/actions'

class AddressForm extends React.Component {
  submit (values) {
    const { updateShipmentAddress, shipmentID, onUpdate } = this.props

    return updateShipmentAddress(shipmentID, values)
      .then(function () {
        onUpdate()
      })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      error
    } = this.props

    return (
      <form
        onSubmit={handleSubmit(values => this.submit(values))}
      >
        <div className='row'>
          <div className='form-group col'>
            <label>Street line 1</label>
            <input
              type='text'
              name='street1'
              className='form-control'
              onChange={handleChange}
              value={values.street1 || ''}
              required
            />
          </div>

          <div className='form-group col'>
            <label>Street line 2 (optional)</label>
            <input
              type='text'
              name='street2'
              className='form-control'
              onChange={handleChange}
              value={values.street2 || ''}
            />
          </div>

          <div className='form-group col'>
            <label>Zip code</label>
            <input
              type='text'
              name='zipCode'
              className='form-control'
              onChange={handleChange}
              value={values.zipCode || ''}
              required
            />
          </div>
        </div>

        <div className='row'>
          <div className='form-group col'>
            <label>City</label>
            <input
              type='text'
              name='city'
              className='form-control'
              onChange={handleChange}
              value={values.city || ''}
              required
            />
          </div>

          <div className='form-group col'>
            <label>State</label>
            <input
              type='text'
              name='state'
              className='form-control'
              onChange={handleChange}
              value={values.state || ''}
              required
            />
          </div>

          <div className='form-group col'>
            <label>Country</label>
            <input
              type='text'
              name='country'
              className='form-control'
              onChange={handleChange}
              value={values.country || ''}
              required
            />
          </div>
        </div>

        {error && (
          <p className='text-danger'>{error.response.data.error}</p>
        )}

        <button
          type='submit'
          className='btn btn-primary'
          disabled={submitting}
        >
          {submitting ? 'Saving...' : 'Save'}
        </button>
      </form>
    )
  }
}

const form = FormHandler(AddressForm)

function mapStateToProps (_state, props) {
  return {
    initialValues: props.address
  }
}

const mapDispatchToProps = {
  updateShipmentAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(form)
