import React from 'react'
import { connect } from 'react-redux'
import NavBar from '@/components/nav-bar'
import PaymentPlans from '../components/payment_plans'
import ReferralCodes from '../components/referral-codes'
import ParentAccounts from '../components/parent-accounts'
import RegisterParentAccountModal from '../components/modals/register-parent-account'
import SetupDemoKitModal from '../components/modals/setup-demo-kit'
import AddPaymentPlanModal from '../components/modals/add-payment-plan'
import ExpressPickupModal from '@/components/modals/express-pickup-modal'
import { getReferralCodes } from '@/lib/redux/actions'
import { selectReferralCodes } from '@/lib/redux/selectors'
import Restricted, { withRestricted } from '@/components/restricted'

class NewClients extends React.Component {
  componentDidMount () {
    const { getReferralCodes } = this.props

    getReferralCodes()
  }

  render () {
    return (
      <div>
        <NavBar />
        <div className='container'>
          <div className='d-flex align-items-center mb-4'>
            <Restricted policy='device::products::assign'>
              <ExpressPickupModal>
                {({ toggle }) => (
                  <button
                    className='btn btn-primary mr-2 d-none'
                    type='button'
                    onClick={toggle}
                  >
                    On-site Pickup
                  </button>
                )}
              </ExpressPickupModal>
            </Restricted>

            <Restricted policy='demo_kit:setup'>
              <SetupDemoKitModal>
                {({ toggle }) => (
                  <button
                    type='button'
                    className='btn btn-primary mr-2'
                    onClick={toggle}
                  >
                    Setup demo kit
                  </button>
                )}
              </SetupDemoKitModal>
            </Restricted>

            <Restricted policy='device::new_clients::accounts::create'>
              <RegisterParentAccountModal>
                {({ toggle }) => (
                  <button
                    type='button'
                    className='btn btn-primary mr-2'
                    onClick={toggle}
                  >
                    Register affiliate (parent account)
                  </button>
                )}
              </RegisterParentAccountModal>

              <AddPaymentPlanModal>
                {({ toggle }) => (
                  <button
                    type='button'
                    className='btn btn-primary mr-2'
                    onClick={toggle}
                  >
                    Add payment plan
                  </button>
                )}
              </AddPaymentPlanModal>
            </Restricted>
          </div>

          <PaymentPlans />
          <ReferralCodes referralCodes={this.props.referralCodes} />
          <ParentAccounts />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const currentUser = state.currentUser
  const referralCodes = selectReferralCodes(state)

  return {
    currentUser,
    referralCodes
  }
}

const mapDispatchToProps = {
  getReferralCodes
}

const connected = connect(mapStateToProps, mapDispatchToProps)(NewClients)

export default withRestricted(connected, 'device::new_clients')
