import React, { useEffect } from 'react'
import AccessCodesTable from '@/components/access_codes_table'
import Loading from '../../components/page-loading'
import NavBar from '@/components/nav-bar'
import Restricted, { withRestricted } from '../../components/restricted'
import { getSite, getAccount, getSiteAccessCodes } from '@/lib/redux/actions'
import Icon from '@/components/icon'
import { connect } from 'react-redux'
import EditSiteModal from '../../components/modals/edit-site-modal'
import CreateSiteModal from '../../components/modals/create-site'

function yesNo (condition) {
  if (condition) {
    return (
      <span className='text-success'>
        <Icon icon='check' /> Yes
      </span>
    )
  }

  return (
    <span className='text-danger'>
      <Icon icon='times' /> No
    </span>
  )
}

function SiteDetails (props) {
  const {
    site,
    getSite,
    getSiteAccessCodes,
    getAccount,
    account,
    siteID
  } = props

  useEffect(() => {
    getSite(siteID).then(site => {
      getAccount(site.accountID)
    })
  }, [siteID])

  const fetchAccessCodes = page => getSiteAccessCodes(siteID, { page })

  if (!site || !account) return <Loading />

  return (
    <div>
      <NavBar />

      <div className='container'>
        <nav>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a href={`/accounts/${site.accountID}`}>{account.displayName}</a>
            </li>
            <li className='breadcrumb-item active'>Site details</li>
          </ol>
        </nav>

        <div className='card mb-5'>
          <div className='card-body'>
            <div className='d-flex mb-4'>
              <div className='mr-auto'>
                <h3>{site.name}</h3>
              </div>

              <div className=''>
                <Restricted policy='device::new_clients::accounts::create'>
                  <EditSiteModal account={account} site={site}>
                    {({ toggle }) => (
                      <button
                        type='button'
                        className='btn btn-primary btn-sm mr-2'
                        onClick={toggle}
                      >
                        Edit site
                      </button>
                    )}
                  </EditSiteModal>
                </Restricted>
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-4'>
                <p>
                  <b>ToS URL</b>
                  <br />
                  {site.tosURL}
                </p>

                <p>
                  <b>Age requirement</b>
                  <br />
                  {site.ageRequirement}
                </p>

                <p>
                  <b>Device assignment</b>
                  <br />
                  {site.deviceAssignment}
                </p>

                <p>
                  <b>Last name requirement</b>
                  <br />
                  {site.lastNameRequirement}
                </p>

                <p>
                  <b>Shipping carrier</b>
                  <br />
                  {site.shippingCarrier || 'n/a'}
                </p>

                <p>
                  <b>Shipping service</b>
                  <br />
                  {site.shippingService || 'n/a'}
                </p>

                <p>
                  <b>Work Email Hosts</b>
                  <br />
                  {site.workEmailHosts.length
                    ? site.workEmailHosts.join(', ')
                    : 'None'}
                </p>
              </div>

              <div className='col-4'>
                <p>
                  <b>Express Pickup Email Template</b>
                  <br />
                  {site.expressPickupEmailTemplate || '- None -'}
                </p>

                <p>
                  <b>Invitation Email Template</b>
                  <br />
                  {site.invitationEmailTemplate || '- None -'}
                </p>

                <p>
                  <b>Registration Flow Type</b>
                  <br />
                  {site.flowType || 'n/a'}
                </p>

                <p>
                  <b>Email required:</b> {yesNo(site.emailRequired)}
                </p>

                <p>
                  <b>Gender required:</b> {yesNo(site.genderRequired)}
                </p>

                <p>
                  <b>Middle initial required:</b>{' '}
                  {yesNo(site.middleInitialRequired)}
                </p>

                <p>
                  <b>Menopausal status required:</b>{' '}
                  {yesNo(site.menopausalStatusRequired)}
                </p>

                <p>
                  <b>Phone required:</b> {yesNo(site.phoneRequired)}
                </p>

                <p>
                  <b>Shipping address required:</b>{' '}
                  {yesNo(site.shippingAddressRequired)}
                </p>
              </div>
              <div className='col-4'>
                <p>
                  <b>Skip practice breath test:</b>{' '}
                  {yesNo(site.skipPracticeBreathTest)}
                </p>
                <p>
                  <b>Health kit / Google fit enabled:</b>{' '}
                  {yesNo(site.healthKitGoogleFitEnabled)}
                </p>

                <p>
                  <b>Subscription eligibility required:</b>{' '}
                  {yesNo(site.eligibilityRequired)}
                </p>

                <p>
                  <b>Hide Access Code Input:</b>{' '}
                  {yesNo(site.hideAccessCodeInput)}
                </p>

                <p>
                  <b>Require Insurance Provider:</b>{' '}
                  {yesNo(site.requireInsuranceProvider)}
                </p>

                <p>
                  <b>Enable In-Person Pickup:</b>{' '}
                  {yesNo(site.enableInPersonPickup)}
                </p>

                <p>
                  <b>Require Waitlist Validation:</b>{' '}
                  {yesNo(site.requiresWaitlistValidation)}
                </p>

                <p>
                  <b>Payment Method Required:</b>{' '}
                  {yesNo(site.paymentSourceRequired)}
                </p>
              </div>
            </div>

            <AccessCodesTable fetch={fetchAccessCodes} />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps (state, props) {
  const siteID = props.match.params.id
  const site = state.sites[siteID]
  const account = state.accounts[site?.accountID]

  return {
    siteID,
    site,
    account
  }
}

const mapDispatchToProps = {
  getAccount,
  getSite,
  getSiteAccessCodes
}

const connected = connect(mapStateToProps, mapDispatchToProps)(SiteDetails)

export default withRestricted(connected, 'device::new_clients::create')
