import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  AGE_REQUIREMENTS,
  DEVICE_ASSIGNMENT,
  LAST_NAME_REQUIREMENTS,
  REGISTRATION_FLOW_TYPE
} from '../../lib/constants'
import { createSite } from '../../lib/redux/actions'
import FormHandler from '../form-handler'
import { withModalManager } from '../modal-manager'
import { withRestricted } from '../restricted'

function CreateSite (props) {
  const {
    children,
    modal,
    handleSubmit,
    handleChange,
    values,
    reset,
    createSite,
    account
  } = props

  const onSubmit = values => {
    const { workEmailHosts, ...otherValues } = values

    createSite(account.id, {
      ...otherValues,
      workEmailHosts: workEmailHosts ? workEmailHosts.split(/, ?/) : []
    }).then(() => {
      reset()
      modal.hide()
    })
  }

  useEffect(() => {
    values.flowType = account.type
  }, [])

  return (
    <>
      {children({ toggle: modal.toggle })}

      <modal.Modal>
        <modal.Header>New Site</modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <modal.Body>
            <div className='form-group'>
              <label>Name</label>
              <input
                type='text'
                name='name'
                value={values.name || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. Demo Lab'
                required
              />
            </div>

            <div className='form-group'>
              <label>ToS URL</label>
              <input
                type='url'
                name='tosURL'
                value={values.tosURL || ''}
                className='form-control'
                onChange={handleChange}
                placeholder='eg. http://example.com/tos'
              />
            </div>

            <div className='form-group'>
              <label>Shipping carrier</label>
              <select
                name='shippingCarrier'
                value={values.shippingCarrier}
                className='form-control'
                onChange={handleChange}
                readOnly
              >
                <option value='fedex'>FedEx</option>
              </select>
            </div>

            <div className='form-group'>
              <label>Shipping service</label>
              <select
                name='shippingService'
                value={values.shippingService}
                className='form-control'
                onChange={handleChange}
                readOnly
              >
                <option value='fedex_2day'>FedEx 2 day</option>
              </select>
            </div>

            <fieldset>
              <legend>Rules</legend>

              <div className='form-group'>
                <label>Age requirement</label>
                <select
                  name='ageRequirement'
                  value={values.ageRequirement || ''}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {AGE_REQUIREMENTS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Device assignment</label>
                <select
                  name='deviceAssignment'
                  value={values.deviceAssignment || ''}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {DEVICE_ASSIGNMENT.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Last name requirement</label>
                <select
                  name='lastNameRequirement'
                  value={values.lastNameRequirement || ''}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {LAST_NAME_REQUIREMENTS.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Registration Flow Type</label>
                <select
                  name='flowType'
                  value={values.flowType || ''}
                  className='form-control'
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>
                    - Select -
                  </option>
                  {REGISTRATION_FLOW_TYPE.map(key => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group'>
                <label>Work Email Hosts:</label>
                <input
                  type='text'
                  name='workEmailHosts'
                  value={values.workEmailHosts || ''}
                  className='form-control'
                  onChange={handleChange}
                  placeholder='eg. example.com,acme.com'
                />
                <span className='-small form-text text-muted'>Comma separated hosts</span>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='emailRequired'
                    checked={!!values.emailRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Email required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='genderRequired'
                    checked={!!values.genderRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Gender required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='middleInitialRequired'
                    checked={!!values.middleInitialRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Middle initial required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='menopausalStatusRequired'
                    checked={!!values.menopausalStatusRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Menopausal status required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='phoneRequired'
                    checked={!!values.phoneRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Phone required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='shippingAddressRequired'
                    checked={!!values.shippingAddressRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Shipping address required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='skipPracticeBreathTest'
                    checked={!!values.skipPracticeBreathTest}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Skip practice breath test
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='healthKitGoogleFitEnabled'
                    checked={!!values.healthKitGoogleFitEnabled}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Health kit / Google Fit enabled
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='eligibilityRequired'
                    checked={!!values.eligibilityRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Subscription eligibility required
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='requireInsuranceProvider'
                    checked={!!values.requireInsuranceProvider}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Insurance Provider
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='hideAccessCodeInput'
                    checked={!!values.hideAccessCodeInput}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Hide Access Code Input
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='enableInPersonPickup'
                    checked={
                      !!values.enableInPersonPickup ||
                      values.deviceAssignment === 'express_pickup'
                    }
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Enable In-Personal Shipment Pickup
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='requiresWaitlistValidation'
                    checked={!!values.requiresWaitlistValidation}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Waitlist Validation
                </label>
              </div>

              <div className='form-check'>
                <label>
                  <input
                    type='checkbox'
                    name='paymentSourceRequired'
                    checked={!!values.paymentSourceRequired}
                    onChange={handleChange}
                    className='form-check-input'
                  />
                  Require Client Payment Source
                </label>
              </div>
            </fieldset>
          </modal.Body>

          <modal.Footer>
            <button className='btn btn-primary' type='submit'>
              Save
            </button>

            <modal.Cancel />
          </modal.Footer>
        </form>
      </modal.Modal>
    </>
  )
}

const mapDispatchToProps = {
  createSite
}
const form = FormHandler(CreateSite, {
  initialValues: {
    shippingAddressRequired: true,
    phoneRequired: true,
    emailRequired: true,
    deviceAssignment: 'shipped',
    lastNameRequirement: 'full',
    ageRequirement: 'year_of_birth',
    genderRequired: true,
    menopausalStatusRequired: true,
    healthKitGoogleFitEnabled: true,
    shippingCarrier: 'fedex',
    shippingService: 'fedex_2day',
    paymentSourceRequired: true
  }
})
const connected = connect(null, mapDispatchToProps)(form)
const modaled = withModalManager(connected)

export default withRestricted(modaled, 'device::new_clients::accounts::create')
