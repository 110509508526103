import React from 'react'
import { connect } from 'react-redux'
import Restricted from '@/components/restricted'
import ChangeTrackModal from './modals/change-track-modal'

class ShippingTrackDetails extends React.Component {
  get shippingTrackID () {
    const { client } = this.props

    return client.shippingTrackID
  }

  render () {
    const { client, tracks } = this.props
    const currentTrack = tracks[this.shippingTrackID]

    return (
      <div className='mb-3'>
        <p>
          <strong className='mr-2'>Shipping Track</strong>

          <Restricted policy='device::shipping::automation::update'>
            <ChangeTrackModal client={client}>
              {({ toggle }) => (
                <button
                  className='btn btn-link m-0 p-0 border-0 small'
                  type='button'
                  onClick={toggle}
                >
                  Change
                </button>
              )}
            </ChangeTrackModal>
          </Restricted>

          <br />
          {currentTrack ? `Track ${currentTrack.name}` : 'No track set'}
        </p>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const tracks = state.shippingTracks

  return {
    tracks
  }
}

export default connect(mapStateToProps)(ShippingTrackDetails)
