import React from 'react'
import FormHandler from '@/components/form-handler'
import UserNavBar from '@/components/nav-bar'
import { verifyOTP } from '@/lib/redux/actions'
import store from '@/lib/redux/store'
import { fetchKey, TEMP_ACCESS_TOKEN_KEY } from '@/lib/functions'

class Verify extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  get tempAccessToken () {
    return fetchKey(TEMP_ACCESS_TOKEN_KEY)
  }

  componentDidMount () {
    const tempToken = this.tempAccessToken
    if (!tempToken) {
      window.location.href = '/login'
    }
  }

  onSubmit (values) {
    values.token = this.tempAccessToken
    return store.dispatch(verifyOTP(values))
      .then(() => {
        window.location = '/'
      }).catch((error) => {
        this.setState({ error: error.response.data.error })
      })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting
    } = this.props
    const { error } = this.state

    return (
      <div>
        <UserNavBar />

        <div className='container py-5'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-8 col-lg-6'>
              <div className='card'>
                <div className='card-body'>
                  <h1 className='card-title'>Verification Code</h1>

                  <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                    <div className='form-group'>
                      <label>Invoy Portal Token</label>
                      <input
                        type='text'
                        name='otp'
                        maxLength='6'
                        placeholder='Verification Code'
                        className='form-control'
                        onChange={handleChange}
                        value={values.otp || ''}
                        required
                        autoFocus
                      />
                      <a href='/login' className='form-text small text-right'>
                        Didn't receieve a code?
                      </a>
                    </div>
                    {error && (
                      <p className='text-danger'>{error}</p>
                    )}
                    <button
                      type='submit'
                      className='btn btn-primary btn-block'
                      disabled={submitting}
                    >
                      {submitting ? 'Verifying...' : 'Verify'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormHandler(Verify)
