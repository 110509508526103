import React from 'react'
import Spinner from '@/components/spinner'
import ShippingBatch from './shipping-batch'
import { weekNumber } from '@/lib/functions'

class ShippingTracks extends React.Component {
  constructor (props) {
    super(props)

    this.handleCreateShippingBatch = this.handleCreateShippingBatch.bind(this)
  }

  shippingBatchesForTrack (track) {
    const { date } = this.props
    const { shippingBatches } = track
    if (shippingBatches.length > 0) return shippingBatches

    return [{
      id: 0,
      status: 'not_created',
      week: weekNumber(date),
      shipments: []
    }]
  }

  handleCreateShippingBatch (batch, trackId) {
    const { onShippingBatchCreate } = this.props

    onShippingBatchCreate(batch, trackId)
  }

  render () {
    const { date, tracks } = this.props

    return (
      <div>
        {tracks ? (
          tracks.map((track) => (
            this.shippingBatchesForTrack(track).map((batch) => (
              <ShippingBatch
                key={batch.id}
                shippingBatch={batch}
                date={date}
                track={track}
                onCreate={this.handleCreateShippingBatch}
              />
            ))
          ))
        ) : (
          <div className='card'>
            <div className='card-body'>
              <Spinner className='mr-2' /> Fetching...
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ShippingTracks
