import Address from '@/components/address'
import ClientDisplayName from '@/components/client-display-name'
import NavBar from '@/components/nav-bar'
import PageLoading from '@/components/page-loading'
import Restricted from '@/components/restricted'
import { formatPhoneNumber, formatTime, setPageTitle } from '@/lib/functions'
import {
  confirmEligibility,
  getClient,
  getClientAccessCode,
  getClientShipments
} from '@/lib/redux/actions'
import React from 'react'
import { connect } from 'react-redux'
import BodyCompositionReadings from '../../components/body-composition-readings'
import ChatPopup from '../../components/chat-popup'
import ClientTasks from '../../components/client-tasks'
import Notes from '../../components/client_notes'
import DevicesAndShipments from '../../components/devices-and-shipments'
import AssignDeviceModal from '../../components/modals/assign-device-modal'
import CreateTaskModal from '../../components/modals/create-task-modal'
import EditClientModal from '../../components/modals/edit-client-modal'
import ResetPasswordModal from '../../components/modals/reset-password-modal'
import SubscriptionDetails from '../../components/subscription-details'
import InsuranceDetails from '../../components/insurance-details'
import { selectAccessCodes } from '../../lib/redux/selectors'
import api from '../../lib/api'

class ClientDetails extends React.Component {
  constructor (props) {
    super(props)

    this.confirmEligibility = this.confirmEligibility.bind(this)
    this.state = {
      referredJoined: []
    }
  }

  componentDidMount () {
    const { getClient } = this.props

    getClient(this.clientID)
      .then(client => {
        api.getReferredAndJoined(client.accessCode).then(clients => {
          this.setState({ referredJoined: clients })
        })
      })
      .catch(error => {
        if (error.response.status === 404) {
          window.location = '/'
        }
      })

    getClientAccessCode(this.clientID)

    this.updatePageTitle()
  }

  componentDidUpdate () {
    this.updatePageTitle()
  }

  get clientID () {
    const {
      match: { params }
    } = this.props
    return params.id
  }

  updatePageTitle () {
    const { client } = this.props

    if (client) {
      setPageTitle(`${client.firstName} ${client.lastName}`)
    }
  }

  confirmEligibility () {
    if (!confirm('Are you sure you want to make this client eligible?')) return

    const { confirmEligibility, getClientShipments } = this.props
    confirmEligibility(this.clientID).then(() => {
      getClientShipments(this.clientID)
    })
  }

  eligibilityButton (accessCode) {
    const { client } = this.props

    if (!accessCode || !accessCode.eligibilityRequired) return null

    return client.subscriptionEligible ? (
      <span className='text-success mr-2'>Eligibility Confirmed</span>
    ) : (
      <Restricted policy='device::profile::update'>
        <div className='align-items-center'>
          <span className='text-danger mr-2'>Eligibility Pending</span>
          <button
            className='btn btn-outline-success btn-sm'
            type='button'
            onClick={this.confirmEligibility}
          >
            Confirm eligibility
          </button>
        </div>
      </Restricted>
    )
  }

  render () {
    const { client, accessCodes } = this.props
    const { referredJoined } = this.state
    const accessCode = accessCodes.filter(a => a.code === client.accessCode)[0]

    if (!client) return <PageLoading />

    const { address, corporateAccessCode } = client

    function yesNo (condition) {
      return condition ? 'Yes' : 'No'
    }

    return (
      <div>
        <NavBar left={<ClientDisplayName client={client} />} />

        <div className='container'>
          <div className='d-flex mb-4'>
            <div className='mr-auto'>
              <Restricted policy='device::profile::tasks::create'>
                <button
                  className='btn btn-outline-primary btn-sm mr-3'
                  type='button'
                  data-toggle='modal'
                  data-target='#create-task-modal'
                >
                  Add Task
                </button>
              </Restricted>

              <Restricted policy='device::products::assign'>
                <AssignDeviceModal clientID={this.clientID}>
                  {({ toggle }) => (
                    <button
                      className='btn btn-outline-primary btn-sm mr-3'
                      type='button'
                      onClick={toggle}
                    >
                      Assign Device
                    </button>
                  )}
                </AssignDeviceModal>
              </Restricted>

              <Restricted policy='device::profile::reset_password'>
                <button
                  className='btn btn-outline-primary btn-sm mr-3'
                  type='button'
                  data-toggle='modal'
                  data-target='#reset-password-modal'
                >
                  Reset password
                </button>
              </Restricted>
            </div>

            {this.eligibilityButton(accessCode)}
          </div>

          <ClientTasks clientID={this.clientID} />

          <Notes clientID={this.clientID} />

          <div className='card'>
            <div className='card-body'>
              <Restricted policy='device::profile::update'>
                <p className='text-right'>
                  <a
                    href='#'
                    className='small'
                    data-toggle='modal'
                    data-target='#edit-client-modal'
                  >
                    Edit
                  </a>
                </p>
              </Restricted>

              {client.vip && <p className='text-danger bold'>VIP Client</p>}

              <div className='row mb-5'>
                <div className='col-4'>
                  {corporateAccessCode && (
                    <p>
                      <span className='font-weight-bold'>
                        Corporate Access Code:
                      </span>
                      <br />
                      <span className='text-monospace'>
                        {corporateAccessCode}
                      </span>
                    </p>
                  )}
                  <p>
                    <span className='font-weight-bold'>Access Code:</span>
                    <br />
                    <span className='text-monospace'>{accessCode?.code}</span>
                  </p>
                  <p>
                    <span className='font-weight-bold'>Registered at:</span>
                    <br />
                    {formatTime(client.createdAt)}
                  </p>
                  <p>
                    <span className='font-weight-bold'>
                      Registration email:
                    </span>
                    <br />
                    <a href={`mailto:${client.email}`}>{client.email}</a>
                  </p>
                  <p>
                    <span className='font-weight-bold'>Phone:</span>
                    {formatPhoneNumber(client.phoneNumber)}
                  </p>
                  <p>
                    <span className='font-weight-bold'>Last user agent:</span>
                    <br />
                    {client.lastUserAgent || 'N/A'}
                  </p>
                  <p>
                    <span className='font-weight-bold'>
                      Prefers In-Person Device Pickup:
                    </span>
                    <br />
                    {yesNo(client.prefersInPersonDevicePickup)}
                  </p>
                </div>

                <div className='col-4'>
                  {accessCode?.invitation && (
                    <>
                      <p>
                        <span className='font-weight-bold'>Invited at:</span>
                        <br />
                        {accessCode.invitation.email}
                      </p>
                      {accessCode?.invitation?.referrerEmail && (
                        <p>
                          <span className='font-weight-bold'>
                            Referrer email:
                          </span>
                          <br />
                          {accessCode.invitation.referrerEmail}
                        </p>
                      )}
                      {referredJoined.length > 0 && (
                        <div>
                          <span className='font-weight-bold'>
                            Additional invitee:
                          </span>
                          <br />
                          <ul className='list-unstyled'>
                            {referredJoined.map(c => (
                              <li key={c.id}>
                                <ClientDisplayName client={c} link />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className='col-4'>
                  {address && (
                    <div>
                      <span className='font-weight-bold'>Address</span>
                      <br />
                      <Address {...address} />
                    </div>
                  )}
                </div>
              </div>

              <Restricted policy='health::members::billing'>
                <InsuranceDetails clientID={this.clientID} />
              </Restricted>
              <SubscriptionDetails clientID={this.clientID} />
              <DevicesAndShipments clientID={this.clientID} />
              <BodyCompositionReadings clientID={this.clientID} />
            </div>
          </div>
        </div>

        <ChatPopup clientID={this.clientID} />
        <EditClientModal clientID={this.clientID} />
        <CreateTaskModal clientID={this.clientID} />
        <ResetPasswordModal clientID={this.clientID} />
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const {
    match: {
      params: { id }
    }
  } = props
  const client = state.clients[id]
  const { currentUser } = state
  const accessCodes = selectAccessCodes(state)

  return {
    client,
    currentUser,
    accessCodes
  }
}

const mapDispatchToProps = {
  getClient,
  confirmEligibility,
  getClientShipments,
  getClientAccessCode
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
