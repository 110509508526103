import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

class ModalManager extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: props.isOpen }
    this.toggle = this.toggle.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.renderModal = this.renderModal.bind(this)
    this.renderCancel = this.renderCancel.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  toggle () {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  hide () {
    this.setState({ isOpen: false })
  }

  show () {
    this.setState({ isOpen: true })
  }

  renderModal ({ children, ...props }) {
    const { isOpen } = this.state

    return (
      <Modal backdrop='static' fade={false} {...props} isOpen={isOpen}>
        {children}
      </Modal>
    )
  }

  renderCancel ({ children, ...props }) {
    return (
      <button
        className='btn btn-danger'
        type='button'
        {...props}
        onClick={this.toggle}
      >
        {children || 'Cancel'}
      </button>
    )
  }

  renderHeader (props) {
    return (
      <ModalHeader toggle={this.toggle} {...props} />
    )
  }

  render () {
    const { children } = this.props

    return children({
      toggle: this.toggle,
      show: this.show,
      hide: this.hide,
      Modal: this.renderModal,
      Header: this.renderHeader,
      Body: ModalBody,
      Footer: ModalFooter,
      Cancel: this.renderCancel
    })
  }
}

export function withModalManager (Component) {
  return function (props) {
    return (
      <ModalManager>
        {(modal) => (
          <Component {...props} modal={modal} />
        )}
      </ModalManager>
    )
  }
}

export default ModalManager
