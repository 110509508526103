import React from 'react'
import { uniq, compact } from 'lodash'
import { connect } from 'react-redux'
import ClientRow from './client-row'
import {
  selectClientsWithOpenTasks,
  selectAgentsValues,
  selectTriggers
} from '@/lib/redux/selectors'
import {
  getFilteredTasks,
  getTriggers,
  getClient
} from '../lib/redux/actions'
import paginationHandler from './pagination-handler'

function byAgentID (filters) {
  return function (client) {
    if (!filters.agentID || filters.agentID === 'unassigned') return true

    const assignedTasks = client.tasks
      .some(task => task.agent === filters.agentID)

    return assignedTasks
  }
}

function byTriggers (filters) {
  return function (client) {
    if (filters.triggers.length === 0) return true

    return filters.triggers.some(function (triggerID) {
      return client.tasks.map(t => t.triggerID).indexOf(triggerID) > -1
    })
  }
}

class TasksList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filters: {
        agentID: '',
        triggers: []
      }
    }

    this.reloadData = this.reloadData.bind(this)
    this.loadNextPage = this.loadNextPage.bind(this)
  }

  componentDidMount () {
    const { getTriggers } = this.props

    getTriggers()
    this.reloadData()
  }

  get filteredClients () {
    const { allClients } = this.props
    if (!allClients) return []

    const { filters } = this.state
    const agentFilter = byAgentID(filters)
    const triggersFilter = byTriggers(filters)

    return allClients.filter(function (client) {
      return agentFilter(client) &&
        triggersFilter(client)
    })
  }

  get agentClients () {
    const { agentID } = this.state
    const { allClients } = this.props

    return allClients ? allClients.filter(function (client) {
      return client.tasks.some(task => task.agent === agentID)
    }) : []
  }

  filterAgent (agentID) {
    this.updateFilters({ agentID })
  }

  filterTriggers (value) {
    const { filters } = this.state
    const triggerID = parseInt(value, 10)

    if (filters.triggers.indexOf(triggerID) > -1) return

    this.updateFilters({
      triggers: [
        ...filters.triggers,
        triggerID
      ]
    })
  }

  get triggersFilterList () {
    const { triggers } = this.props
    const { filters } = this.state

    return triggers.filter(function (trigger) {
      return filters.triggers.indexOf(trigger.id) === -1
    })
  }

  triggerName (id) {
    const { triggersById } = this.props
    const trigger = triggersById[id]

    if (!trigger) return 'n/a'

    return trigger.name
  }

  removeTaskFilter (triggerID) {
    const { filters } = this.state
    const index = filters.triggers.indexOf(triggerID)

    if (index === -1) return

    const newTriggers = [
      ...filters.triggers.slice(0, index),
      ...filters.triggers.slice(index + 1)
    ]

    this.updateFilters({ triggers: newTriggers })
  }

  updateFilters (newFilters) {
    const { filters } = this.state
    const { resetPagination } = this.props

    this.setState({
      filters: {
        ...filters,
        ...newFilters
      }
    }, () => {
      resetPagination(this.reloadData)
    })
  }

  reloadData () {
    const { pageSize, currentPage } = this.props.pagination

    for (let page = 1; page <= currentPage; page++) {
      this.loadPageData({ page, per_page: pageSize })
    }
  }

  loadNextPage () {
    const { pageSize, nextPage } = this.props.pagination
    this.loadPageData({ page: nextPage, per_page: pageSize })
  }

  loadPageData (pagination) {
    const { filters } = this.state
    const { getFilteredTasks, clientsById, getClient, updatePagination } = this.props
    const params = {
      triggers: filters.triggers.join(','),
      ...pagination
    }
    const agentID = filters.agentID

    clearTimeout(this.reloadTimeout)

    if (agentID) {
      params.agent_id = agentID
    }

    getFilteredTasks(params)
      .then((data) => {
        const { tasks, pagination } = data

        const taskClients = compact(tasks.map(t => t.clientID))
        const clientIds = uniq(taskClients)

        updatePagination(pagination)

        clientIds.map(function (id) {
          if (clientsById[id]) return

          getClient(id)
        })

        this.reloadTimeout = setTimeout(this.reloadData, 45000)
      })
  }

  render () {
    const { agents, renderNextPageButton } = this.props
    const { filters } = this.state

    return (
      <div className='mb-5'>
        <div className='mb-5'>
          <div className='d-flex justify-content-end mb-3'>
            <h4 className='mr-auto'>Tasks ({this.filteredClients.length})</h4>

            <div className='form-inline'>
              <select
                className='custom-select custom-select-sm mr-3'
                value={filters.agentID}
                onChange={ev => this.filterAgent(ev.target.value)}
              >
                <option value=''>All agents</option>
                <option value='unassigned'>- Unassigned -</option>
                <optgroup
                  key='tasks-by-agent'
                  label='By Agent'
                >
                  {agents.map((agent) => (
                    <option
                      key={`tasks-agent-${agent.id}`}
                      value={agent.id}
                    >
                      {agent.firstName} {agent.lastName}
                    </option>
                  ))}
                </optgroup>
              </select>

              <select
                className='custom-select custom-select-sm'
                onChange={ev => this.filterTriggers(ev.target.value)}
              >
                <option value=''>All Triggers</option>
                {this.triggersFilterList.map((trigger) => (
                  <option
                    value={trigger.id}
                    key={`trigger-filter-${trigger.id}`}
                  >
                    {trigger.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='text-right mb-4'>
          {filters.triggers.length > 0 ? (
            <span className='mr-3'>Showing tasks with triggers:</span>
          ) : null}
          {filters.triggers.map((id) => (
            <button
              className='btn btn-light bg-white btn-sm mr-3 mb-2'
              type='button'
              key={`trigger-filter-badge-${id}`}
              onClick={() => this.removeTaskFilter(id)}
            >
              {this.triggerName(id)} <span className='badge'>&times;</span>
            </button>
          ))}
        </div>

        <div className='data-table mb-5'>
          {this.filteredClients.length < 1 && (
            <p className='lead text-muted'>Agent has no assigned tasks.</p>
          )}

          {this.filteredClients.map((client) => (
            <ClientRow {...client} key={`client-${client.id}`} />
          ))}

          {renderNextPageButton(this.loadNextPage)}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const allClients = selectClientsWithOpenTasks(state)
  const agents = selectAgentsValues(state)
  const triggers = selectTriggers(state)
  const {
    currentUser,
    clients: clientsById,
    triggers: triggersById
  } = state

  return {
    currentUser,
    clientsById,
    allClients,
    agents,
    triggers,
    triggersById
  }
}

const mapDispatchToProps = {
  getFilteredTasks,
  getTriggers,
  getClient
}

const PaginatedTasksList = paginationHandler(TasksList)

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedTasksList)
