import { normalize } from 'normalizr'
import { ADD_ENTITIES, addEntities } from './actions'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...state,
        ...action.payload.productReadings
      }

    default:
      return state
  }
}

export function getProductReadings (productID) {
  return function (dispatch, getState, { api, schema }) {
    return api.getProductReadings(productID)
      .then(function (response) {
        const data = normalize(response, [schema.productReading])

        dispatch(addEntities(data.entities))

        return response
      })
  }
}

export function deleteProductReadings (productID) {
  return function (dispatch, getState, { api }) {
    return api.deleteProductReadings(productID)
  }
}
