export const UI = 'UI'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case UI:
      return {
        ...state,
        ...action.payload.update
      }

    default:
      return state
  }
}

export function updateUI (update) {
  return {
    type: UI,
    payload: { update }
  }
}

export function capitalizeFirstLetter(string) {
  if (string === undefined || string === null) return "";
  const capitalizeFirstLetter = (
    [first, ...rest],
    locale = navigator.language
  ) => first.toLocaleUpperCase(locale) + rest.join("");
  return capitalizeFirstLetter(string);
}
