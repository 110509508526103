import React from 'react'
import { connect } from 'react-redux'
import Dropdown from '@/components/dropdown'
import Restricted from '@/components/restricted'

function ClientListSelectedActions ({ onActivateMembers }) {
  return (
    <>
      <Dropdown className='mr-3'>
        {({ Toggle, Menu, Item }) => (
          <>
            <Toggle color='light' caret>
              Actions...
            </Toggle>
            <Menu>
              <Restricted policy='device::subscription::update'>
                <Item onClick={onActivateMembers}>Enroll Members</Item>
              </Restricted>
            </Menu>
          </>
        )}
      </Dropdown>
    </>
  )
}

export default connect()(ClientListSelectedActions)
