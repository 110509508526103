import React from 'react'

export default function ShippingAutomationNotes ({ shipment }) {
  switch (shipment.shippingAutomationStatus) {
    case 'failed':
      return (
        <span className='text-danger'>
          Failed automatic order creation (Reason: {shipment.shippingAutomationNotes})
        </span>
      )

    case 'exception':
      return (
        <span className='text-warning'>
          Manual Exception: {shipment.shippingException.reason}
        </span>
      )

    case 'pending':
      return <span className='text-info'>Generating order</span>

    case 'address_verification_pending':
      return <span className='text-info'>Address verification pending</span>

    case 'success':
      return <span className='text-success'>Order ready</span>

    default:
      return null
  }
}
