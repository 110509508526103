import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import ShipmentModal from './modals/shipment-modal'
import ClientDisplayName from './client-display-name'
import { SHIPMENT_STATUSES } from '../lib/api/schema'
import { shippingServiceName } from '../lib/functions'
import { selectProductPresetKits } from '../lib/redux/selectors'
import { allow } from './restricted'
import Icon from './icon'

class ShipmentRow extends React.Component {
  get isCreated () {
    const { status } = this.props
    const { CREATED } = SHIPMENT_STATUSES

    return status === CREATED
  }

  get isScheduled () {
    const { status } = this.props.shipment
    const { SCHEDULED } = SHIPMENT_STATUSES

    return status === SCHEDULED
  }

  get isEditable () {
    const allowed = allow('device::shipments::update')

    return allowed ? this.isCreated : this.isScheduled
  }

  get productPresetKit () {
    const { shipment, productPresetKits } = this.props
    return productPresetKits.filter(p => p.slug === shipment.kit)[0]
  }

  render () {
    const { shipment } = this.props
    const {
      id,
      status,
      createdAt,
      client,
      shipBy,
      shippedOn,
      deliveredOn,
      expedited,
      shippingService
    } = shipment
    const modalId = `shipment-modal-${id}`

    return (
      <tr>
        <td>
          <ClientDisplayName client={client} link />
        </td>
        <td>{this.productPresetKit && this.productPresetKit.name}</td>
        <td>{status}</td>
        <td>{createdAt && moment(createdAt).format('ll')}</td>
        <td>{shipBy && moment(shipBy).format('ll')}</td>
        <td>{shippedOn && moment(shippedOn).format('ll')}</td>
        <td>{deliveredOn && moment(deliveredOn).format('ll')}</td>
        <td>
          {expedited ? <Icon icon='bolt' className='text-warning' /> : ''} {shippingServiceName(shippingService)}
        </td>
        <td>
          <button className='btn btn-outline-primary btn-sm' data-toggle='modal' data-target={`#${modalId}`}>
            View
          </button>
          <ShipmentModal {...shipment} modalId={modalId} />
        </td>
      </tr>
    )
  }
}

function mapStateToProps (state) {
  const productPresetKits = selectProductPresetKits(state)

  return {
    productPresetKits
  }
}

export default connect(mapStateToProps)(ShipmentRow)
