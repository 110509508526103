import { schema } from 'normalizr'

export const agent = new schema.Entity('agents')
export const client = new schema.Entity('clients')
export const membership = new schema.Entity('memberships')
export const searchResult = new schema.Entity('searchResults')
export const product = new schema.Entity('products')
export const productReading = new schema.Entity('productReadings')
export const productPreset = new schema.Entity('productPresets')
export const shipment = new schema.Entity('shipments', {
  products: [product],
  replacedProducts: [product]
})
export const replacement = new schema.Entity(
  'replacements',
  {},
  {
    idAttribute: function (value) {
      return value.productID
    }
  }
)
export const paymentPlan = new schema.Entity('paymentPlans')
export const accessCode = new schema.Entity('accessCodes')
export const referralCode = new schema.Entity('referralCodes')
export const subscription = new schema.Entity('subscriptions')
export const ticket = new schema.Entity('tickets')
export const account = new schema.Entity('accounts')
export const waitlist = new schema.Entity('waitlist')
export const task = new schema.Entity('tasks')
export const trigger = new schema.Entity('triggers')
export const site = new schema.Entity('sites')
export const bodyCompositionReading = new schema.Entity(
  'bodyCompositionReadings'
)
export const bodyCompositionReport = new schema.Entity('bodyCompositionReports')
export const accessPolicy = new schema.Entity(
  'accessPolicies',
  {},
  {
    idAttribute: function (value) {
      return value.name
    }
  }
)
export const shippingTrack = new schema.Entity('shippingTracks')

export const TASK_STATUSES = {
  OPEN: 'open',
  COMPLETED: 'completed',
  INVALID: 'invalid',
  ESCALATED_TO_PROGRAM: 'escalated_to_program'
}

export const SHIPMENT_STATUSES = {
  CREATED: 'created',
  SCHEDULED: 'scheduled',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  CANCELED: 'canceled'
}

export const SUBSCRIPTION_STATUSES = {
  REGISTERED: 'registered',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled'
}

export const TICKET_STATUSES = {
  OPEN: 'open',
  RESOLVED: 'resolved'
}

export const REPLACEMENT_REASONS = [
  'No Reason',
  'Complete wetting failure',
  'Faulty tank',
  'BLE connection',
  'App-Device connection',
  'Tray close at start',
  'Tray close at end',
  'Tray to base unit',
  'Unexpected low scores',
  'MP tubing',
  'MP beeping',
  'Bead spillage',
  'Overwetting',
  'Warm device',
  'Long-range BLE failure',
  'Device clock drift'
]

export const REPLACEMENT_REASON_DESCRIPTIONS = {
  'No Reason': 'Replacing as part of a whole kit.',
  'Complete wetting failure':
    'Base unit does not wet. After changing to a new tank, ' +
    'test still results in white cartridge.',
  'Faulty tank':
    'Faulty tank. Analyst confirmed a white cartridge. After intervention, the cartridge wet properly.',
  'BLE connection':
    'After trying to initiate a breath test, client receives an "Oops" message.',
  'App-Device connection':
    'During test initiation, hitting the "Open Tray" button results ' +
    'in an abnormal delay in getting the tray to open. Client is stuck at "Sending to Device" screen.',
  'Tray close at start':
    'During test initiation, after loading cartridge into tray, tray does not close.',
  'Tray close at end':
    'At the end of the test, after removing cartridge from tray, tray does not close.',
  'Tray to base unit':
    'During test initiation, hitting the "Open Tray" button does not result ' +
    'in the tray opening. Client is stuck at "Insert Cartridge" screen.',
  'Unexpected low scores':
    'After retraining on proper breathing technique, lower acetone scores continue.',
  'MP tubing': 'MP Tubing becomes dislodged.',
  'MP beeping': 'MP does not beep for 7 seconds + follow with a single beep.',
  'Bead spillage':
    'Client reports particles being present outside of the cartridges.',
  Overwetting:
    'Client reports "overwetting," or that liquid is present within the tray, or ' +
    'outside of the tank or cartridge.',
  'Warm device': 'Client reports that their device is "warm" to the touch.',
  'Long-range BLE failure':
    'If client moves away from their device by more than 50 feet, ' +
    'the App and the phone may disconnect. Cilent does not get a reading.',
  'Device clock drift':
    'After running a test, client is not able to see their data for that day. ' +
    'Test score is logged the day before.'
}

export const METRICS = [
  'New Clients',
  'Total Clients',
  'Dropouts',
  'Waitlist Time',
  'Revenue'
]
