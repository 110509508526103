import { removeKey, ACCESS_TOKEN_KEY } from '@/lib/functions'
export const AUTHENTICATE = 'AUTHENTICATE'

export default function reducer (state = null, action) {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...action.payload.user
      }

    default:
      return state
  }
}

export function authenticate (user) {
  return {
    type: AUTHENTICATE,
    payload: { user }
  }
}

export function logoutUser () {
  return function (dispatch, getstate, { api }) {
    return api.logoutUser().then(function (response) {
      dispatch(authenticate(null))
      return response
    })
  }
}

export function loginUser (values) {
  return function (dispatch, getstate, { api }) {
    return api.loginUser(values).then(function (response) {
      if (!response.data.otpRequired) {
        dispatch(verifyCurrentUser())
      }

      return response
    })
  }
}

export function verifyOTP (values) {
  return function (dispatch, getstate, { api }) {
    return api.verifyOTP(values).then(function (response) {
      dispatch(verifyCurrentUser())
      return response
    })
  }
}

export function verifyCurrentUser () {
  return function (dispatch, getstate, { api }) {
    return api
      .verifyCurrentUser()
      .then(function (response) {
        if (response.lockedAt || response.deletedAt) {
          dispatch(logoutUser())
          location.reload()
          return
        }

        dispatch(authenticate(response))
        return response
      })
      .catch(function (error) {
        removeKey(ACCESS_TOKEN_KEY)

        throw error
      })
  }
}

export function resetPassword (email) {
  return function (dispatch, getstate, { api }) {
    return api.resetPassword(email)
  }
}
