import React from 'react'
import store from '@/lib/redux/store'

export function allow (policyName) {
  const currentUser = store.getState().currentUser

  if (!policyName) return true
  if (!currentUser) return false

  const policy = store.getState().accessPolicies[policyName]

  if (!policy) return false

  return policy.roles.some((role) => {
    return currentUser.roles.indexOf(role) > -1
  })
}

export function withRestricted (Component, policy) {
  return function RestrictedComponent (props) {
    if (!allow(policy)) return null

    return (
      <Component {...props} />
    )
  }
}

export default function Restricted (props) {
  const { policy, children } = props
  if (!allow(policy)) return null

  return children
}
