// Taken from https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react

import React, { useState } from 'react'
import { range } from 'lodash'
import Icon from './icon'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'
const DOTS = 'DOTS'
const PAGE_NEIGHBOURS = 1

function fetchPageNumbers ({ totalPages, currentPage }) {
  const totalNumbers = PAGE_NEIGHBOURS * 2 + 3
  const totalBlocks = totalNumbers + 2

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, currentPage - PAGE_NEIGHBOURS)
    const endPage = Math.min(totalPages - 1, currentPage + PAGE_NEIGHBOURS)
    let pages = range(startPage, endPage + 1)
    const hasLeftSpill = startPage > 2
    const hasRightSpill = totalPages - endPage > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1)
        pages = [DOTS, ...extraPages, ...pages]
        break
      }

      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset)
        pages = [...pages, ...extraPages, DOTS]
        break
      }

      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [DOTS, ...pages, DOTS]
        break
      }
    }

    return [LEFT_PAGE, 1, ...pages, totalPages, RIGHT_PAGE]
  }

  return range(1, totalPages + 1)
}

export default function Pagination (props) {
  const { totalRecords, perPage, onPageChange = () => {} } = props
  const pageSize = perPage || 50
  const totalPages = Math.ceil(totalRecords / pageSize)
  if (!totalRecords || totalPages === 1) return null

  const [currentPage, setCurrentPage] = useState(
    parseInt(props.currentPage || '1')
  )
  const gotoPage = page => {
    const current = Math.max(1, Math.min(page, totalPages))

    const paginationData = {
      currentPage: current,
      totalPages,
      pageSize,
      totalRecords
    }

    setCurrentPage(current)
    onPageChange(paginationData)
  }
  const handleMoveLeft = evt => {
    evt.preventDefault()
    gotoPage(currentPage - 1)
  }
  const handleMoveRight = evt => {
    evt.preventDefault()
    gotoPage(currentPage + 1)
  }
  const handleClick = page => evt => {
    evt.preventDefault()
    gotoPage(page)
  }
  const pages = fetchPageNumbers({ totalPages, currentPage })

  return (
    <>
      <nav>
        <ul className='pagination justify-content-center'>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li
                  key={index}
                  className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}
                >
                  <a className='page-link' href='#' onClick={handleMoveLeft}>
                    <Icon icon='caret-left' />
                  </a>
                </li>
              )
            }

            if (page === DOTS) {
              return (
                <li key={index} className='page-item disabled'>
                  <span className='page-link'>...</span>
                </li>
              )
            }

            if (page === RIGHT_PAGE) {
              return (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage >= totalPages ? 'disabled' : ''
                  }`}
                >
                  <a className='page-link' href='#' onClick={handleMoveRight}>
                    <Icon icon='caret-right' />
                  </a>
                </li>
              )
            }

            return (
              <li
                key={index}
                className={`page-item${currentPage === page ? ' active' : ''}`}
              >
                <a className='page-link' href='#' onClick={handleClick(page)}>
                  {page}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}
