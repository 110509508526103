import React from 'react'
import FormHandler from '@/components/form-handler'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { updateTask } from '@/lib/redux/actions'
import { selectAgentsValues } from '@/lib/redux/selectors'
import { parseDate, localTimeZone, formatTime } from '@/lib/functions'
import { withRestricted } from '@/components/restricted'
import '~/react-datepicker/dist/react-datepicker.css'

class EditTaskModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      startDate: parseDate(props.initialValues.startDate, localTimeZone())
    }
  }

  onSubmit (values) {
    const { updateTask, reset, id, modalId } = this.props
    const startDate = formatTime(this.state.startDate, 'YYYY-MM-DD')

    return updateTask(id, {
      ...values,
      agentID: values.agent,
      startDate
    }).then(() => {
      reset()
      window.$(`#${modalId}`).modal('hide')
    })
  }

  changeStartDate (date) {
    this.setState({ startDate: date })
  }

  render () {
    const {
      handleSubmit,
      handleChange,
      values,
      submitting,
      agents,
      modalId,
      id
    } = this.props
    const { startDate } = this.state

    return (
      <div className='modal' id={modalId}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Edit Task
              </h5>
              <button className='close' type='button' data-dismiss='modal'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <div className='form-group'>
                  <label>Description</label>
                  <input
                    type='text'
                    name='description'
                    className='form-control'
                    placeholder='Write a description for the task'
                    onChange={handleChange}
                    value={values.description || ''}
                    required
                  />
                </div>
                <div className='form-group'>
                  <label>Start Date:</label>
                  <DatePicker
                    className='form-control d-inline w-auto'
                    placeholderText='MM/DD/YYYY'
                    minDate={new Date()}
                    selected={startDate}
                    onChange={date => this.changeStartDate(date)}
                  />
                </div>
                <div className='form-group'>
                  <label>Agent</label>
                  <select
                    name='agent'
                    className='form-control'
                    onChange={handleChange}
                    value={values.agent || ''}
                  >
                    <option value=''>- None -</option>
                    {agents.map((agent) => (
                      <option
                        value={agent.id}
                        key={`edit-task-${id}-agent-${agent.id}`}
                      >
                        {agent.firstName} {agent.lastName}
                      </option>
                    ))}
                  </select>
                  <p className='small form-text text-muted'>
                    Assign this task to an agent
                  </p>
                </div>
                <button className='btn btn-primary' type='submit' disabled={submitting}>
                  {submitting ? 'Saving...' : 'Save'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const agents = selectAgentsValues(state)
  const task = state.tasks[props.id]

  return {
    agents,
    initialValues: {
      ...task
    }
  }
}

const mapDispatchToProps = {
  updateTask
}

const form = FormHandler(EditTaskModal, {
  enableReinitialize: true
})
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default withRestricted(connected, 'device::profile::tasks::update')
