import React from 'react'
import nl2br from 'react-nl2br'
import moment from 'moment'
import { connect } from 'react-redux'
import FormHandler from '@/components/form-handler'
import ChatImage from './chat-image'
import {
  getClientTickets,
  createTicketMessage,
  updateUI
} from '../lib/redux/actions'
import api from '@/lib/api'
import Restricted, { withRestricted } from '@/components/restricted'
import '@/styles/chat-popup.scss'

function ChatMessage (props) {
  const {
    sender,
    message,
    client,
    createdAt,
    imageURL
  } = props
  const byClient = client && sender === client.id
  const sentAt = moment(createdAt).format('lll')

  return (
    <div
      className={`message by-${byClient ? 'them' : 'me'}`}
    >
      <div className='content'>
        <small className='by d-block text-muted'>
          {byClient ? (client && client.firstName) : 'Invoy'} @ {sentAt}:
        </small>
        <p>
          {message && nl2br(message)}
          <ChatImage src={imageURL} />
        </p>
      </div>
    </div>
  )
}

class ChatPopup extends React.Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { uploading: false }
  }

  componentDidMount () {
    const { getClientTickets, clientID } = this.props

    getClientTickets(clientID)
  }

  componentDidUpdate () {
    this.scroller && this.scroller.scroll(0, this.scroller.children[0].offsetHeight)
  }

  toggle () {
    const { chatOpen, updateUI } = this.props

    updateUI({ chatOpen: !chatOpen })
  }

  sendChatMessage (values) {
    const {
      createTicketMessage,
      reset,
      clientID,
      ticketID,
      currentUser
    } = this.props

    reset()

    return createTicketMessage(clientID, ticketID, {
      message: values.message,
      sender: currentUser.id
    })
  }

  uploadImage (ev) {
    const { target: { files } } = ev
    const file = files[0]
    const {
      ticketID,
      createTicketMessage,
      clientID,
      currentUser
    } = this.props
    this.setState({ uploading: true })

    api.uploadTicketImage(ticketID, file)
      .then((imageName) => {
        return createTicketMessage(clientID, ticketID, {
          imageName,
          sender: currentUser.id
        }).then(() => {
          this.setState({ uploading: false })
        })
      })
      .catch(() => {
        alert('Could not upload image, please try again.')
        this.setState({ uploading: false })
      })
  }

  render () {
    const {
      messages,
      handleSubmit,
      handleChange,
      values,
      submitting,
      client,
      chatOpen
    } = this.props
    const { uploading } = this.state

    return (
      <div className='chat-popup'>
        {chatOpen ? (
          <div className='card chat-window'>
            <div className='card-header'>
              <p className='text-right m-0'>
                <button className='btn btn-link small' type='button' onClick={this.toggle}>Hide</button>
              </p>
            </div>
            <div className='scroll-y border-bottom' ref={el => this.scroller = el}>
              <div className='card-body'>
                {messages.map((message) => (
                  <ChatMessage
                    client={client}
                    key={`message-${message.id}`}
                    {...message}
                  />
                ))}
              </div>
            </div>
            <Restricted policy='device::profile::chat::create'>
              <form className='form-inline d-flex' onSubmit={handleSubmit(values => this.sendChatMessage(values))}>
                <textarea
                  className='form-control flex-fill mr-auto'
                  placeholder='Send a message...'
                  name='message'
                  onChange={handleChange}
                  value={values.message || ''}
                  required
                  autoFocus
                />
                <div className='form-controls text-center px-2'>
                  <button type='submit' className='btn badge badge-tag d-block my-3' disabled={submitting}>
                    {submitting ? 'Sending...' : 'Send'}
                  </button>
                  {uploading ? (
                    <img src='/assets/loader.gif' alt='Uploading...' />
                  ) : (
                    <div className='position-relative upload-button'>
                      <input
                        type='file'
                        className='custom-file-input'
                        name='image'
                        accept='image/*'
                        onChange={ev => this.uploadImage(ev)}
                      />
                    </div>
                  )}
                </div>
              </form>
            </Restricted>
          </div>
        ) : null}
        <p className='m-0 text-right'>
          <button className='chat-toggler' type='button' onClick={this.toggle}>
            {chatOpen ? (
              <img src='/assets/close.svg' alt='Close' height='17' />
            ) : (
              <img src='/assets/chat.svg' alt='Chat' height='29' />
            )}
          </button>
        </p>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { clientID } = props
  const client = state.clients[clientID]
  const ticketID = Object.keys(state.tickets)[0]
  const chatTicket = state.tickets[ticketID]
  const chatOpen = state.ui.chatOpen
  const currentUser = state.currentUser

  return {
    messages: chatTicket ? chatTicket.messages : [],
    ticketID,
    client,
    chatOpen,
    currentUser
  }
}

const mapDispatchToProps = {
  getClientTickets,
  createTicketMessage,
  updateUI
}

const ChatPopupForm = FormHandler(ChatPopup)

const connected = connect(mapStateToProps, mapDispatchToProps)(ChatPopupForm)

export default withRestricted(connected, 'device::profile::chat')
