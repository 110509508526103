import React, { useEffect, useState } from 'react'
import NavBar from '../components/nav-bar'
import nl2br from 'react-nl2br'
import Restricted, { withRestricted } from '../components/restricted'
import Pagination from '../components/pagination'
import PageLoading from '../components/page-loading'
import ResponseError from '../components/response-error'
import FormHandler from '../components/form-handler'
import EditDmrModal from '../components/modals/edit-dmr-modal'
import Icon from '../components/icon'
import api from '../lib/api'
import { formatTime } from '../lib/functions'

const PER_PAGE = 50

function DeviceMasterRecords (props) {
  const { values, submitting, handleSubmit, handleChange, reset, error } = props
  const [dmrs, setDmrs] = useState(null)
  const [pagination, setPagination] = useState({ total: 0, currentPage: 1 })
  const loadData = (page = 1) => {
    api.paginateDeviceMasterRecords({ page, per_page: PER_PAGE }).then(data => {
      setDmrs(data.dmrs)
      console.log('data.metadata :>> ', data.metadata)
      setPagination(data.metadata)
    })
  }
  const loadNextPage = ({ currentPage }) => {
    loadData(currentPage)
  }
  useEffect(loadData, [])
  const onSubmit = values => {
    return api.createDeviceMasterRecord(values).then(() => {
      loadData()
      reset()
    })
  }
  const deleteDmr = id => () => {
    if (!confirm('Are you sure?')) return

    api.deleteDeviceMasterRecord(id).then(() => loadData())
  }

  return (
    <div>
      <NavBar />

      <div className='container'>
        <h4>Device Master Records</h4>

        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-body'>
                {dmrs ? (
                  <table className='table table-striped table-borderless table-sm w-100'>
                    <thead>
                      <tr>
                        <th>DMR</th>
                        <th>Notes</th>
                        <th>Date added</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {dmrs.map((dmr, i) => (
                        <tr key={i}>
                          <td>{dmr.dmr}</td>
                          <td>{nl2br(dmr.notes)}</td>
                          <td>{formatTime(dmr.createdAt, 'll')}</td>
                          <td>
                            <Restricted policy='device::products::create'>
                              <EditDmrModal dmr={dmr} onUpdated={loadData}>
                                {({ toggle }) => (
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm mr-2'
                                    onClick={toggle}
                                  >
                                    Update
                                  </button>
                                )}
                              </EditDmrModal>
                              <button
                                type='button'
                                className='btn btn-link btn-sm'
                                onClick={deleteDmr(dmr.id)}
                              >
                                <Icon icon='trash' className='text-danger' />
                              </button>
                            </Restricted>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <PageLoading />
                )}
                <Pagination
                  totalRecords={pagination.total}
                  onPageChange={loadNextPage}
                  currentPage={pagination.currentPage}
                  perPage={PER_PAGE}
                />
              </div>
            </div>
          </div>

          <Restricted policy='device::products::create'>
            <div className='col-4'>
              <div className='card'>
                <div className='card-body'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-group mb-2'>
                      <label>DMR:</label>
                      <input
                        type='text'
                        className='form-control'
                        name='dmr'
                        value={values.dmr || ''}
                        onChange={handleChange}
                        placeholder='SYS-ABC-123'
                        required
                      />
                    </div>
                    <div className='form-group mb-2'>
                      <label className='mr-2'>Notes:</label>
                      <textarea
                        rows='4'
                        className='form-control'
                        name='notes'
                        value={values.notes || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type='submit'
                      className='btn btn-primary mb-2'
                      disabled={submitting}
                    >
                      Add DMR
                    </button>

                    {error && <ResponseError error={error} />}
                  </form>
                </div>
              </div>
            </div>
          </Restricted>
        </div>
      </div>
    </div>
  )
}

const Form = FormHandler(DeviceMasterRecords)
export default withRestricted(Form, 'device::products')
