import React, { useState } from 'react'

function inArray (value, array) {
  return array.indexOf(value) > -1
}

function addToArray (value, array) {
  if (inArray(value, array)) return [...array]

  return [...array, value]
}

function removeFromArray (value, array) {
  if (!inArray(value, array)) return [...array]

  const i = array.indexOf(value)

  return [...array.slice(0, i), ...array.slice(i + 1)]
}

function MasterCheckbox (props) {
  const { selected, toggle, children, totalRows } = props

  return (
    <span className='form-check form-check-inline'>
      <label className='form-check-label'>
        <input
          type='checkbox'
          className='form-check-input'
          checked={selected.length === totalRows}
          ref={el => {
            if (!el) return
            el.indeterminate =
              selected.length > 0 && selected.length < totalRows
          }}
          onChange={() => toggle()}
        />
        {children}
      </label>
    </span>
  )
}

export default function CheckTable ({ children, headers, selectedHeader }) {
  const [selected, setSelected] = useState([])
  const isRowSelected = id => inArray(id, selected)
  const toggleRow = id => {
    if (inArray(id, selected)) {
      return setSelected(removeFromArray(id, selected))
    }

    return setSelected(addToArray(id, selected))
  }
  const Row = ({ children, id, ...rowProps }) => {
    return (
      <tr {...rowProps}>
        <td className='align-middle'>
          <span className='form-check form-check-inline'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={isRowSelected(id)}
              onChange={() => toggleRow(id)}
            />
          </span>
        </td>

        {children}
      </tr>
    )
  }
  const rows = children({ Row, setSelected, selected })
  const totalRows = rows.length
  const clearSelection = () => {
    setSelected([])
  }
  const toggleAllRows = () => {
    if (selected.length === totalRows) {
      clearSelection()
    } else {
      setSelected(rows.map(({ props }) => props.id))
    }
  }

  if (rows.length === 0) {
    return <div className='text-center text-muted'>No Results</div>
  }

  return (
    <div className='table-responsive'>
      <table className='table table-striped table-borderless'>
        <thead>
          {selected.length > 0
            ? (
              <tr>
                <th colSpan={headers.length + 1} className='bg-secondary'>
                  <div className='d-flex align-items-center'>
                    <span className='text-white mr-3'>
                      <MasterCheckbox
                        selected={selected}
                        totalRows={totalRows}
                        toggle={toggleAllRows}
                      >
                        {selected.length} selected
                      </MasterCheckbox>
                    </span>

                    {selectedHeader({ selected, clearSelection })}
                  </div>
                </th>
              </tr>
              )
            : (
              <tr>
                <th>
                  <MasterCheckbox
                    selected={selected}
                    totalRows={totalRows}
                    toggle={toggleAllRows}
                  />
                </th>
                {headers}
              </tr>
              )}
        </thead>

        <tbody>{rows}</tbody>
      </table>
    </div>
  )
}
