import React from 'react'
import TasksList from '../components/tasks-list'
import NavBar from '@/components/nav-bar'
import store from '@/lib/redux/store'
import * as Actions from '@/lib/redux/actions'

class Tasks extends React.Component {
  componentDidMount () {
    this.fetchData()
  }

  componentWillUnmount () {
    clearTimeout(this.updateTimeout)
  }

  fetchData () {
    store.dispatch(Actions.getAgents())

    // FIXME: temporary disable to avoid memory issues
    // this.updateTimeout = setTimeout(() => this.fetchData(), 15000);
  }

  render () {
    return (
      <div>
        <NavBar showSearch />

        <div className='container'>
          <p className='small d-flex'>
            <a href='/triggers_reference' target='_blank' rel='noopener noreferrer'>
              Triggers definition
            </a>
          </p>

          <TasksList />
        </div>
      </div>
    )
  }
}

export default Tasks
